// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.live-profile-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
  }
  
  .profile-border {
    width: 54px;
    height: 54px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(45deg, #ff0000, #ff4500);
    padding: 3px; /* Space for border effect */
  }
  
  .profile-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .live-tag {
    position: absolute;
    bottom: -5px;
    background-color: #ff0000;
    color: white;
    font-weight: bold;
    font-size: 12px;
    padding: 3px 6px;
    border-radius: 5px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/LiveProfile/styles.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,oDAAoD;IACpD,YAAY,EAAE,4BAA4B;EAC5C;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;EACnB;;EAEA;IACE,kBAAkB;IAClB,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,iBAAiB;IACjB,eAAe;IACf,gBAAgB;IAChB,kBAAkB;EACpB","sourcesContent":[".live-profile-container {\n    position: relative;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin-right: 12px;\n  }\n  \n  .profile-border {\n    width: 54px;\n    height: 54px;\n    border-radius: 50%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background: linear-gradient(45deg, #ff0000, #ff4500);\n    padding: 3px; /* Space for border effect */\n  }\n  \n  .profile-image {\n    width: 50px;\n    height: 50px;\n    border-radius: 50%;\n    object-fit: cover;\n  }\n  \n  .live-tag {\n    position: absolute;\n    bottom: -5px;\n    background-color: #ff0000;\n    color: white;\n    font-weight: bold;\n    font-size: 12px;\n    padding: 3px 6px;\n    border-radius: 5px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
