import React, { useState, useEffect, useCallback } from "react";
import "../../home/styles.css";
import { useDispatch, useSelector } from "react-redux";
import mapDiv from "../../../assets/images/mapDiv.png";
import map from "../../../assets/images/map.png";
import { getGradient } from "../../../utills/getGradient";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";

const GroupWidget = ({ itemStyles, height, width, widgetId }) => {
    const dispatch = useDispatch();
    const editingMode = useSelector((state) => state.bodyStyling.isEdit);
    const [color, setColor] = useState("");
    const [image, setImage] = useState("");
    const [initialImage, setInitialImage] = useState(mapDiv);
    const [isHovered, setIsHovered] = useState(false); // Track hover state
    const [link, setLink] = useState("");
    const [border, setBorder] = useState("");
    const [defaultCorners, setDefaultCorners] = useState("true");
    const [mapVisible, setMapVisible] = useState(false);

    const universalStyles = useSelector(
        (state) => state.bodyStyling.universalStyles
    );

    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        // googleMapsApiKey: process.env.GOOGLE_MAP_KEY,
        googleMapsApiKey: "AIzaSyCHV5NUMj_-IAtVbOIs3--vayPR3E60dp8",
    });

    const containerStyle = {
        width: "100%",
        height: "100%",
        borderRadius: border === "round" ? 20 : border === "sharp" ? 0 : null,
    };

    const [center, setCenter] = useState({
        lat: 31.52855536504707,
        lng: 74.343926645336,
    });

    const onLoad = useCallback(
        function callback(map) {
            const bounds = new window.google.maps.LatLngBounds(center);
            map.fitBounds(bounds);
        },
        [center]
    );

    const { default_widget_colors, mainColor } = universalStyles;

    useEffect(() => {
        if (universalStyles.corners && defaultCorners == "true") {
            setBorder(universalStyles.corners);
        }
    }, [universalStyles.corners]);

    useEffect(() => {
        if (itemStyles) {
            // setColor(itemStyles.background_color);
            if (
                itemStyles.background_color !== "0" &&
                itemStyles.background_color !== null
            ) {
                try {
                    const bgstyle = JSON.parse(itemStyles.background_color);
                    setColor(bgstyle);
                } catch (error) {
                    const bgstyle = itemStyles.background_color;
                    setColor(bgstyle);
                }
            } else {
                if (itemStyles.background_image) {
                    // console.log("IMG HERE>", itemStyles.background_image);
                    setImage(itemStyles.background_image);
                }
            }

            if (itemStyles.latitude && itemStyles.longitude) {
                setCenter({
                    lat: parseFloat(itemStyles.latitude),
                    lng: parseFloat(itemStyles.longitude),
                });
            }

            // setImage(itemStyles.background_image);
            setLink(itemStyles.widget_link);
            setBorder(itemStyles.border);
            setDefaultCorners(itemStyles.defaultCorners);
            setDefault(itemStyles.is_default);
            setImagePosition(itemStyles.image_position);
            setImgRepeat(itemStyles.image_repeat);
        }
    }, [itemStyles]);

    const [isDefault, setDefault] = useState(0);

    const [imagePosition, setImagePosition] = useState("cover");
    const [imageRepeat, setImgRepeat] = useState("no-repeat");

    return (
        <a
            style={{ textDecoration: "none" }}
            href={itemStyles?.widget_link}
            target="_blank"
        >
            <div
                className={`purplegradientmain  `}
                style={{
                    ...((image == "0" || image == null || image == false) && {
                        background: `${
                            isDefault && default_widget_colors.map
                                ? default_widget_colors.map.is_default === "0"
                                    ? default_widget_colors.map.color
                                    : default_widget_colors.map.is_default ===
                                      "1"
                                    ? mainColor
                                    : getGradient(color)
                                : getGradient(color)
                        }`,
                    }),
                    ...(((image !== "0" && image !== null && image !== false) ||
                        !initialImage) && {
                        backgroundImage: isHovered
                            ? `url(${map})`
                            : image
                            ? `url(${process.env.REACT_APP_BASE_URL}${image})`
                            : `url(${initialImage})`,
                        backgroundPosition: "center",
                        backgroundRepeat: imageRepeat,
                        backgroundSize: imagePosition,
                    }),
                    borderRadius:
                        border === "round" ? 20 : border === "sharp" ? 0 : null,
                    padding: 0,
                }}
                onMouseEnter={() => {
                    !color && setIsHovered(true);
                    setMapVisible(true);
                }}
                onMouseLeave={() =>
                    setTimeout(() => {
                        setIsHovered(false);
                        setMapVisible(false);
                    }, 300)
                }
            >
                {/* Map */}
                {isLoaded && (
                    <div
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            opacity: mapVisible ? 1 : 0,
                            transition: "opacity 0.3s ease-in-out",
                            pointerEvents: mapVisible ? "auto" : "none",
                            zIndex: 1,
                        }}
                    >
                        <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={center}
                            onLoad={onLoad}
                            options={{
                                // disableDoubleClickZoom: true,
                                // zoomControl: false,
                                streetViewControl: false,
                                // fullscreenControl: false,
                                mapTypeControl: false,
                                controlSize: false,
                                scaleControl: false,
                                // draggableCursor: false,
                                // draggable: false,
                                // draggingCursor: false,
                                // gestureHandling: "none",
                                zoom: 15,
                            }}
                        >
                            {center && <Marker position={center} />}
                        </GoogleMap>
                    </div>
                )}
            </div>
        </a>
    );
};

export default GroupWidget;
