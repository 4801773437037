import React, { useEffect, useRef } from "react";
import Hls from "hls.js";

const PostVideo = ({ src }) => {
    const videoRef = useRef(null);
    const hlsRef = useRef(null); // Store HLS instance

    useEffect(() => {
        const video = videoRef.current;
        if (!video) return;

        // Intersection Observer to autoplay when in view
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    if (video.paused && video.readyState >= 3) {
                        video.play().catch(() => {}); // Play if it's ready
                    }
                } else {
                    video.pause(); // Pause when out of view
                }
            },
            { threshold: 0.7 } // Video must be at least 50% visible
        );

        observer.observe(video);

        return () => observer.disconnect();
    }, []);

    useEffect(() => {
        const video = videoRef.current;
        if (!video) return;

        // Cleanup old HLS instance
        if (hlsRef.current) {
            hlsRef.current.destroy();
            hlsRef.current = null;
        }

        if (src.endsWith(".m3u8") && Hls.isSupported()) {
            const hls = new Hls();
            hls.loadSource(src);
            hls.attachMedia(video);
            hlsRef.current = hls; // Store instance for cleanup
        } else {
            video.src = src;
        }

        return () => {
            if (hlsRef.current) {
                hlsRef.current.destroy();
                hlsRef.current = null;
            }
            if (video) {
                video.pause();
                video.src = ""; // Prevent "media removed" error
            }
        };
    }, [src]);

    return (
        <video
            ref={videoRef}
            autoPlay={false} // Controlled via Intersection Observer
            loop={false}
            muted
            controls
            disablePictureInPicture
            playsInline // For better mobile support
            style={{
                position: "relative",
                width: "100%",
                height: "100%",
                borderRadius: 6,
            }}
        />
    );
};

export default PostVideo;

