import React from "react";

const OurBeliefs = () => {
  const data = [
    {
      id: 1,
      title: "The Bible",
      descriptions:
        "The Bible is absolutely true and without error. The Bible was written by human authors under the supernatural guidance of the Holy Spirit. The Bible is fully relevant to our daily lives, past, present, and future. The Bible is a guide to truth and living a spiritually fruitful and devoted life to Christ.",
      verse: "Psalm 119:105; 2 Timothy 3:15-17; John 17:17; Matthew 24:35",
    },
    {
      id: 2,
      title: "Trinity",
      descriptions:
        "The Trinity consists of one God, who reveals himself in three ways: the Father, the Son, and the Holy Spirit. Each one having equal power and authority, serves different functions.",
      verse:
        "Matthew 28:19; Luke 3:22; John 14:26; 15:26; Acts 2:33; 10:38; Romans 8:9; 1 Corinthians 6:11; 2 Corinthians 13:14; Galatians 4:6; Ephesians 1:17",
    },
    {
      id: 3,
      title: "The Father",
      descriptions:
        "God has existed and will exist for all eternity. He is all powerful, all knowing, all seeing, sovereign in all things and is worthy of our worship. God created the universe for His glory and reigns over it from heaven.",
      verse:
        "Genesis 1; Leviticus: 19:2; Deuteronomy 6:4-5; Isaiah 5:16; Isaiah 6:1-7; Isaiah 40:18-31; Matthew 3:16-17; Matthew 28:19; John 14:6-27",
    },
    {
      id: 4,
      title: "The Son",
      descriptions:
        "Jesus Christ is fully man and fully God at the same time. He came to the earth miraculously by a virgin birth to become the ultimate sacrifice when he was crucified on a cross as the atonement for the sin of man. While on the earth as man, He taught the will of God to man and lived a perfect life. Jesus’ perfection was essential as the sacrifice was the substitute for all man. Whoever believes in Jesus as Lord and asks for forgiveness of their sins will be in relationship with Him for all eternity in heaven.",
      verse:
        "Genesis 1; Leviticus 19:2; Deuteronomy 6:4-5; Isaiah 5:16; Isaiah 6:1-7; Isaiah 40:18-31; Matthew 3:16-17; Matthew 28:19; John: 14:6-27",
    },
    {
      id: 5,
      title: "The Holy Spirit",
      descriptions:
        "The Holy Spirit exists within every believer. He guides believers into truth and glorifies Jesus. He offers conviction of sin and offers comfort in trials. He provides the believer with sanctification, spiritual gifts, and helps us to become more like Christ. He is always present and sufficiently active in our lives.",
      verse:
        "John 7:39; John 14:15-18; John 16:7-15; Acts 2:33; Romans 8:1-27; Galatians 3:1-14; Galatians 4:6; Ephesians 3:14-21; 1 John: 3:24",
    },
    {
      id: 6,
      title: "Man",
      descriptions:
        "Man is made in the image of God, both male and female. Every person is uniquely created, possesses dignity, and is worthy of respect and Christian love. Man was created to have fellowship with God but became separated in that relationship through sinful disobedience. As a result, man cannot attain an eternal relationship with God through his own effort, but only through grace by admission of sin, belief in Jesus, and a desire to seek forgiveness.",
      verse:
        "John 1:14; 1 Timothy 2:5; Philippians 2:7; Hebrews: 1:3; Galatians 4:4; 1 John 4:2; Revelation: 2:8",
    },
    {
      id: 7,
      title: "Salvation",
      descriptions:
        "Admission of sin, belief in Jesus as Lord who came to this world to be persecuted and die on a cross and was placed in a tomb for the forgiveness of sin who then three days later supernaturally rose from the grave, and the desire to be forgiven of those sins is the only way to an eternal relationship with Jesus Christ. Salvation can not be attained through the works of man. Salvation once truly gained can not be lost, however this does not absolve the believer of worldly or spiritual consequence of continuing sin.",
      verse:
        "Ephesians 2:8-9; Titus 3:5; Romans 10:9; Acts 4:12; John 3:16-18; John 14:6; 1 Thessalonians: 5:9",
    },
    {
      id: 8,
      title: "The Church",
      descriptions:
        "The Church is a group of people who symbolically represents the body of Christ. It is committed to the teachings of Christ and obeying all of His commands, seeks to serve others, and to make disciples of all nations. The Church exists to provide fellowship amongst believers, invite guests into fellowship, and encourage one another through unity and love.",
      verse:
        "Exodus 19:3; Jeremiah 31:33; Matthew 8:11; John 17:14-26; John 20:21-23; Acts 1:7-8; Acts 2:32-47; Acts 6:1-2; Acts 13:1; Acts 14:23; Romans 2:28-29; Romans 4:16; Corinthians: 5:11-6:1",
    },
    {
      id: 9,
      title: "About Baptism",
      descriptions:
        "We believe that baptism is a symbol intended for others to see as a declaration of belief in Jesus Christ. Baptism is not a requirement of salvation, however it is a step of obedience and proclamation of personal faith. Participation is a personal decision which should only be made by those capable of understanding the meaning and making their own determination of participation. We believe baptism should be performed in water by complete immersion.",
      verse:
        "Matthew 3:1-7; Matthew 28:16-20; Acts 2:37-41; Acts 8:35-39; Acts 10:44-48; Romans 6:3-4; Galatians 3:26-28; Peter 3:18-22",
    },
    {
      id: 10,
      title: "About The Lord’s Supper",
      descriptions:
        "We believe that the Lord’s supper symbolically represents the body and blood of Christ. The Lord’s supper is to be performed by believers as an act of remembrance of Christ and the sacrifice he made.",
      verse:
        "Exodus 12:1-14; Matthew 26:26-29; Mark 14:22-25; Luke 22:17-20; John 6:28-58; 1 Corinthians 10:14-21",
    },
  ];

  return (
    <div className="our-belief ">
      <h1 style={{fontSize:34}}>Our Beliefs</h1>

      {data.map((item) => {
        return (
          <div key={item.id}>
            <h3>{item.title}</h3>
            <p>{item.descriptions}</p>
            <h2>{item.verse}</h2>
          </div>
        );
      })}
    </div>
  );
};

export default OurBeliefs;
