import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Circles } from "react-loader-spinner";
//Actions
//Components
import GivingGrid from "../../components/GridLayout/GivingGrid";
import { getStyling } from "../../store/slices/bodyStyling";
import Assets from "../../assets/images";
import XHR from "../../utills/XHR";
import { setGivingData } from "../../store/slices/givingSlice";

function Giving(props) {
    const { darkMode } = props;

    const dispatch = useDispatch();
    const hostname = process.env.REACT_APP_BASE_URL;
    const user = useSelector((state) => state.login.userData);
    const data = useSelector((state) => state.bodyStyling.styling);
    const loading = useSelector((state) => state.bodyStyling.isLoading);

    useEffect(() => {
        document.body.classList.remove("sharp-mode", "round-mode");
        if (window.innerWidth <= 450) {
            dispatch(getStyling(11));
        } else {
            dispatch(getStyling(3));
        }
        requestGiving(user.id, user.api_token);
    }, []);

    const requestGiving = (userId, token) => {
        const options = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            method: "GET",
        };
        XHR(`${hostname}/api/v1/getGiving?user_id=${userId}`, options)
            .then((resp) => {
                if (resp.data.success) {
                    dispatch(setGivingData(resp.data));
                }
            })
            .catch((e) => {
                console.log("ERR>", e);
            })
            .finally(() => {});
    };

    return (
        <div
            className={
                darkMode ? "rightWrapMain darkMode" : "rightWrapMain lightMode"
            }
        >
            <div className="rightInnerWrap">
                <div className="sec2">
                    <div className="container">
                        {loading ? (
                            <Circles
                                height="50"
                                width="50"
                                color="transparent"
                                ariaLabel="circles-loading"
                                wrapperStyle={{
                                    alignItems: "center",
                                    justifyContent: "center",
                                    display: "flex",
                                }}
                                wrapperClass=""
                                visible={true}
                            />
                        ) : (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <GivingGrid widgetsOrder={data.homepageOrder} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Giving;
