import React, { useEffect, useRef, useState } from "react";
import { Heart, Pencil, Trash } from "lucide-react";
import Popup from "reactjs-popup";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { removeStartingSlash as removeSlash } from "../../utills/RemoveSlash.js";
import { useSelector } from "react-redux";
import placeholder from "../../assets/images/placeholder.png";
import XHR from "../../utills/XHR.js";

const LikeBar = ({ postData, edit, getPostData, otherUserProfile }) => {
  const userDetails = useSelector((state) => state.login.userProfile);

  const { reactions, comments, comment_count, reaction_count, is_reacted } =
    postData;
  const navigate = useNavigate();

  const hostname = process.env.REACT_APP_BASE_URL;
  const user = useSelector((state) => state.login.userData);

  const [commentsCount, setCommentsCount] = useState(0);
  const [liked, setLiked] = useState(false);
  const [likeCount, setLikeCount] = useState();
  const likeText = likeCount === 0 || likeCount > 1 ? "Likes" : "Like";
  const fileInputRef = useRef(null);
  const [selectedImg, setSelectedImg] = useState(null);
  const [imageFile, setFile] = useState(null);

  useEffect(() => {
    if (postData) {
      setType(postData.post_type);
      setPrivacyType(postData.post_privacy);
      setText(postData.post_content);
      if (comments) {
        setCommentsCount(comments?.length);
      } else {
        setCommentsCount(comment_count);
      }
    }
  }, [postData]);

  useEffect(() => {
    if (postData) {
      if (otherUserProfile !== true) {
        const { is_reacted, reactions } = postData;
        setLiked(is_reacted);
        setLikeCount(reactions?.length);
      } else {
        const { is_reacted, reaction_count } = postData;
        setLiked(is_reacted);
        setLikeCount(reaction_count);
      }
    }
  }, [postData]);

  const handleButtonClick = () => {
    // Trigger the file input
    fileInputRef.current.click();
  };
  const handleFileChange = (e) => {
    // Handle the selected file
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile.type.startsWith("image/")) {
        setFile(selectedFile);

        const reader = new FileReader();
        reader.onload = (event) => {
          setSelectedImg(event.target.result);
        };
        reader.readAsDataURL(selectedFile);
      } else {
        alert("Please select a valid image file.");
      }
    }
  };
  const reactApi = (reactionId) => {
    const options = {
      method: "POST",
      url: `${hostname}/api/v1/updatePostReaction`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.api_token}`,
      },
      data: {
        user_id: user.id,
        post_id: postData.id,
        reaction_id: reactionId,
      },
    };
    XHR(options)
      .then((resp) => {
        if (resp.data.success) {
          liked ? setLiked(false) : setLiked(true);
          const newCount = likeCount;
          setLikeCount(liked ? newCount - 1 : newCount + 1);
        }
      })
      .catch((e) => {
        console.log("ERR>", e);
      });
  };
  const postTypes = [
    { type: "Question" },
    { type: "Prayer Request" },
    { type: "Encouragement" },
  ];
  const [postType, setType] = useState("Question");
  const [privacyType, setPrivacyType] = useState("1");
  const [text, setText] = useState("");

  const privacyTypes = [
    { type: "Public", value: 1 },
    { type: "Connection only", value: 2 },
    { type: "Family only", value: 3 },
    { type: "Only me", value: 4 },
  ];

  const updatePost = (close) => {
    if (text.trim().length == 0 && selectedImg == null && !postData?.post_img) {
      toast.error("Please add some content");
      return;
    }

    const formData = new FormData();
    if (selectedImg) {
      formData.append("post_img", selectedImg);
    }
    // Assuming 'image' is the selected image file

    formData.append("post_id", postData.id);
    formData.append("post_type", postType);
    formData.append("post_privacy", privacyType);
    formData.append("post_content", text);

    const options = {
      method: "post",
      url: `${hostname}/api/v1/editPost`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${user.api_token}`,
      },
      data: formData,
    };
    XHR(options)
      .then((resp) => {
        if (resp.data.success) {
          getPostData(postData.id);
          toast.success(resp.data.message);
          close();
        }
        console.log("RESP>", resp);
      })
      .catch((e) => {
        console.log("ERR>", e);
      });
  };

  const deletePost = (close) => {
    const options = {
      method: "post",
      url: `${hostname}/api/v1/deletePost`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.api_token}`,
      },
      data: { post_id: postData.id },
    };
    // @ts-ignore
    XHR(options)
      .then((resp) => {
        if (resp.data.success) {
          toast.success(resp.data.message);
          close();
          navigate(-1);
        }
        console.log("RESP>", resp);
      })
      .catch((e) => {
        console.log("ERR>", e);
      });
  };

  return (
    <div className="postaction d-flex align-items-center justify-content-between ">
      <ul>
        <li
          style={{ cursor: "pointer" }}
          onClick={() => {
            reactApi(1);
          }}
        >
          <Heart
            size={30}
            color={liked ? "#e84747" : "grey"}
            fill={liked ? "#e84747" : "grey"}
          />
          <span style={{ color: liked ? "#e84747" : "grey",marginLeft:10 }}>
            {likeCount} {likeText}
          </span>
        </li>

        <li>
          <img
            src="https://images.ctfassets.net/1itkm9rji8jb/2sztEcJ65hskNTrD7mXxWx/cebf2f7b6f602408f7b6eea3326967bd/comen.svg"
            alt=""
          />
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/post", { state: postData.id });
            }}
          >
            {commentsCount} {commentsCount == 1 ? "Comment" : "Comments"}
          </span>
        </li>
      </ul>
      <div className="d-flex" style={{ columnGap: 6 }}>
        {edit && (
          <Popup
            lockScroll={true}
            contentStyle={{
              border: "unset",
              padding: 0,
              borderRadius: 10,
            }}
            modal={true}
            trigger={
              <Pencil style={{ cursor: "pointer" }} size={30} color="grey" />
            }
            position="center center"
          >
            {/* @ts-ignore */}
            {(close) => (
              <div className="popupMain">
                <div className="popupInner">
                  <div className="popupHeader d-flex">
                    <div>
                      <h5>Select Topic</h5>
                      <ul style={{listStyle:'none'}} >
                        {postTypes.map((item) => {
                          return (
                            <li
                              key={item.type}
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setType(item.type);
                              }}
                              className={postType === item.type ? "active" : ""}
                            >
                              {item.type}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div>
                      <h5
                        style={{
                          width: "unset",
                          marginBottom: 10,
                        }}
                      >
                        Select Privacy
                      </h5>
                      <select
                        style={{
                          height: 30,
                          borderRadius: 4,
                          backgroundColor: "dimgray",
                        }}
                        value={privacyType}
                        onChange={(e) => {
                          setPrivacyType(e.target.value);
                        }}
                      >
                        {privacyTypes.map((item) => {
                          return (
                            <option key={item.value} value={item.value}>
                              {item.type}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="mindUpload">
                    <div className="uoloadtext">
                      <div className="profileimg">
                        <img
                          src={
                            userDetails?.profile_pic
                              ? `${hostname}/${removeSlash(
                                  userDetails?.profile_pic
                                )}`
                              : placeholder
                          }
                          // src="https://images.ctfassets.net/1itkm9rji8jb/4iHI7UAQ5QpNc4CRvaF9Im/68f1a121dc99dc9ca1d34272e9deedb3/Mask.jpg"
                          alt=""
                        />
                      </div>
                      <div className="profileText">
                        <h4>{postData.username}</h4>
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        handleButtonClick();
                      }}
                      className="profileimgUploadIcon"
                    >
                      <img
                        src="https://images.ctfassets.net/1itkm9rji8jb/GBEcAOKhA2yzHVXL3RhZQ/f9c2723924c977f8c393e2025672d06d/image-gallery-svgrepo-com.svg"
                        alt=""
                      />
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{
                          display: "none",
                        }}
                        accept="image/*"
                        onChange={handleFileChange}
                      />
                    </div>
                  </div>

                  <div
                    className="postContent"
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    <p
                      className="editable"
                      onBlur={(e) => {
                        setText(e.currentTarget.textContent);
                      }}
                      data-placeholder="what's on your mind?"
                      contentEditable
                      // aria-multiline
                      style={{
                        maxHeight: 70,
                        overflowY: "scroll",
                      }}
                    >
                      {text}
                    </p>
                    <div className="postedImage d-flex align-items-center justify-content-center ">
                      {selectedImg ? (
                        <img
                          style={{
                            height: 200,
                            width: "unset",
                          }}
                          src={selectedImg}
                          alt=""
                        />
                      ) : postData.post_img ? (
                        <img
                          style={{
                            height: 200,
                            width: "unset",
                          }}
                          src={`${hostname}/${removeSlash(
                            postData.post_img
                          )}`}
                          // src={`${BASE_URL}${postData.post_img}`}
                          alt=""
                        />
                      ) : null}
                    </div>
                    <div onClick={() => updatePost(close)} className="postBtn">
                      <p
                        style={{ textDecoration: "none" }}
                        className="hasnewbtn"
                      >
                        Update
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Popup>
        )}
        {edit && (
          <Popup
            lockScroll={true}
            contentStyle={{
              border: "unset",
              padding: 0,
              borderRadius: 10,
            }}
            modal={true}
            trigger={
              <Trash style={{ cursor: "pointer" }} size={30} color="grey" />
            }
            position="center center"
          >
            {/* @ts-ignore */}
            {(close) => (
              <div
                className="confirmation-dialog d-flex flex-column gap-2 "
                style={{
                  backgroundColor: "black",
                  borderRadius: 10,
                  padding: 20,
                }}
              >
                <p style={{ fontSize: 20,color:'#fff' }}>
                  Are you sure you want to delete this post?
                </p>
                <div className="d-flex align-items-center justify-content-around">
                  <button
                    onClick={close}
                    style={{ fontSize: 20, color: "grey", outline: "unset",backgroundColor:'transparent' }}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => deletePost(close)}
                    style={{ fontSize: 20, color: "red", outline: "unset" ,backgroundColor:'transparent'}}
                  >
                    Delete
                  </button>
                </div>
              </div>
            )}
          </Popup>
        )}
      </div>
    </div>
  );
};

export default LikeBar;
