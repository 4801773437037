import React, { useState, useEffect } from "react";
import "../../home/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { getGradient } from "../../../utills/getGradient";

const EventListWidget = ({
    itemStyles,
    height,
    width,
    widgetId,
    eventData,
}) => {
    const dispatch = useDispatch();
    const [color, setColor] = useState("");
    const [image, setImage] = useState("");
    const [link, setLink] = useState("");
    const [border, setBorder] = useState("");
    const [defaultCorners, setDefaultCorners] = useState("true");

    const universalStyles = useSelector(
        (state) => state.bodyStyling.universalStyles
    );

    const { default_widget_colors, mainColor } = universalStyles;

    useEffect(() => {
        if (universalStyles.corners && defaultCorners == "true") {
            setBorder(universalStyles.corners);
        }
        if (universalStyles.font) {
            font.isDefaultFont == 1 &&
                setFont((prevFont) => ({
                    ...prevFont,
                    family: universalStyles.font,
                }));
            subfont.isDefaultFont == 1 &&
                setSubFont((prevFont) => ({
                    ...prevFont,
                    family: universalStyles.font,
                }));
            dateFont.isDefaultFont == 1 &&
                setDateFont((prevFont) => ({
                    ...prevFont,
                    family: universalStyles.font,
                }));
        }
    }, [universalStyles.corners, universalStyles.font]);

    useEffect(() => {
        if (itemStyles) {
            // setImage(itemStyles.background_image);
            // setColor(itemStyles.background_color);
            if (
                itemStyles.background_color !== "0" &&
                itemStyles.background_color !== null
            ) {
                try {
                    const bgstyle = JSON.parse(itemStyles.background_color);
                    setColor(bgstyle);
                } catch (error) {
                    const bgstyle = itemStyles.background_color;
                    setColor(bgstyle);
                }
            } else {
                if (itemStyles.background_image) {
                    // console.log("IMG HERE>", itemStyles.background_image);
                    setImage(itemStyles.background_image);
                }
            }
            if (itemStyles.title_font_style) {
                try {
                    const fontstyle = JSON.parse(itemStyles.title_font_style);
                    setFont({
                        ...fontstyle,
                        color: itemStyles.title_color,
                        family: itemStyles.title_font,
                        size: itemStyles.title_font_size,
                    });
                } catch (error) {
                    const fontstyle = itemStyles.title_font_style;
                    setFont({
                        ...fontstyle,
                        color: itemStyles.title_color,
                        family: itemStyles.title_font,
                        size: itemStyles.title_font_size,
                    });
                }
            }
            if (itemStyles.date_font_style) {
                try {
                    const dateStyle = JSON.parse(itemStyles.date_font_style);
                    setDateFont({
                        ...dateStyle,
                        color: itemStyles.date_font_color,
                        family: itemStyles.date_font,
                        size: itemStyles.date_font_size,
                    });
                } catch (error) {
                    const dateStyle = itemStyles.date_font_style;
                    setDateFont({
                        ...dateStyle,
                        color: itemStyles.date_font_color,
                        family: itemStyles.date_font,
                        size: itemStyles.date_font_size,
                    });
                }
            }
            if (itemStyles.description_font_style) {
                try {
                    const descriptionStyle = JSON.parse(
                        itemStyles.description_font_style
                    );
                    setSubFont({
                        ...descriptionStyle,
                        color: itemStyles.description_color,
                        family: itemStyles.description_font,
                        size: itemStyles.description_font_size,
                    });
                } catch (error) {
                    const descriptionStyle = itemStyles.description_font_style;
                    setSubFont({
                        ...descriptionStyle,
                        color: itemStyles.description_color,
                        family: itemStyles.description_font,
                        size: itemStyles.description_font_size,
                    });
                }
            }
            setLink(itemStyles.widget_link);
            setBorder(itemStyles.border);
            setDefaultCorners(itemStyles.defaultCorners);
            setDefault(itemStyles.is_default);
            setImagePosition(itemStyles.image_position);
            setImgRepeat(itemStyles.image_repeat);
        }
    }, [itemStyles]);

    const [isDefault, setDefault] = useState(0);
    const [font, setFont] = useState({
        color: "#ffffff",
        isBold: false,
        isItalic: false,
        isUnderlined: false,
        family: "frontage",
        size: 30,
        isDefaultFont: 1,
    });
    const [subfont, setSubFont] = useState({
        color: "#ffffff",
        isBold: false,
        isItalic: false,
        isUnderlined: false,
        family: "frontage",
        size: 10,
        isDefaultFont: 1,
    });
    const [dateFont, setDateFont] = useState({
        color: "#ffffff",
        isBold: false,
        isItalic: false,
        isUnderlined: false,
        family: "frontage",
        size: 10,
        isDefaultFont: 1,
    });

    //COUNT for color useeffect check
    const [imagePosition, setImagePosition] = useState("cover");
    const [imageRepeat, setImgRepeat] = useState("no-repeat");

    return (
        <a
            style={{ textDecoration: "none" }}
            href={itemStyles?.widget_link}
            target="_blank"
        >
            <div
                className={"eventList"}
                style={{
                    ...(!eventData?.banner_1 &&
                        !eventData?.banner_2 &&
                        !image && {
                            background: `${
                                isDefault && default_widget_colors.eventList
                                    ? default_widget_colors.eventList
                                          .is_default === "0"
                                        ? default_widget_colors.eventList.color
                                        : default_widget_colors.eventList
                                              .is_default === "1"
                                        ? mainColor
                                        : getGradient(color)
                                    : getGradient(color)
                            }`,
                        }),
                    ...(eventData?.banner_1 && {
                        backgroundImage: `url(${process.env.REACT_APP_BASE_URL}${eventData.banner_1})`,
                        backgroundPosition: "center",
                        backgroundRepeat: imageRepeat,
                        backgroundSize: "cover",
                    }),
                    ...(!eventData?.banner_1 &&
                        eventData?.banner_2 && {
                            backgroundImage: `url(${process.env.REACT_APP_BASE_URL}${eventData.banner_2})`,
                            backgroundPosition: "center",
                            backgroundRepeat: imageRepeat,
                            backgroundSize: "cover",
                        }),
                    ...(!eventData?.banner_1 &&
                        !eventData?.banner_2 &&
                        image && {
                            backgroundImage: `url(${process.env.REACT_APP_BASE_URL}${image})`,
                            backgroundPosition: "center",
                            backgroundRepeat: imageRepeat,
                            backgroundSize: "cover",
                        }),
                    borderRadius:
                        border === "round" ? 20 : border === "sharp" ? 0 : null,
                }}
            >
                {/* )} */}
                <div className="d-flex flex-column justify-content-between ">
                    <div className="d-flex align-items">
                        <div className="date-range">
                            <p
                                style={{
                                    fontSize: Number(dateFont.size),
                                    margin: 0,
                                    color: dateFont.color,
                                    // fontFamily: dateFont.family,
                                    fontFamily:
                                        dateFont.family === "default"
                                            ? universalStyles.font
                                            : dateFont.family
                                            ? dateFont.family
                                            : "sans-serif",
                                    fontStyle: dateFont.isItalic
                                        ? "italic"
                                        : "normal",
                                    fontWeight: dateFont.isBold
                                        ? "bold"
                                        : "normal",
                                    textDecorationLine: dateFont.isUnderlined
                                        ? "underline"
                                        : "none",
                                }}
                            >
                                {/* 19-Nov-2023 - 26-Nov-2023 */}
                                {eventData?.start_date} - {eventData?.end_date}
                            </p>
                        </div>
                    </div>
                    <div className="d-flex align-items-start">
                        <div className="d-flex flex-column">
                            <p
                                style={{
                                    fontSize: Number(font.size),
                                    margin: 0,
                                    color: font.color,
                                    // fontFamily: font.family,
                                    fontFamily:
                                        font.family === "default"
                                            ? universalStyles.font
                                            : font.family
                                            ? font.family
                                            : "sans-serif",
                                    fontStyle: font.isItalic
                                        ? "italic"
                                        : "normal",
                                    fontWeight: font.isBold ? "bold" : "normal",
                                    textDecorationLine: font.isUnderlined
                                        ? "underline"
                                        : "none",
                                }}
                            >
                                {/* NO PAIN, NO GAIN */}
                                {eventData?.series_name}
                            </p>
                            <div className="d-flex align-items-center">
                                <p
                                    style={{
                                        color: subfont.color,
                                        // fontFamily: subfont.family,
                                        fontFamily:
                                            subfont.family === "default"
                                                ? universalStyles.font
                                                : subfont.family
                                                ? subfont.family
                                                : "sans-serif",
                                        fontStyle: subfont.isItalic
                                            ? "italic"
                                            : "normal",
                                        fontWeight: subfont.isBold
                                            ? "bold"
                                            : "normal",
                                        textDecorationLine: subfont.isUnderlined
                                            ? "underline"
                                            : "none",
                                        fontSize: Number(subfont.size),
                                        margin: 0,
                                    }}
                                >
                                    {/* NO PAIN, NO GAIN */}
                                    {eventData?.series_desc}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </a>
    );
};

export default EventListWidget;
