import React, { useCallback, useEffect, useRef, useState } from "react";
import "../home/styles.css";
import { Send } from "lucide-react";
import broadcast from "../../assets/svg/broadcast.svg";
import ChatItem from "./ChatItem";
import { useDispatch, useSelector } from "react-redux";
import XHR from "../../utills/XHR";
import {
    setLiveStreamChat,
    setSelectedEventDeatails,
    setSermonsData,
} from "../../store/slices/watchSlice";
import toast from "react-hot-toast";
import { database } from "../../firebase/firebaseConfig.js";
import { ref, onChildAdded, off, onValue } from "firebase/database";
import moment from "moment";

const Chat = ({ borderRadius, width }) => {
    const user = useSelector((state) => state.login.userData);

    const sermonsData = useSelector((state) => state.watch.sermonsData);
    const liveEvent = useSelector((state) => state.watch.liveEvent);

    const selectedEvent = useSelector(
        (state) => state.watch.selectedEventDetails
    );

    const sermonsDataRef = useRef(sermonsData);
    const selectedEventRef = useRef(selectedEvent);

    // Update refs when state changes
    useEffect(() => {
        sermonsDataRef.current = sermonsData;
        selectedEventRef.current = selectedEvent;
    }, [sermonsData, selectedEvent]);

    const chatSectionRef = useRef(null);

    const [data, setData] = useState([]);

    useEffect(() => {
        if (selectedEvent?.chat && selectedEvent?.playCheck) {
            setData(selectedEvent?.chat);
        }
    }, [selectedEvent]);

    const dispatch = useDispatch();
    const [isFocused, setFocused] = useState(false);

    const [visibleReactionId, setVisibleReactionId] = useState(null);
    const [message, setMessage] = useState("");

    const handleMouseEnter = (id) => {
        setVisibleReactionId(id);
    };

    const handleMouseLeave = () => {
        setVisibleReactionId(null);
    };

    let newItems = false;

    useEffect(() => {
        // add chat message listener
        const addMsgRef = ref(database, "ChatMessageSent");
        onChildAdded(addMsgRef, handleChatMessage);
        // add posts comment listener
        const LiveReactionRef = ref(database, "LiveChatReaction");
        onChildAdded(LiveReactionRef, handleLiveReaction);
        // add posts listener
        const LiveDeletedRef = ref(database, "ChatMessageDeleted");
        onChildAdded(LiveDeletedRef, handleLiveDelete);

        // handle initial items
        onValue(LiveDeletedRef, handleInitialItems);
    }, []);

    const handleInitialItems = () => {
        newItems = true;
    };

    const handleChatMessage = (snapshot) => {
        if (!newItems) return;
        if (snapshot.exists()) {
            const sermonsData = sermonsDataRef.current;
            const selectedEvent = selectedEventRef.current;
            console.log("LIVE Chat MSG>>", snapshot.val());
            let e = snapshot.val();
            if (selectedEvent?.stream_session_id == e.stream_id) {
                if (e.user_id == user.id) {
                    setData((prev) => {
                        const newData = prev.map((item) =>
                            item.id == e.temp_id
                                ? {
                                      ...item,
                                      id: Number(e.id),
                                      created_at: e.created_at,
                                      message_status: "posted",
                                  }
                                : item
                        );

                        // Updating sermons data to sync every change

                        const tempSeries = JSON.parse(
                            JSON.stringify(sermonsData?.series)
                        );
                        const seriesIndex = tempSeries.findIndex(
                            (item) =>
                                item?.series_id == selectedEvent?.series_id
                        );
                        if (seriesIndex == -1) return;
                        const eventIndex = tempSeries[
                            seriesIndex
                        ].events.findIndex(
                            (event) => event.event_id == selectedEvent?.event_id
                        );
                        if (eventIndex == -1) return;
                        tempSeries[seriesIndex].events[eventIndex].chat =
                            newData;
                        const updateData = {
                            ...sermonsData,
                            series: tempSeries,
                        };
                        dispatch(setSermonsData(updateData));

                        return newData;
                    });
                } else {
                    const newObj = { ...e, check_user: null, reactions: [] };
                    setData((prev) => [...prev, newObj]);
                }

                if (chatSectionRef.current) {
                    setTimeout(() => {
                        chatSectionRef.current.scrollTo({
                            top: chatSectionRef.current.scrollHeight,
                            behavior: "smooth",
                        });
                    }, 300);
                }
            } else {
                // To add chat to live event if live event is not in foreground to have updated data
                const ids = findSeriesAndEventIndex(
                    sermonsData?.series,
                    e.stream_id
                );
                if (ids.eventIndex == -1 && ids.seriesIndex == -1) return;
                const tempSeries = JSON.parse(
                    JSON.stringify(sermonsData?.series)
                );
                const eventChat =
                    tempSeries[ids.seriesIndex].events[ids.eventIndex].chat;
                tempSeries[ids.seriesIndex].events[ids.eventIndex].chat = [
                    ...eventChat,
                    { ...e, check_user: null, reactions: [] },
                ];
                const updateData = {
                    ...sermonsData,
                    series: tempSeries,
                };
                dispatch(setSermonsData(updateData));
            }
        }
    };

    // Find series and event index against stream id
    const findSeriesAndEventIndex = (data, targetStreamSessionId) => {
        let seriesIndex = -1;
        let eventIndex = -1;

        // Iterate over the series
        data.some((series, sIndex) => {
            // Iterate over the events in the series
            return series.events.some((event, eIndex) => {
                if (event.stream_session_id === targetStreamSessionId) {
                    seriesIndex = sIndex;
                    eventIndex = eIndex;
                    return true; // Stop the iteration once a match is found
                }
                return false;
            });
        });

        return { seriesIndex, eventIndex };
    };

    const handleLiveReaction = (snapshot) => {
        if (!newItems) return;
        if (snapshot.exists()) {
            console.log("LIVE REACTION>>", snapshot.val());
            let e = snapshot.val();
            if (e) {
                // Use the functional form of setChatData to ensure correct updates
                setData((prev) => {
                    const updatedChat = updateArray(prev, e);
                    return updatedChat || prev;
                });
            }
        }
    };

    const handleLiveDelete = (snapshot) => {
        if (!newItems) return;
        if (snapshot.exists()) {
            console.log("LIVE DELETE>>", snapshot.val());
            let e = snapshot.val();
            if (e.id) {
                setData((prev) => {
                    const msgItem = prev.find(
                        (item) => item.id == Number(e.id)
                    );
                    const updatedChat = prev.filter(
                        (item) => item.id !== Number(e.id)
                    );
                    if (msgItem !== -1 && msgItem?.user_id == user.id) {
                        toast.success("Chat message deleted.", {
                            duration: 2000,
                        });
                    }
                    return updatedChat;
                });
            }
        }
    };

    const updateArray = (originalArray, updateData) => {
        const updatedArray = originalArray.map((item) => {
            if (item.id == updateData.msg_id) {
                // If the item matches the specified msg_id, update its properties
                return {
                    ...item,
                    id: updateData.msg_id,
                    user_id: updateData.user_id,
                    reactions: updateData?.reaction ? updateData.reaction : [],
                    check_user: updateData.check_user
                        ? updateData.check_user
                        : null,
                    total_reactions: updateData.total_raction_count,
                };
            }
            return item;
        });

        return updatedArray;
    };

    const sendMessage = useCallback(() => {
        if (message.trim().length === 0) {
            return;
        }
        const tmpId = new Date().getTime();
        const createdAt = moment(tmpId).format("YYYY-MM-DD HH:mm:ss");

        const options = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user?.api_token}`,
            },
            method: "POST",
            data: {
                message: message,
                streamID: selectedEvent?.stream_session_id,
                annoucment: 0,
                userId: user.id,
                temp_id: tmpId,
            },
        };
        setData((prev) => {
            const newMessage = {
                id: Number(tmpId),
                annoucment: 0,
                check_user: [],
                created_at: createdAt,
                description: message,
                reactions: [],
                status: "",
                stream_id: selectedEvent?.stream_session_id,
                total_reactions: 0,
                updated_at: null,
                user_id: user.id,
                user_name: user.name,
                message_status: "posting",
            };
            return [...prev, newMessage];
        });
        setMessage("");
        setTimeout(() => {
            chatSectionRef.current.scrollTo({
                top: chatSectionRef.current.scrollHeight,
                behavior: "smooth",
            });
        }, 50);

        XHR(`${process.env.REACT_APP_BASE_URL}/api/v1/send-live-chat`, options)
            .then((response) => {
                // console.log('RESP>', response)
                // const tempSeries = JSON.parse(JSON.stringify(sermonsData?.series));
                // const seriesIndex = tempSeries.findIndex(
                //   (item) => item?.series_id == selectedEvent?.series_id
                // );
                // if (seriesIndex == -1) return;
                // const eventIndex = tempSeries[seriesIndex].events.findIndex(
                //   (event) => event.event_id == selectedEvent?.event_id
                // );
                // if (eventIndex == -1) return;
                // tempSeries[seriesIndex].events[eventIndex].chat = updatedChat;
                // const updateData = {
                //   ...sermonsData,
                //   series: tempSeries,
                // };
                // dispatch(setSermonsData(updateData));
            })
            .catch((error) => {
                console.log("ERRRR>", error);

                setData((prev) => {
                    const chatMesages = [...prev];
                    const updatedResults =
                        chatMesages &&
                        chatMesages.map((item) =>
                            item.id == tmpId
                                ? { ...item, message_status: "failed" }
                                : item
                        );
                    console.log("RES>", updatedResults);
                    return updatedResults;
                });
            });
    }, [message, selectedEvent, sermonsData]);

    const deleteMessage = (msgId) => {
        const updatedChat = data.map((item) =>
            item.id == msgId
                ? {
                      ...item,
                      deleteStatus: "deleting",
                  }
                : item
        );

        const tempData = {
            playCheck: true,
            status:
                liveEvent &&
                liveEvent?.stream_session_id ===
                    selectedEvent?.stream_session_id
                    ? 1
                    : 0,
            stream_session_id: selectedEvent?.stream_session_id,
            series_id: selectedEvent?.series_id,
            event_id: selectedEvent?.event_id,
            chat: updatedChat,
            notes: selectedEvent?.notes,
        };
        dispatch(setSelectedEventDeatails(tempData));

        const options = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user?.api_token}`,
            },
            method: "DELETE",
        };
        XHR(
            `${process.env.REACT_APP_BASE_URL}/api/v1/delete-live-chat/${msgId}`,
            options
        )
            .then((response) => {
                // const tempChat = JSON.parse(JSON.stringify(selectedEvent.chat));
                // const updatedChat = tempChat.filter((item) => item.id !== msgId);

                // const tempData = {
                //   playCheck: true,
                //   status:
                //     liveEvent &&
                //     liveEvent?.stream_session_id === selectedEvent?.stream_session_id
                //       ? 1
                //       : 0,
                //   stream_session_id: selectedEvent?.stream_session_id,
                //   series_id: selectedEvent?.series_id,
                //   event_id: selectedEvent?.event_id,
                //   chat: updatedChat,
                //   notes: selectedEvent?.notes,
                // };
                // dispatch(setSelectedEventDeatails(tempData));
                // toast.success("Chat message deleted.", { duration: 2000 });

                // Update chat in sermons data to sync chat after switching event
                const tempSeries = JSON.parse(
                    JSON.stringify(sermonsData?.series)
                );
                const seriesIndex = tempSeries.findIndex(
                    (item) => item?.series_id == selectedEvent?.series_id
                );
                if (seriesIndex == -1) return;

                const eventIndex = tempSeries[seriesIndex].events.findIndex(
                    (event) => event.event_id == selectedEvent?.event_id
                );
                if (eventIndex == -1) return;

                tempSeries[seriesIndex].events[eventIndex].chat = updatedChat;
                const updateData = {
                    ...sermonsData,
                    series: tempSeries,
                };
                dispatch(setSermonsData(updateData));
                // dispatch(setLiveStreamChat(updatedChat));
            })
            .catch((error) => {
                console.log("ERRRR>", error);
            });
    };

    const ChatComp = React.useCallback(
        ({
            item,
            visibleReactionId,
            handleMouseEnter,
            handleMouseLeave,
            deleteMessage,
        }) => {
            return (
                <ChatItem
                    item={item}
                    visibleReactionId={visibleReactionId}
                    handleMouseEnter={handleMouseEnter}
                    handleMouseLeave={handleMouseLeave}
                    deleteMessage={deleteMessage}
                />
            );
        },
        []
    );

    return (
        <div
            className={"chatBox d-flex flex-column"}
            style={{
                borderRadius: borderRadius,
                overflow: "hidden",
                // width: "35%",
                height: "100%",
                width: width,
            }}
        >
            <div
                ref={chatSectionRef}
                className={`chat-Section ${
                    data && data?.length == 0
                        ? "d-flex align-items-center justify-content-center"
                        : ""
                } `}
                style={{
                    overflow: "auto",
                    height: "100%",
                }}
            >
                {data && data?.length ? (
                    data.map((item, i) => {
                        return (
                            <ChatComp
                                key={item.id}
                                item={item}
                                visibleReactionId={visibleReactionId}
                                handleMouseEnter={handleMouseEnter}
                                handleMouseLeave={handleMouseLeave}
                                deleteMessage={deleteMessage}
                            />
                        );
                    })
                ) : (
                    <span>No chat found</span>
                )}
            </div>

            <div
                className={`d-flex align-items-center col-md-12 justify-content-between mb-3 ${
                    isFocused ? "input-container" : ""
                }`}
                style={{
                    backgroundColor: "#212121",
                    padding: 10,
                    borderRadius: 5,
                }}
            >
                <div className="col-md-8">
                    <input
                        onFocus={() => setFocused(true)}
                        onBlur={() => setFocused(false)}
                        className="chat-Input"
                        style={{ width: "100%" }}
                        placeholder="Type something"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        disabled={
                            liveEvent &&
                            liveEvent?.stream_session_id ===
                                selectedEvent?.stream_session_id
                                ? false
                                : true
                        }
                    />
                </div>
                <div className="col-md-4 d-flex  justify-content-end gap-2">
                    <img className="broadcast" src={broadcast} alt="Logo" />
                    <Send
                        style={{ cursor: "pointer" }}
                        onClick={() => sendMessage()}
                        color="#3578ff"
                        pointerEvents={
                            liveEvent &&
                            liveEvent?.stream_session_id ===
                                selectedEvent?.stream_session_id
                                ? "all"
                                : "none"
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default Chat;
