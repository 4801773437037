import React, { useState } from "react";
import { Eye, EyeOff } from "lucide-react";

const PasswordInput = ({ placeholder, value, setText, type }) => {
  const [showPass, setShowPass] = useState(false);

  return (
    <div className="d-flex align-items-center justify-content-between gap-2 modal-input">
      <input
        value={value}
        type={showPass ? "text" : "password"}
        onChange={(e) => setText && setText(e.target.value)}
        placeholder={placeholder}
        style={{
          width: "100%",
          outline: "none",
          backgroundColor: "transparent",
          border: "unset",
          color: "#fff",
        }}
      />
      {showPass ? (
        <Eye onClick={() => setShowPass(!showPass)} size={20} color="white" />
      ) : (
        <EyeOff
          onClick={() => setShowPass(!showPass)}
          size={20}
          color="white"
        />
      )}
    </div>
  );
};

export default PasswordInput;
