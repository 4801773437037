import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  loginError: null,
  userData: null,
  userProfile: null,
  pageId: "",
  pages: [],
};

const slice = createSlice({
  name: "login",
  initialState,
  reducers: {
    requestLogin(state, action) {
      state.isLoading = action.payload;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.loginError = action.payload;
    },
    loginSuccess(state, action) {
      state.isLoading = false;
      state.userData = action.payload;
    },
    setUserProfile(state, action) {
      state.isLoading = false;
      state.userProfile = action.payload;
    }
  },
});

// Reducer
export default slice.reducer;
//Actions
export const {
  requestLogin,
  hasError,
  loginSuccess,
  setUserProfile,
} = slice.actions;
