// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getDatabase} from 'firebase/database'
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCpfMdAxigQ3pjgV6Kt7Ndh3voizZNU9kM",
  authDomain: "redefine-church-8de34.firebaseapp.com",
  databaseURL: "https://redefine-church-8de34-default-rtdb.firebaseio.com",
  projectId: "redefine-church-8de34",
  storageBucket: "redefine-church-8de34.appspot.com",
  messagingSenderId: "434890517030",
  appId: "1:434890517030:web:e962d486f48b30e4a614e7",
  measurementId: "G-BLFVPXBS7Z"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const database = getDatabase(app);

// const analytics = getAnalytics(app);