import React, { useState, useEffect } from "react";
import "../../home/styles.css";
import Assets from "../../../assets/images";
import { useSelector } from "react-redux";

const SermonWidget = ({ widgetId, itemStyles }) => {
  const universalStyles = useSelector(
    (state) => state.bodyStyling.universalStyles
  );

  const [border, setBorder] = useState("");
  const [defaultCorners, setDefaultCorners] = useState("true");

  useEffect(() => {
    if (itemStyles) {
      setDefaultCorners(itemStyles.defaultCorners);
      setBorder(itemStyles.border);
    }
  }, [itemStyles]);

  useEffect(() => {
    if (universalStyles.corners && itemStyles.defaultCorners == "true") {
      setBorder(universalStyles.corners);
    }
  }, [universalStyles.corners, itemStyles]);

  const sermons = [
    { id: 1, name: "Blessed", img: Assets.sermon1 },
    { id: 2, name: "Stride, Not strive", img: Assets.sermon2 },
    { id: 3, name: "Nicholes series", img: Assets.sermon3 },
    { id: 4, name: "Believe", img: Assets.sermon4 },
    { id: 5, name: "Andy's series", img: Assets.sermon5 },
    { id: 6, name: "Purity", img: Assets.sermon6 },
    { id: 25, name: "Nicholes series", img: Assets.sermon3 },
    { id: 16, name: "Believe", img: Assets.sermon4 },
    { id: 23, name: "Andy's series", img: Assets.sermon5 },
    { id: 63, name: "Purity", img: Assets.sermon6 },
  ];

  const series = [
    { id: 3, name: "Nicholes series", img: Assets.sermon3 },
    { id: 4, name: "Believe", img: Assets.sermon4 },
    { id: 5, name: "Andy's series", img: Assets.sermon5 },
    { id: 6, name: "Purity", img: Assets.sermon6 },
    { id: 25, name: "Nicholes series", img: Assets.sermon3 },
    { id: 16, name: "Believe", img: Assets.sermon4 },
    { id: 23, name: "Andy's series", img: Assets.sermon5 },
    { id: 63, name: "Purity", img: Assets.sermon6 },
  ];

  return (
    <div
      className={`d-flex sermon-Cont`}
      style={{
        borderRadius: border === "round" ? 20 : border === "sharp" ? 0 : null,
      }}
    >
      {/*1st  */}
      <div className="col-md-6 sermon-wrapper" style={{ paddingBottom: 50 }}>
        <p className="sermon-heading"
        >
          Sermons
        </p>
        <div
          className="d-flex flex-column sermon-section"
        >
          {sermons.map((item) => {
            return (
              <div
                className="d-flex align-items-center"
                style={{
                  backgroundColor: "#232323",
                  borderRadius: 6,
                  padding: 4,
                  columnGap: 10,
                  cursor: "pointer",
                  // flexWrap: "wrap",
                }}
              >
                <img
                  src={item.img}
                  style={{ height: 50, width: 60, borderRadius: 6 }}
                />
                <p
                  style={{
                    color: "#ffffff",
                    fontSize: 12,
                    margin: 0,
                    flexWrap: "wrap",
                  }}
                >
                  {item.name}
                </p>
              </div>
            );
          })}
        </div>
      </div>
      {/* 2nd */}
      <div className="col-md-6 sermon-series-wrapper" style={{ paddingBottom: 50 }}>
        <p className="sermon-heading">Sermons Series</p>
        <div className="d-flex flex-column sermon-series-section">
          {series.map((item) => {
            return (
              <div
                className="d-flex align-items-center"
                style={{
                  backgroundColor: item.id == 3 ? "#007bff" : "#232323",
                  borderRadius: 6,
                  padding: 4,
                  columnGap: 10,
                  cursor: "pointer",
                }}
              >
                <img
                  src={item.img}
                  style={{ height: 50, width: 60, borderRadius: 6 }}
                />
                <p
                  style={{
                    color: "#ffffff",
                    fontSize: 12,
                    margin: 0,
                    flexWrap: "wrap",
                  }}
                >
                  {item.name}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SermonWidget;
