import React, { useEffect, useRef } from "react";
import Hls from "hls.js";

const HLSSource = ({
  src,
  video,
  type = "application/x-mpegURL",
  setError,
  clearError,
}) => {
  const hlsRef = useRef(null);

  useEffect(() => {
    if (Hls.isSupported()) {
      const hls = new Hls();
      hlsRef.current = hls;
      hls.loadSource(src);
      hls.attachMedia(video);
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        if (!document.hidden) {
          const promise = video.play();
          if (promise !== undefined) {
            promise
              .then(() => clearError()) // Clear error on successful play
              .catch((error) => {
                console.log("Play was prevented: ", error);
              });
          } else {
            clearError(); // Clear error if promise is undefined and play is successful
          }
        }
      });
      // Event listener for errors
      hls.on(Hls.Events.ERROR, (event, data) => {
        const { type, details, fatal } = data;
        console.error(
          `HLS.js error: Type=${type}, Details=${details}, Fatal=${fatal}`
        );

        if (fatal) {
          switch (type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              // Try to recover network error
              // console.error(
              //   "Fatal network error encountered, trying to recover..."
              // );
              setError("Fatal network error encountered, trying to recover...");
              hls.startLoad();
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              // Try to recover media error
              // console.error(
              //   "Fatal media error encountered, trying to recover..."
              // );
              setError("Fatal media error encountered, trying to recover...");
              hls.recoverMediaError();
              break;
            default:
              // Cannot recover
              // console.error(
              //   "Unrecoverable error encountered, destroying HLS instance..."
              // );
              setError(
                "Unrecoverable error encountered, destroying HLS instance..."
              );
              hls.destroy();
              break;
          }
        }
      });

      return () => {
        if (hlsRef.current) {
          hlsRef.current.destroy();
        }
      };
    }
  }, [src, video]);

  return <source src={src} type={type} />;
};

export default HLSSource;
