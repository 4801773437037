import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setConnectData, setLoading } from "../../store/slices/connectSlice.js";
import moment from "moment";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import toast from "react-hot-toast";
import placeholder from "../../assets/placeholder.png";
import { Circles } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { Link2 } from "lucide-react";
import { removeStartingSlash as removeSlash } from "../../utills/RemoveSlash.js";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "react-datepicker/dist/react-datepicker.css";
import { Progress } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { database } from "../../firebase/firebaseConfig.js";
import { ref, onChildAdded, off, onValue } from "firebase/database";
import XHR from "../../utills/XHR.js";
import "../../globals.css";
import LikeBar from "../../components/Reactions/LikeBar.js";
import InfiniteScroll from "react-infinite-scroller";
import DonationConfirmationModal from "../../components/Modal/DonationConfirmation.js";
import {
    setNotificationBadge,
    setMessageBadge,
    setSystemUserData,
} from "../../store/slices/loginSlice.js";
import {
    setStyling,
    updateUniversalStyles,
} from "../../store/slices/bodyStyling.js";
import PostVideo from "../../components/PostVideo/index.js";
import LiveProfile from "../../components/LiveProfile/index.js";
import liveGif from "../../assets/giphy2.gif";

const TimeLine = ({ darkMode }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userDetails = useSelector((state) => state.login.userProfile);
    const isLoading = useSelector((state) => state.connect.isLoading);
    const timeLineData = useSelector((state) => state.connect.timeLineData);
    const user = useSelector((state) => state.login.userData);
    // system user
    const isSystemUser = useSelector((state) => state.login.isSystemUser);
    const systemUserData = useSelector((state) => state.login.systemUserData);
    //

    const hostname = process.env.REACT_APP_BASE_URL;

    const [timeline_posts, setTimLinePosts] = useState([]);
    const [ConnectSuggesstions, setConnectionSuggestions] = useState([]);
    const [upcomingEvents, setUpcomingEvents] = useState([]);
    const [event_requests, setEvenetRequests] = useState([]);
    const [connectionRequests, setConnectionRequest] = useState([]);

    const [amount, setAmount] = useState("");
    const [givingData, setGivingData] = useState(false);
    const [paymentLoader, setPaymentLoader] = useState(false);
    const [defaultCard, setDefaultCard] = useState({});

    const [percentage, setPercentage] = useState(0);
    const [paidAmount, setPaidAmount] = useState(0);
    const [totalGoal, setTotalGoal] = useState(0);

    const [postType, setType] = useState("Question");
    const [text, setText] = useState("");
    const [videoFile, setFile] = useState({ src: null, file: null });
    const [selectedImg, setSelectedImg] = useState(null);

    const [page, setPage] = useState({ currentPage: 1, lastPage: 1 });
    const [loadingMore, setLoadingMore] = useState(false);

    const [confirmModal, setConfirmModal] = useState(false);

    const [eventLoader, setEventLoader] = useState({ id: null, type: null });

    const [addPostLoader, setAddPostLoader] = useState(false);

    const scrollParentRef = useRef(null);
    const lastDonationTime = useRef(null);

    let newItems = false;

    useEffect(() => {
        requestData(1);
        requestGiving();
    }, []);

    useEffect(() => {
        // add posts listener
        const addPostRef = ref(database, "PostCreated");
        onChildAdded(addPostRef, handlePosts);
        // update posts listener
        const postUpdateRef = ref(database, "PostUpdated");
        onChildAdded(postUpdateRef, handlePostUpdate);
        // add posts comment listener
        const PostCommentRef = ref(database, "PostCommentAdded");
        onChildAdded(PostCommentRef, handlePostCommment);
        // delete posts listener
        const DelPostsRef = ref(database, "PostDeleted");
        onChildAdded(DelPostsRef, handlePostDelete);
        // add posts listener
        const PostReactionChangedRef = ref(database, "PostReactionChanged");
        onChildAdded(PostReactionChangedRef, handlePostReaction);

        // handle initial items
        // onValue(addPostRef, handleInitialItems)
        onValue(ref(database), handleInitialItems);

        // return () => {
        //     off(addPostRef, 'child_added')
        //     off(PostReactionChangedRef, 'child_added')
        //     off(postUpdateRef, 'child_added')
        // }
    }, []);

    const handleInitialItems = () => {
        newItems = true;
    };

    const handlePosts = (snapshot) => {
        if (!newItems) return;
        if (snapshot.exists()) {
            console.log("NEW POST>>", snapshot.val());
            const e = snapshot.val();
            if (e.group_id == null) {
                setTimLinePosts((prev) => {
                    const updatedPosts = [...prev];
                    const data = {
                        ...e,
                        id: e.post_id,
                        created_at: e.post_created_at,
                        updated_at: null,
                        reactions: [],
                        comments: [],
                    };
                    updatedPosts.unshift(data); // Append the new post at the beginning of the array
                    return updatedPosts;
                });
            }
        }
    };

    const handlePostReaction = (snapshot) => {
        if (!newItems) return;
        if (snapshot.exists()) {
            console.log("Post reaction", snapshot.val());
            const e = snapshot.val();
            const loggedinUserId = user?.id;
            if (e) {
                const { postId, userId } = e;
                setTimLinePosts((prev) => {
                    const updatedPosts =
                        prev &&
                        prev.map((post) => {
                            if (post.id == postId) {
                                const updatedReactions = [...post.reactions]; // Create a copy of reactions array
                                const existingReactionIndex =
                                    updatedReactions.findIndex(
                                        (reaction) => reaction.user_id == userId
                                    );
                                if (existingReactionIndex !== -1) {
                                    updatedReactions.splice(
                                        existingReactionIndex,
                                        1
                                    );
                                    const data = {
                                        ...post,
                                        reactions: updatedReactions,
                                        is_reacted:
                                            userId == loggedinUserId
                                                ? false
                                                : post.is_reacted,
                                    };
                                    // Create a new post object with updated reactions and is_reacted value
                                    return data;
                                } else {
                                    updatedReactions.push({
                                        ...e,
                                        reaction_id: e.reactionId,
                                        post_id: postId,
                                        user_id: userId,
                                    });
                                    const data = {
                                        ...post,
                                        reactions: updatedReactions,
                                        is_reacted:
                                            userId == loggedinUserId
                                                ? true
                                                : post.is_reacted,
                                    };
                                    // Update is_reacted if necessary
                                    return data;
                                }
                            }
                            return post;
                        });
                    return updatedPosts;
                });
            }
        }
    };

    const handlePostUpdate = (snapshot) => {
        if (!newItems) return;
        if (snapshot.exists()) {
            const e = snapshot.val();
            console.log("REAL TIME POST UPDATE", e);
            setTimLinePosts((prev) => {
                const index =
                    prev && prev.findIndex((item) => item.id == e.post.id);
                if (index !== -1) {
                    const newArray = [...prev];
                    newArray[index] = { ...newArray[index], ...e.post };
                    return newArray;
                }
                return prev;
            });
        }
    };

    const handlePostCommment = (snapshot) => {
        if (!newItems) return;
        if (snapshot.exists()) {
            const e = snapshot.val();
            console.log("REAL TIME COMMENT UPDATE", e);
            if (e.is_reply == 0) {
                setTimLinePosts((prev) => {
                    const index = prev.findIndex(
                        (item) => item.id == e.post_id
                    );
                    if (index !== -1) {
                        const newArray = [...prev];
                        const comments = [
                            ...newArray[index].comments,
                            {
                                ...e,
                                replies: [],
                                reactions: [],
                                id: Number(e.CommentId),
                            },
                        ];
                        newArray[index] = { ...newArray[index], comments };
                        return newArray;
                    }
                    return prev;
                });
            }
        }
    };

    const handlePostDelete = (snapshot) => {
        if (!newItems) return;
        if (snapshot.exists()) {
            const e = snapshot.val();
            if (e?.post_id) {
                const { post_id } = e;
                console.log("REAL TIME Post Delete", e);
                setTimLinePosts((prev) => {
                    return prev.filter((item) => item.id !== Number(post_id));
                });
            }
        }
    };

    useEffect(() => {
        if (timeLineData) {
            const {
                timeline_posts,
                ConnectSuggesstions,
                upcomingEvents,
                event_requests,
                connectionRequests,
                timeline_posts_current_page = 1,
                timeline_posts_last_page = 1,
            } = timeLineData;
            if (timeline_posts_current_page && timeline_posts_last_page) {
                setPage({
                    currentPage: timeline_posts_current_page,
                    lastPage: timeline_posts_last_page,
                });
            }
            if (timeline_posts && timeline_posts_current_page > 1) {
                setTimLinePosts((prev) => {
                    const newData = [...prev, ...timeline_posts];
                    const removedDuplicates = newData.filter(
                        (item, index, self) =>
                            index === self.findIndex((t) => t.id === item.id) // Use the id or another unique property
                    );
                    return removedDuplicates;
                });
            } else {
                setTimLinePosts(timeline_posts);
            }

            if (ConnectSuggesstions) {
                const newArray = ConnectSuggesstions.map((item) => {
                    return { ...item, requestState: "canceled" };
                });
                newArray && setConnectionSuggestions(newArray);
            }
            setUpcomingEvents(upcomingEvents);
            setEvenetRequests(event_requests);
            setConnectionRequest(connectionRequests);
        }
    }, [timeLineData]);

    const requestData = (page) => {
        const options = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user.api_token}`,
            },
            method: "GET",
        };
        page == 1 && dispatch(setLoading(true));
        XHR(
            `${hostname}/api/v1/getTimeline?user_id=${user.id}&&page=${page}`,
            options
        )
            .then((resp) => {
                if (resp.data.success) {
                    dispatch(setConnectData(resp.data));
                    dispatch(
                        updateUniversalStyles(resp?.data?.universal_styles)
                    );
                    // system user data
                    dispatch(setSystemUserData(resp?.data?.systemUser));
                    setTimeout(() => {
                        // Chat badge
                        if (resp.data?.unreadConversations > 0) {
                            dispatch(setMessageBadge(true));
                        } else {
                            dispatch(setMessageBadge(false));
                        }
                        if (resp.data?.unreadNotifications > 0) {
                            dispatch(setNotificationBadge(true));
                        } else {
                            dispatch(setNotificationBadge(false));
                        }
                    }, 500);
                }
            })
            .catch((e) => {
                console.log("ERR>", e);
            })
            .finally(() => {
                page == 1 ? dispatch(setLoading(false)) : setLoadingMore(false);
            });
    };

    const [givingLoader, setGivingLoader] = useState(false);

    const requestGiving = () => {
        const options = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user.api_token}`,
            },
            method: "GET",
        };
        setGivingLoader(true);
        XHR(`${hostname}/api/v1/getGiving?user_id=${user.id}`, options)
            .then((resp) => {
                if (resp.data.success) {
                    const { default_card, giving } = resp.data;
                    giving && setGivingData(giving);
                    default_card && setDefaultCard(default_card);
                    if (giving) {
                        if (giving.goal.percentage) {
                            setPercentage(giving.goal.percentage);
                        }
                        if (giving.goal.paid_amount) {
                            setPaidAmount(giving.goal.paid_amount);
                        }
                        if (giving.goal.amount) {
                            setTotalGoal(giving.goal.amount);
                        }
                    }
                }
            })
            .catch((e) => {
                console.log("ERR>", e);
            })
            .finally(() => {
                setGivingLoader(false);
                setAmount("");
            });
    };

    const addPost = (close) => {
        if (addPostLoader) return;
        if (
            selectedImg == null &&
            text.trim().length == 0 &&
            videoFile.file == null
        ) {
            toast.error("Please add some content to post");
            return;
        }

        setAddPostLoader(true);
        const formData = new FormData();
        if (selectedImg) {
            formData.append("post_img", selectedImg);
        }
        formData.append(
            "user_id",
            !isSystemUser ? user.id : systemUserData?.user_id
        );
        formData.append("post_type", postType);
        formData.append("post_privacy", privacyType);
        formData.append("post_content", text);
        if (videoFile.file) {
            formData.append("post_video", videoFile.file);
        }
        const options = {
            method: "post",
            url: `${hostname}/api/v1/addPost`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${user.api_token}`,
            },
            data: formData,
        };

        XHR(options)
            .then((resp) => {
                if (resp.data.success) {
                    toast.success(resp.data.message);
                    setText("");
                    close();
                }
            })
            .catch((e) => {
                console.log("ERR>", e);
            })
            .finally(() => {
                setAddPostLoader(false);
            });
    };

    const handleConnectionRequest = (id, state) => {
        const options = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user.api_token}`,
            },
            method: "POST",
            data: {
                sender_id: user.id,
                reciever_id: id,
                action: state,
            },
        };
        XHR(`${hostname}/api/v1/sendConnectionRequest`, options)
            .then((response) => {
                if (response.data.success) {
                    setConnectionSuggestions((prevState) =>
                        prevState.map((item) => {
                            if (
                                item.user_details &&
                                item.user_details.user_id == id
                            ) {
                                return {
                                    ...item,
                                    requestState:
                                        state === "send"
                                            ? "requested"
                                            : "canceled",
                                };
                            }
                            return item;
                        })
                    );
                    toast.success(response?.data?.message);
                }
                console.log("RESP>", response);
            })
            .catch((error) => {
                console.log("ERRRR>", error);
            });
    };

    const handlePendingInvites = (invitation_id, status, eventId) => {
        const options = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user.api_token}`,
            },
            method: "POST",
            data: {
                invitation_id,
                status,
            },
        };
        setEventLoader({ id: invitation_id, type: status });
        XHR(`${hostname}/user-change-event-status`, options)
            .then((response) => {
                if (response.data.success) {
                    setEvenetRequests((prev) => {
                        return prev.filter((item) => item.id !== eventId);
                    });
                    toast.success(
                        status == 1
                            ? response?.data?.msg
                            : "You have declined the invitation."
                    );
                }
                console.log("RESP>", response);
            })
            .catch((error) => {
                console.log("ERRRR>", error);
            })
            .finally(() => {
                setEventLoader({ id: null, type: null });
            });
    };

    const handlePendingRequests = (id, action) => {
        const options = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user.api_token}`,
            },
            method: "POST",
            data: {
                connection_id: id,
            },
        };
        XHR(
            `${hostname}/api/v1/${
                action === "accept"
                    ? "acceptConnectionRequest"
                    : "rejectConnectionRequest"
            }`,
            options
        )
            .then((response) => {
                if (response.data.success) {
                    setConnectionRequest((prev) => {
                        return prev.filter((item) => item.id !== id);
                    });
                    toast.success(response?.data?.message);
                }
                console.log("RESP>", response);
            })
            .catch((error) => {
                console.log("ERRRR>", error);
            });
    };

    const donateNow = () => {
        const currentTime = Date.now();

        if (amount.trim().length === 0) {
            toast.error("Enter amount", { id: "8686237" });
            return;
        }

        // Check if last donation was within the last 10 seconds
        if (
            lastDonationTime.current &&
            currentTime - lastDonationTime.current < 10000 &&
            !confirmModal
        ) {
            setConfirmModal(true);
            return;
        }

        const options = {
            method: "post",
            url: `${hostname}/api/v1/stripePostPay?amount=${amount}&gift=No&datepicker_date=${moment(
                new Date()
            ).format("YYYY-MM-DD")}&customer=${
                defaultCard.customer
            }&currency_code=USD&card_id=${defaultCard.id}`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user.api_token}`,
            },
        };
        setPaymentLoader(true);
        XHR(options)
            .then((resp) => {
                if (resp.data.ordStatus === "success") {
                    // Update last donation timestamp
                    lastDonationTime.current = currentTime;

                    toast.success(resp.data.message);
                    setAmount("");
                    requestGiving();
                } else {
                    toast.error(resp.data.ordStatus);
                }
            })
            .catch((e) => {
                if (e?.response?.data?.message) {
                    toast.error(e.response.data.message, { duration: 4000 });
                }
                console.log("ERR>", e);
            })
            .finally(() => {
                setPaymentLoader(false);
                setConfirmModal(false);
            });
    };

    const postTypes = [
        { type: "Question" },
        { type: "Prayer Request" },
        { type: "Encouragement" },
    ];

    const privacyTypes = [
        { type: "Public", value: 1 },
        { type: "Connection only", value: 2 },
        { type: "Family only", value: 3 },
        { type: "Only me", value: 4 },
    ];
    const [privacyType, setPrivacyType] = useState("1");

    const fileInputRef = useRef(null);

    const handleButtonClick = () => {
        // Trigger the file input
        fileInputRef.current.click();
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile.size > 20000000) {
            toast.error("File size should be less than 20MB.", {
                duration: 2500,
            });
            return;
        }

        if (selectedFile) {
            const fileType = selectedFile.type;

            if (fileType.startsWith("image/")) {
                setFile({ file: null, src: null });
                const reader = new FileReader();
                reader.onload = (event) => {
                    setSelectedImg(event.target.result); // Preview image
                };
                reader.readAsDataURL(selectedFile);
            } else if (fileType.startsWith("video/")) {
                setSelectedImg(null);
                const videoURL = URL.createObjectURL(selectedFile);
                setFile({ src: videoURL, file: selectedFile });
            } else {
                toast.error("Please select a valid image or video file.", {
                    duration: 2500,
                });
            }
        }
    };

    const onEnd = () => {
        if (page.currentPage < page.lastPage && !loadingMore) {
            setLoadingMore(true);
            const nextPage = page.currentPage + 1;
            requestData(nextPage);
        }
    };

    const ReanderLikeBar = useCallback(({ item, isLive }) => {
        return <LikeBar postData={item} edit={false} isLive={isLive} />;
    }, []);

    return (
        <>
            {/* <section className="profilemain1"> */}
            <div className="container-fluid">
                <div className="row mt-4 justify-content-around pb-4 mainCont ">
                    <div
                        className="col-md-3 flex-column leftCol"
                        // style={{width:'30%'}}
                    >
                        {/* New Giving */}
                        <div className="col-md-12 givingCont">
                            <div className="topPart" style={{ padding: 20 }}>
                                <h4
                                    style={{
                                        fontSize: 16,
                                        color: "white",
                                        letterSpacing: 3,
                                        lineHeight: 1.2,
                                    }}
                                >
                                    GIVE
                                </h4>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="d-flex col-md-5 inputCont align-items-center ">
                                        <input
                                            className="givingInput"
                                            value={amount}
                                            type="number"
                                            min={0}
                                            onChange={(e) =>
                                                setAmount(e.target.value)
                                            }
                                            placeholder="Amount"
                                        />
                                    </div>
                                    <div
                                        onClick={() => {
                                            if (paymentLoader) return;
                                            if (defaultCard.id) {
                                                donateNow();
                                            } else {
                                                toast.error(
                                                    "Please add a payment method first at giving page.",
                                                    {
                                                        id: "no-payment-method",
                                                    }
                                                );
                                                setTimeout(() => {
                                                    toast.dismiss(
                                                        "no-payment-method"
                                                    );
                                                    navigate("/give");
                                                    window.scrollTo(0, 0);
                                                }, 2500);
                                            }
                                        }}
                                        className="giveButton"
                                        style={{ cursor: "pointer" }}
                                    >
                                        {paymentLoader ? (
                                            <Circles
                                                height="20"
                                                width="20"
                                                color="#fb5656"
                                                ariaLabel="circles-loading"
                                                wrapperClass=""
                                                visible={true}
                                            />
                                        ) : (
                                            <h4
                                                style={{
                                                    color: "#fb5656",
                                                    fontSize: 12,
                                                    margin: 0,
                                                    fontWeight: "600",
                                                    lineHeight: 1.2,
                                                }}
                                            >
                                                GIVE NOW
                                            </h4>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/* white part */}
                            <div
                                className="d-flex"
                                style={{
                                    backgroundColor: "#ffffff",
                                    borderBottomRightRadius: 15,
                                    borderBottomLeftRadius: 15,
                                }}
                            >
                                <div className="col-md-2">
                                    <Progress.Line
                                        showInfo={false}
                                        strokeWidth={40}
                                        vertical
                                        percent={percentage}
                                        // strokeColor="#fb5f5f"
                                        style={{ opacity: 0.9 }}
                                    />
                                </div>
                                <div className="d-flex flex-column col-md-10 align-items-center justify-content-center">
                                    {givingLoader ? (
                                        <Skeleton
                                            baseColor="#e8e8ec"
                                            height={20}
                                            width={100}
                                        />
                                    ) : (
                                        <h1
                                            style={{
                                                color: "#fb5f5f",
                                                fontWeight: "600",
                                                letterSpacing: 4,
                                                margin: 0,
                                                opacity: 0.9,
                                                lineHeight: 1.2,
                                            }}
                                        >
                                            {Number(percentage).toFixed(1)}%
                                        </h1>
                                    )}
                                    {givingLoader ? (
                                        <Skeleton
                                            baseColor="#e8e8ec"
                                            height={10}
                                            width={100}
                                        />
                                    ) : (
                                        <h4
                                            style={{
                                                color: "#fb5f5f",
                                                fontWeight: "400",
                                                letterSpacing: 4,
                                                margin: 0,
                                                lineHeight: 1.2,
                                            }}
                                        >
                                            {`$${paidAmount} | $${Number(
                                                totalGoal
                                            ).toFixed(0)}`}
                                        </h4>
                                    )}

                                    <p
                                        style={{
                                            color: "#000000",
                                            fontSize: 12,
                                            margin: 0,
                                            lineHeight: "10px",
                                            fontWeight: "600",
                                            marginTop: 10,
                                        }}
                                    >
                                        YOUR GIVING GOAL
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/*  */}

                        <div className="col-md-12 upcomingEventsCol px-2">
                            <p
                                className="px-2"
                                style={{
                                    color: darkMode ? "#ffffff" : "#000",
                                    fontSize: 14,
                                    fontWeight: "500",
                                    margin: 0,
                                }}
                            >
                                Upcoming Events
                            </p>
                            {isLoading ? (
                                <div className="eventCard mb-2">
                                    <Skeleton
                                        baseColor="#716e6e"
                                        height={105}
                                        width={"100%"}
                                    />
                                    <div className="`">
                                        <p
                                            style={{
                                                fontSize: 18,
                                                fontWeight: "500",
                                                marginBottom: 0,
                                                lineHeight: "22px",
                                            }}
                                        >
                                            <Skeleton
                                                baseColor="#716e6e"
                                                height={8}
                                                width={150}
                                            />
                                        </p>
                                        <p
                                            style={{
                                                fontSize: 12,
                                                margin: 0,
                                                lineHeight: "22px",
                                            }}
                                        >
                                            <Skeleton
                                                baseColor="#716e6e"
                                                height={6}
                                                width={150}
                                            />
                                        </p>
                                    </div>
                                    <div
                                        style={{ cursor: "pointer" }}
                                        className="viewBtn d-flex"
                                    >
                                        <p
                                            style={{
                                                fontSize: 12,
                                                lineHeight: "30px",
                                            }}
                                        >
                                            View Event
                                        </p>
                                    </div>
                                </div>
                            ) : (
                                upcomingEvents &&
                                upcomingEvents.length > 0 &&
                                upcomingEvents.map((item) => {
                                    return (
                                        <div className="eventCard mb-2">
                                            <img
                                                src={
                                                    item.series_banner !== null
                                                        ? `${hostname}/${removeSlash(
                                                              item.series_banner
                                                          )}`
                                                        : "https://images.ctfassets.net/1itkm9rji8jb/2Awcn6y97oIY6GvEnRDYEn/a749e51fd78f564ff012f47326fb83b2/Rectangle_7777.jpg"
                                                }
                                                alt=""
                                            />
                                            <div className="`">
                                                <p
                                                    style={{
                                                        fontSize: 18,
                                                        fontWeight: "500",
                                                        marginBottom: 0,
                                                        lineHeight: "22px",
                                                    }}
                                                >
                                                    {item.event_name}
                                                </p>
                                                <p
                                                    style={{
                                                        fontSize: 12,
                                                        margin: 0,
                                                        lineHeight: "22px",
                                                    }}
                                                >
                                                    {item.event_desc}
                                                </p>
                                            </div>
                                            <div
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    navigate("/event", {
                                                        state: item,
                                                    });
                                                }}
                                                className="viewBtn d-flex"
                                            >
                                                <p
                                                    style={{
                                                        fontSize: 12,
                                                        lineHeight: "30px",
                                                    }}
                                                >
                                                    View Event
                                                </p>
                                            </div>
                                        </div>
                                    );
                                })
                            )}
                        </div>
                    </div>

                    <div ref={scrollParentRef} className="col-md-6 postCol">
                        <div className="newpromainwrap hashomenewcentercon">
                            <div className="welcomeBox">
                                <div className="welcomeLeft">
                                    <h2>
                                        <span>Hello {user?.username},</span>
                                        Welcome Back !
                                    </h2>
                                </div>
                                <div className="welcomeboxright">
                                    <div className="notifictionocon">
                                        <span
                                            onClick={() =>
                                                navigate("/notifications")
                                            }
                                            style={{ cursor: "pointer" }}
                                        >
                                            <img
                                                style={{
                                                    height: 30,
                                                    width: 30,
                                                }}
                                                src="https://images.ctfassets.net/1itkm9rji8jb/7AmegEI5J8hdh0uVDigDN0/22c694254d1eeae3785c34a084860d58/bell.svg"
                                                alt=""
                                            />
                                        </span>
                                        <span
                                            onClick={() => navigate("/chat")}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <img
                                                style={{
                                                    height: 30,
                                                    width: 30,
                                                }}
                                                src="https://images.ctfassets.net/1itkm9rji8jb/28NuKJUreOvtYRu3VBnLQt/60d3c8b71e765e8ef4be391f2a2b77b6/msg.svg"
                                                alt=""
                                            />
                                        </span>
                                    </div>
                                    <div className="welcomebtn">
                                        <a>Not {user?.username}?</a>
                                        <a>Edit Profile</a>
                                    </div>
                                </div>
                            </div>
                            <div className="newpromaininner">
                                <div className="profilesecionmainwrwap">
                                    <div className="prosecright">
                                        <div className="serviceshortctuts">
                                            {/* <h3>Shortcuts</h3> */}
                                            <ul
                                                style={{
                                                    paddingLeft: 0,
                                                    margin: 0,
                                                }}
                                            >
                                                <li>
                                                    <a
                                                        onClick={() =>
                                                            navigate(
                                                                "/about-us"
                                                            )
                                                        }
                                                        // href={`/about-us`}
                                                        style={{
                                                            color: "transparent",
                                                        }}
                                                    >
                                                        <div className="shortcuticon">
                                                            <img
                                                                src="https://images.ctfassets.net/1itkm9rji8jb/3lN0kzgDKCaDzL35RQFIs5/137751fd975af808d56fc27e4a146218/shortcut1.svg"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="shotcusttext d-flex align-items-center justify-content-center">
                                                            About Us
                                                        </div>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        // href={`/serve`}
                                                        onClick={() =>
                                                            navigate("/serve")
                                                        }
                                                        style={{
                                                            color: "transparent",
                                                        }}
                                                    >
                                                        <div className="shortcuticon">
                                                            <img
                                                                src="https://images.ctfassets.net/1itkm9rji8jb/1Mxp4Bc0fIvcUp8YxA5BOm/885bcb0ccb3fee256fdf8fecf0b4f278/shortcut2.svg"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="shotcusttext d-flex align-items-center justify-content-center">
                                                            Serve
                                                        </div>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        // href={`/group`}
                                                        onClick={() =>
                                                            navigate("/group")
                                                        }
                                                        style={{
                                                            color: "transparent",
                                                        }}
                                                    >
                                                        <div className="shortcuticon">
                                                            <img
                                                                src="https://images.ctfassets.net/1itkm9rji8jb/15NNrNowSOZry26wR9C5Xq/4f1f4e12086acdedda8716b8fb35e1cc/shortcut3.svg"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="shotcusttext d-flex align-items-center justify-content-center">
                                                            Groups
                                                        </div>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        // href={`/kids`}
                                                        onClick={() =>
                                                            navigate("/kids")
                                                        }
                                                        style={{
                                                            color: "transparent",
                                                        }}
                                                    >
                                                        <div className="shortcuticon">
                                                            <img
                                                                src="https://images.ctfassets.net/1itkm9rji8jb/499uuDruPAkbyaxnm48GFz/ab4e1839345134e81f092fa7a8e8b69b/shortcut4.svg"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="shotcusttext  d-flex align-items-center justify-content-center">
                                                            Kidz
                                                        </div>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <Popup
                                            // lockScroll={true}
                                            contentStyle={{
                                                border: "unset",
                                                padding: 0,
                                                borderRadius: 10,
                                            }}
                                            modal={true}
                                            onClose={() => {
                                                setSelectedImg(null);
                                                setText("");
                                                setFile({
                                                    file: null,
                                                    src: null,
                                                });
                                            }}
                                            trigger={
                                                <div className="mindUpload hasboxbg">
                                                    <div className="uoloadtext">
                                                        <div className="profileimg">
                                                            <img
                                                                style={{
                                                                    height: 43,
                                                                    width: 43,
                                                                }}
                                                                src={
                                                                    isSystemUser &&
                                                                    systemUserData?.profile_pic
                                                                        ? `${hostname}/${removeSlash(
                                                                              systemUserData?.profile_pic
                                                                          )}`
                                                                        : userDetails?.profile_pic
                                                                        ? `${hostname}/${removeSlash(
                                                                              userDetails?.profile_pic
                                                                          )}`
                                                                        : placeholder
                                                                }
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="profileText">
                                                            <h4>
                                                                What&#39;s on
                                                                your mind,{" "}
                                                                {isSystemUser
                                                                    ? systemUserData?.username
                                                                    : user?.username}
                                                                ?
                                                            </h4>
                                                        </div>
                                                    </div>
                                                    <div className="profileimgUploadIcon">
                                                        <img
                                                            src="https://images.ctfassets.net/1itkm9rji8jb/GBEcAOKhA2yzHVXL3RhZQ/f9c2723924c977f8c393e2025672d06d/image-gallery-svgrepo-com.svg"
                                                            alt=""
                                                        />
                                                    </div>
                                                </div>
                                            }
                                            position="center center"
                                        >
                                            {(close) => (
                                                <div className="popupMain">
                                                    {addPostLoader && (
                                                        <div
                                                            className="d-flex align-items-center justify-content-center"
                                                            style={{
                                                                position:
                                                                    "absolute",
                                                                width: "100%",
                                                                height: "100%",
                                                                backgroundColor:
                                                                    "#fff",
                                                                opacity: 0.2,
                                                                borderRadius: 10,
                                                                zIndex: 1,
                                                            }}
                                                        >
                                                            <Circles
                                                                height="40"
                                                                width="40"
                                                                color="#000"
                                                                ariaLabel="circles-loading"
                                                                wrapperClass=""
                                                                visible={true}
                                                            />
                                                        </div>
                                                    )}
                                                    <div className="popupInner">
                                                        <div className="popupHeader d-flex">
                                                            <div>
                                                                <h5>
                                                                    Select Topic
                                                                </h5>
                                                                <ul
                                                                    style={{
                                                                        listStyleType:
                                                                            "none",
                                                                    }}
                                                                >
                                                                    {postTypes.map(
                                                                        (
                                                                            item
                                                                        ) => {
                                                                            return (
                                                                                <li
                                                                                    key={
                                                                                        item.id
                                                                                    }
                                                                                    style={{
                                                                                        cursor: "pointer",
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        setType(
                                                                                            item.type
                                                                                        );
                                                                                    }}
                                                                                    className={
                                                                                        postType ===
                                                                                        item.type
                                                                                            ? "active"
                                                                                            : ""
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        item.type
                                                                                    }
                                                                                </li>
                                                                            );
                                                                        }
                                                                    )}
                                                                </ul>
                                                            </div>
                                                            <div>
                                                                <h5
                                                                    style={{
                                                                        width: "unset",
                                                                        marginBottom: 10,
                                                                    }}
                                                                >
                                                                    Select
                                                                    Privacy
                                                                </h5>
                                                                <select
                                                                    style={{
                                                                        height: 30,
                                                                        borderRadius: 4,
                                                                        backgroundColor:
                                                                            "dimgray",
                                                                    }}
                                                                    value={
                                                                        privacyType
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        setPrivacyType(
                                                                            e
                                                                                .target
                                                                                .value
                                                                        );
                                                                    }}
                                                                >
                                                                    {privacyTypes.map(
                                                                        (
                                                                            item
                                                                        ) => {
                                                                            return (
                                                                                <option
                                                                                    key={
                                                                                        item.value
                                                                                    }
                                                                                    value={
                                                                                        item.value
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        item.type
                                                                                    }
                                                                                </option>
                                                                            );
                                                                        }
                                                                    )}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="mindUpload">
                                                            <div className="uoloadtext">
                                                                <div className="profileimg">
                                                                    <img
                                                                        src={
                                                                            isSystemUser &&
                                                                            systemUserData?.profile_pic
                                                                                ? `${hostname}/${removeSlash(
                                                                                      systemUserData?.profile_pic
                                                                                  )}`
                                                                                : userDetails?.profile_pic
                                                                                ? `${hostname}/${removeSlash(
                                                                                      userDetails?.profile_pic
                                                                                  )}`
                                                                                : placeholder
                                                                        }
                                                                        // src="https://images.ctfassets.net/1itkm9rji8jb/4iHI7UAQ5QpNc4CRvaF9Im/68f1a121dc99dc9ca1d34272e9deedb3/Mask.jpg"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div className="profileText">
                                                                    <h4>
                                                                        {isSystemUser
                                                                            ? systemUserData?.username
                                                                            : user?.username}
                                                                    </h4>
                                                                </div>
                                                            </div>
                                                            <div
                                                                onClick={() =>
                                                                    handleButtonClick()
                                                                }
                                                                className=""
                                                            >
                                                                <img
                                                                    src="https://images.ctfassets.net/1itkm9rji8jb/GBEcAOKhA2yzHVXL3RhZQ/f9c2723924c977f8c393e2025672d06d/image-gallery-svgrepo-com.svg"
                                                                    alt=""
                                                                    style={{
                                                                        height: 22,
                                                                    }}
                                                                />
                                                                <input
                                                                    type="file"
                                                                    ref={
                                                                        fileInputRef
                                                                    }
                                                                    style={{
                                                                        display:
                                                                            "none",
                                                                    }}
                                                                    onChange={
                                                                        handleFileChange
                                                                    }
                                                                    accept="image/*,video/*"
                                                                />
                                                            </div>
                                                        </div>

                                                        <div
                                                            className="postContent"
                                                            style={{
                                                                marginBottom: 0,
                                                            }}
                                                        >
                                                            <p
                                                                className="editable"
                                                                onBlur={(e) => {
                                                                    setText(
                                                                        e
                                                                            .currentTarget
                                                                            .textContent
                                                                    );
                                                                }}
                                                                data-placeholder="what's on your mind?"
                                                                contentEditable
                                                                // aria-multiline
                                                                style={{
                                                                    maxHeight: 70,
                                                                    padding: 10,
                                                                    overflowY:
                                                                        "scroll",
                                                                    color: "#fff",
                                                                }}
                                                            >
                                                                {text}
                                                            </p>
                                                            <div className="postedImage">
                                                                {selectedImg && (
                                                                    <img
                                                                        style={{
                                                                            height: 200,
                                                                            objectFit:
                                                                                "contain",
                                                                        }}
                                                                        src={
                                                                            selectedImg
                                                                        }
                                                                        // src="https://images.ctfassets.net/1itkm9rji8jb/7aosR4rBhaGcquUCrya7xJ/6f1327b226d7eec22d47b5e8906a0a05/DCU1K79VLM.jpg"
                                                                        alt=""
                                                                    />
                                                                )}
                                                                {videoFile.src && (
                                                                    <div
                                                                        className="d-flex align-items-center justify-content-center"
                                                                        style={{
                                                                            height: 300,
                                                                        }}
                                                                    >
                                                                        <PostVideo
                                                                            src={
                                                                                videoFile.src
                                                                            }
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div
                                                                onClick={() => {
                                                                    addPost(
                                                                        close
                                                                    );
                                                                }}
                                                                className="postBtn"
                                                            >
                                                                <p className="hasnewbtn">
                                                                    Post
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Popup>

                                        {isLoading ? (
                                            <div className="mindUploadPost hasboxbg">
                                                <div className="uoloadtextmain">
                                                    <div className="uoloadtext">
                                                        <div className="profileimg">
                                                            <Skeleton
                                                                baseColor="#716e6e"
                                                                circle
                                                                height={50}
                                                                width={50}
                                                            />
                                                        </div>
                                                        <div className="profileText">
                                                            <Skeleton
                                                                baseColor="#716e6e"
                                                                count={2}
                                                                height={8}
                                                                width={100}
                                                            />
                                                        </div>
                                                        <Skeleton
                                                            className="tag"
                                                            baseColor="#716e6e"
                                                            count={1}
                                                            height={20}
                                                            width={100}
                                                        />
                                                    </div>
                                                    <div className="comenttag">
                                                        <Skeleton
                                                            className="time"
                                                            baseColor="#716e6e"
                                                            count={1}
                                                            height={10}
                                                            width={60}
                                                        />
                                                    </div>
                                                </div>
                                                <a>
                                                    <div className="postContent">
                                                        <Skeleton
                                                            baseColor="#716e6e"
                                                            count={2}
                                                            height={6}
                                                            width={100}
                                                        />
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <Skeleton
                                                                baseColor="#716e6e"
                                                                count={1}
                                                                height={160}
                                                                width={300}
                                                            />
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        ) : (
                                            <InfiniteScroll
                                                pageStart={0}
                                                loadMore={onEnd}
                                                hasMore={
                                                    page.currentPage <
                                                        page.lastPage &&
                                                    !loadingMore
                                                }
                                                useWindow={false}
                                                getScrollParent={() =>
                                                    scrollParentRef.current
                                                }
                                            >
                                                {timeline_posts &&
                                                    timeline_posts.map(
                                                        (item, i) => {
                                                            return (
                                                                <div
                                                                    key={
                                                                        item.id
                                                                    }
                                                                    className={`mindUploadPost hasboxbg ${
                                                                        timeline_posts &&
                                                                        timeline_posts.length -
                                                                            1 ==
                                                                            i
                                                                            ? "hasboxbg-no-bottom-margin"
                                                                            : ""
                                                                    } `}
                                                                >
                                                                    <div className="uoloadtextmain">
                                                                        <div
                                                                            onClick={() => {
                                                                                if (
                                                                                    item?.stream_session_id
                                                                                ) {
                                                                                    navigate(
                                                                                        "/watch"
                                                                                    );
                                                                                    return;
                                                                                }
                                                                                if (
                                                                                    item?.is_default_user
                                                                                ) {
                                                                                    return;
                                                                                }
                                                                                if (
                                                                                    item?.user_id ==
                                                                                    user?.id
                                                                                ) {
                                                                                    navigate(
                                                                                        "/userProfile"
                                                                                    );
                                                                                } else {
                                                                                    navigate(
                                                                                        "/otherUserProfile",
                                                                                        {
                                                                                            state: item.user_id,
                                                                                        }
                                                                                    );
                                                                                }
                                                                            }}
                                                                            className="uoloadtext"
                                                                            style={{
                                                                                flexWrap:
                                                                                    "wrap",
                                                                                rowGap: 10,
                                                                                width: "80%",
                                                                            }}
                                                                        >
                                                                            {!item?.islive ? (
                                                                                <div className="profileimg">
                                                                                    <img
                                                                                        src={
                                                                                            item?.profile_pic &&
                                                                                            item.profile_pic !==
                                                                                                null
                                                                                                ? `${hostname}/${removeSlash(
                                                                                                      item.profile_pic
                                                                                                  )}`
                                                                                                : placeholder
                                                                                        }
                                                                                        alt=""
                                                                                    />
                                                                                </div>
                                                                            ) : null}

                                                                            {item?.islive ? (
                                                                                <LiveProfile
                                                                                    item={
                                                                                        item
                                                                                    }
                                                                                    placeholder={
                                                                                        placeholder
                                                                                    }
                                                                                    hostname={
                                                                                        hostname
                                                                                    }
                                                                                />
                                                                            ) : null}

                                                                            <div
                                                                                style={{
                                                                                    cursor: "pointer",
                                                                                    maxWidth:
                                                                                        "50%",
                                                                                }}
                                                                                className="profileText"
                                                                            >
                                                                                <h4
                                                                                    className="two-line-ellipsis"
                                                                                    style={{
                                                                                        lineHeight: 1.4,
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        item.username
                                                                                    }
                                                                                </h4>
                                                                            </div>
                                                                            <span className="tag">
                                                                                {
                                                                                    item.post_type
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                        <div className="comenttag">
                                                                            <span className="time">
                                                                                {item?.updated_at !==
                                                                                    null &&
                                                                                    "edited "}
                                                                                {/* {moment(
                                                                                    item.created_at
                                                                                ).format(
                                                                                    "hh:mm A"
                                                                                )} */}
                                                                                {moment
                                                                                    .utc(
                                                                                        item?.created_at,
                                                                                        "YYYY-MM-DD HH:mm:ss"
                                                                                    )
                                                                                    .local()
                                                                                    .format(
                                                                                        "hh:mm A"
                                                                                    )}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <a
                                                                        onClick={() => {
                                                                            if (
                                                                                item?.stream_session_id
                                                                            ) {
                                                                                navigate(
                                                                                    "/watch"
                                                                                );
                                                                                return;
                                                                            }
                                                                            navigate(
                                                                                "/post",
                                                                                {
                                                                                    state: item.id,
                                                                                }
                                                                            );
                                                                        }}
                                                                    >
                                                                        <div className="postContent">
                                                                            <p className="mb-2">
                                                                                {
                                                                                    item.post_content
                                                                                }
                                                                            </p>

                                                                            {item.post_img &&
                                                                                !item?.stream_session_id && (
                                                                                    <div className="d-flex align-items-center justify-content-center">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            style={{
                                                                                                maxWidth:
                                                                                                    "100%",
                                                                                                height: "auto",
                                                                                            }}
                                                                                            src={`${hostname}/${removeSlash(
                                                                                                item.post_img
                                                                                            )}`}
                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                            {(item?.post_video ||
                                                                                item?.stream_session_id) && (
                                                                                <div
                                                                                    className="d-flex align-items-center justify-content-center"
                                                                                    style={{
                                                                                        height: 300,
                                                                                    }}
                                                                                >
                                                                                    <PostVideo
                                                                                        src={
                                                                                            item?.stream_session_id
                                                                                                ? `https://live.redefine.church/live/${item.stream_session_id}/index.m3u8`
                                                                                                : `${hostname}/${item.post_video}`
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            )}

                                                                            {/* LIVE POST */}
                                                                            {/* {item?.islive ? (
                                                                                <div
                                                                                    className="d-flex align-items-center justify-content-center"
                                                                                    style={{
                                                                                        height: 300,
                                                                                        position:
                                                                                            "relative",
                                                                                        overflow:
                                                                                            "hidden",
                                                                                        borderRadius: 10,
                                                                                    }}
                                                                                >
                                                                                    <img
                                                                                        loading="lazy"
                                                                                        style={{
                                                                                            height: "100%",
                                                                                            width: "100%",
                                                                                            position:
                                                                                                "absolute",
                                                                                        }}
                                                                                        src={`${hostname}/${removeSlash(
                                                                                            item.post_img
                                                                                        )}`}
                                                                                    />
                                                                                    <div className="blur-overlay" />
                                                                                    <img
                                                                                        // className="live-gif"
                                                                                        style={{
                                                                                            height: 100,
                                                                                            width: 100,
                                                                                            zIndex: 1,
                                                                                        }}
                                                                                        src={
                                                                                            liveGif
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            ) : null} */}
                                                                        </div>
                                                                    </a>
                                                                    <ReanderLikeBar
                                                                        item={
                                                                            item
                                                                        }
                                                                        isLive={
                                                                            item?.stream_session_id ||
                                                                            false
                                                                        }
                                                                    />
                                                                    {/* <LikeBar
                                                                        postData={
                                                                            item
                                                                        }
                                                                        edit={
                                                                            false
                                                                        }
                                                                    /> */}
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                                {loadingMore && (
                                                    <div className="d-flex align-items-center justify-content-center py-3">
                                                        <Circles
                                                            height="40"
                                                            width="40"
                                                            color="#fb5656"
                                                            ariaLabel="circles-loading"
                                                            wrapperClass=""
                                                            visible={true}
                                                        />
                                                    </div>
                                                )}
                                            </InfiniteScroll>
                                        )}
                                    </div>
                                    {/* } */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 rightCol">
                        {/* first portion */}
                        <div className="connectSuggestionContainer">
                            <p
                                className="px-2"
                                style={{
                                    fontSize: 14,
                                    fontWeight: "500",
                                    margin: 0,
                                    // lineHeight: "30px",
                                }}
                            >
                                Pending Requests & Connect Suggestions
                            </p>

                            {isLoading ? (
                                <div
                                    className="scrollCont"
                                    style={{ height: "42vh" }}
                                >
                                    <div className="d-flex mb-2 connectSuggestionCard ">
                                        <div
                                            style={{ cursor: "pointer" }}
                                            className="d-flex align-items-center justify-content-center"
                                        >
                                            <Skeleton
                                                circle
                                                baseColor="#716e6e"
                                                height={36}
                                                width={36}
                                            />
                                            <div
                                                className="connectconet"
                                                style={{ marginLeft: 6 }}
                                            >
                                                <p
                                                    style={{
                                                        lineHeight: "14px",
                                                        fontSize: 10,
                                                    }}
                                                >
                                                    <Skeleton
                                                        baseColor="#716e6e"
                                                        height={8}
                                                        width={150}
                                                    />
                                                </p>
                                            </div>
                                        </div>
                                        <div className="conlink">
                                            <span
                                                style={{
                                                    display: "flex",
                                                    borderRadius: 100,
                                                    height: 27,
                                                    width: 27,
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <Link2
                                                    style={{
                                                        transform:
                                                            "rotate(-45deg)",
                                                    }}
                                                    size={16}
                                                    color="white"
                                                />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div
                                    className="scrollCont"
                                    style={{ borderRadius: 15, height: "42vh" }}
                                >
                                    {connectionRequests &&
                                        connectionRequests.length > 0 &&
                                        connectionRequests.map((item) => {
                                            return (
                                                <div
                                                    key={item.id}
                                                    className="d-flex flex-column"
                                                    style={{
                                                        paddingInline: 10,
                                                        backgroundColor:
                                                            darkMode
                                                                ? "#202020"
                                                                : "#fff",
                                                        marginBottom: 10,
                                                        borderRadius: 7,
                                                        paddingBlock: 15,
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            alignItems:
                                                                "center",
                                                        }}
                                                        className="d-flex connectconetCon mb-2  "
                                                    >
                                                        <div
                                                            className="d-flex"
                                                            style={{
                                                                alignItems:
                                                                    "center",
                                                                justifyContent:
                                                                    "center",
                                                                columnGap: 10,
                                                            }}
                                                        >
                                                            <img
                                                                style={{
                                                                    height: 36,
                                                                    width: 36,
                                                                    borderRadius: 100,
                                                                }}
                                                                src={
                                                                    item.profile_pic !==
                                                                    null
                                                                        ? `${hostname}/${removeSlash(
                                                                              item.profile_pic
                                                                          )}`
                                                                        : placeholder
                                                                }
                                                            />
                                                            <p
                                                                style={{
                                                                    margin: 0,
                                                                    fontSize: 16,
                                                                }}
                                                            >
                                                                {item.username}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="actionsCont">
                                                        <a
                                                            onClick={() => {
                                                                handlePendingRequests(
                                                                    item?.id,
                                                                    "accept"
                                                                );
                                                            }}
                                                            style={{
                                                                textDecoration:
                                                                    "none",
                                                                cursor: "pointer",
                                                                fontSize: 12,
                                                            }}
                                                            className="accptbtn"
                                                        >
                                                            Accept
                                                        </a>
                                                        <a
                                                            onClick={() => {
                                                                handlePendingRequests(
                                                                    item?.id,
                                                                    "decline"
                                                                );
                                                            }}
                                                            style={{
                                                                textDecoration:
                                                                    "none",
                                                                cursor: "pointer",
                                                                fontSize: 12,
                                                            }}
                                                            className="declinebtn"
                                                        >
                                                            Decline
                                                        </a>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    {ConnectSuggesstions &&
                                        ConnectSuggesstions.map((item) => {
                                            return item.user_details !==
                                                null ? (
                                                <div className="d-flex mb-2 connectSuggestionCard ">
                                                    <div
                                                        onClick={() => {
                                                            navigate(
                                                                "/otherUserProfile",
                                                                {
                                                                    state: item.user_id,
                                                                }
                                                            );
                                                        }}
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                        className="d-flex align-items-center justify-content-center"
                                                    >
                                                        <img
                                                            style={{
                                                                height: 36,
                                                                width: 36,
                                                                borderRadius: 50,
                                                            }}
                                                            src={
                                                                item.user_details &&
                                                                item
                                                                    .user_details
                                                                    .profile_pic
                                                                    ? `${hostname}/${removeSlash(
                                                                          item
                                                                              .user_details
                                                                              .profile_pic
                                                                      )}`
                                                                    : placeholder
                                                            }
                                                        />
                                                        <div
                                                            className="connectconet"
                                                            style={{
                                                                marginLeft: 6,
                                                            }}
                                                        >
                                                            <p
                                                                style={{
                                                                    lineHeight:
                                                                        "14px",
                                                                    fontSize: 10,
                                                                }}
                                                            >
                                                                {/* Did you know {' '}
                                                                    {item.user_details ? item.user_details.first_name : ''} {' '}
                                                                    {item.user_details ? item.user_details.last_name : ''} {' '}
                                                                    also has kids and lives near you? */}
                                                                Did you know{" "}
                                                                {item.user_details
                                                                    ? item
                                                                          .user_details
                                                                          .first_name
                                                                    : ""}{" "}
                                                                {item.user_details
                                                                    ? item
                                                                          .user_details
                                                                          .last_name
                                                                    : ""}{" "}
                                                                and you are in
                                                                same team?
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="conlink">
                                                        <span
                                                            onClick={() => {
                                                                if (
                                                                    isSystemUser
                                                                )
                                                                    return;
                                                                handleConnectionRequest(
                                                                    item
                                                                        .user_details
                                                                        .user_id,
                                                                    item.requestState ===
                                                                        "requested"
                                                                        ? "canceled"
                                                                        : "send"
                                                                );
                                                            }}
                                                            style={{
                                                                display: "flex",
                                                                backgroundColor:
                                                                    item.requestState ===
                                                                    "canceled"
                                                                        ? "#3478FF"
                                                                        : "green",
                                                                borderRadius: 100,
                                                                height: 27,
                                                                width: 27,
                                                                alignItems:
                                                                    "center",
                                                                justifyContent:
                                                                    "center",
                                                            }}
                                                        >
                                                            <Link2
                                                                style={{
                                                                    transform:
                                                                        "rotate(-45deg)",
                                                                }}
                                                                size={16}
                                                                color="white"
                                                            />
                                                        </span>
                                                    </div>
                                                </div>
                                            ) : null;
                                        })}
                                </div>
                            )}
                        </div>
                        {/* 2nd portion */}
                        <div
                            className="connectSuggestionContainer"
                            style={{ marginBottom: 0 }}
                        >
                            <p
                                className="px-2"
                                style={{
                                    fontSize: 14,
                                    fontWeight: "500",
                                    margin: 0,
                                    lineHeight: "30px",
                                }}
                            >
                                Pending Invites
                            </p>
                            {isLoading ? (
                                <div className="scrollCont">
                                    <div
                                        className="d-flex flex-column"
                                        style={{
                                            paddingInline: 15,
                                            backgroundColor: darkMode
                                                ? "#202020"
                                                : "#fff",
                                            marginBottom: 10,
                                            borderRadius: 7,
                                            paddingBlock: 10,
                                        }}
                                    >
                                        <div className="connectconetCon">
                                            <p
                                                style={{
                                                    fontSize: 18,
                                                    fontWeight: "500",
                                                    marginBottom: 0,
                                                    lineHeight: "30px",
                                                }}
                                            >
                                                <Skeleton
                                                    baseColor="#716e6e"
                                                    height={8}
                                                    width={120}
                                                />
                                            </p>
                                            <p
                                                style={{
                                                    fontSize: 12,
                                                    margin: 0,
                                                    lineHeight: "30px",
                                                }}
                                            >
                                                <Skeleton
                                                    baseColor="#716e6e"
                                                    height={6}
                                                    width={130}
                                                    count={2}
                                                />
                                            </p>
                                        </div>
                                        <div className="actionsCont">
                                            <a
                                                style={{
                                                    textDecoration: "none",
                                                    cursor: "pointer",
                                                    fontSize: 12,
                                                }}
                                                className="accptbtn"
                                            >
                                                Accept
                                            </a>
                                            <a
                                                style={{
                                                    textDecoration: "none",
                                                    cursor: "pointer",
                                                    fontSize: 12,
                                                }}
                                                className="declinebtn"
                                            >
                                                Decline
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div
                                    className="scrollCont"
                                    style={{ borderRadius: 7, height: "37vh" }}
                                >
                                    {event_requests &&
                                        event_requests.length > 0 &&
                                        event_requests.map((item) => {
                                            return (
                                                <div
                                                    key={item.id}
                                                    className="d-flex flex-column"
                                                    style={{
                                                        paddingInline: 15,
                                                        // backgroundColor:
                                                        //     "#202020",
                                                        backgroundColor:
                                                            darkMode
                                                                ? "#202020"
                                                                : "#fff",

                                                        marginBottom: 10,
                                                        borderRadius: 7,
                                                        paddingBlock: 10,
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                        navigate("/team")
                                                    }
                                                >
                                                    <div className="connectconetCon">
                                                        <p
                                                            style={{
                                                                fontSize: 18,
                                                                fontWeight:
                                                                    "500",
                                                                marginBottom: 0,
                                                                lineHeight:
                                                                    "30px",
                                                            }}
                                                        >
                                                            {item.event_name}
                                                        </p>
                                                        <p
                                                            style={{
                                                                fontSize: 12,
                                                                margin: 0,
                                                            }}
                                                        >
                                                            Position:{" "}
                                                            {
                                                                item?.position_name
                                                            }
                                                        </p>
                                                        <p
                                                            style={{
                                                                fontSize: 12,
                                                                margin: 0,
                                                                lineHeight:
                                                                    "30px",
                                                            }}
                                                        >
                                                            {moment(
                                                                item?.event_date
                                                            ).format(
                                                                "DD-MM-YYYY"
                                                            )}{" "}
                                                            | {item.event_time}
                                                        </p>
                                                    </div>
                                                    <div className="actionsCont">
                                                        <a
                                                            onClick={(e) => {
                                                                handlePendingInvites(
                                                                    item?.invitation_id,
                                                                    1,
                                                                    item?.id
                                                                );
                                                                e.stopPropagation();
                                                            }}
                                                            style={{
                                                                textDecoration:
                                                                    "none",
                                                                cursor: "pointer",
                                                                fontSize: 12,
                                                            }}
                                                            className="accptbtn"
                                                        >
                                                            {eventLoader.id ==
                                                                item?.invitation_id &&
                                                            eventLoader.type ==
                                                                1 ? (
                                                                <Circles
                                                                    color="#fff"
                                                                    height={
                                                                        "20"
                                                                    }
                                                                    width={"20"}
                                                                    ariaLabel="circles-loading"
                                                                    wrapperClass=""
                                                                    visible={
                                                                        true
                                                                    }
                                                                />
                                                            ) : (
                                                                "Accept"
                                                            )}
                                                        </a>
                                                        <a
                                                            onClick={(e) => {
                                                                handlePendingInvites(
                                                                    item?.invitation_id,
                                                                    2,
                                                                    item?.id
                                                                );
                                                                e.stopPropagation();
                                                            }}
                                                            style={{
                                                                textDecoration:
                                                                    "none",
                                                                cursor: "pointer",
                                                                fontSize: 12,
                                                            }}
                                                            className="declinebtn"
                                                        >
                                                            {eventLoader.id ==
                                                                item?.invitation_id &&
                                                            eventLoader.type ==
                                                                2 ? (
                                                                <Circles
                                                                    color="#fff"
                                                                    height={
                                                                        "20"
                                                                    }
                                                                    width={"20"}
                                                                    ariaLabel="circles-loading"
                                                                    wrapperClass=""
                                                                    visible={
                                                                        true
                                                                    }
                                                                />
                                                            ) : (
                                                                "Decline"
                                                            )}
                                                        </a>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            )}
                        </div>
                    </div>
                    {/* Confirmation Modal */}
                    <DonationConfirmationModal
                        donate={donateNow}
                        loader={paymentLoader}
                        onHide={setConfirmModal}
                        show={confirmModal}
                    />
                </div>
            </div>
            {/* </section> */}
        </>
    );
};

export default TimeLine;
