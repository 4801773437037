import React, { useState, useEffect } from "react";
import "../../home/styles.css";
import Assets from "../../../assets/images";
import { useDispatch, useSelector } from "react-redux";
import { removeStartingSlash } from "../../../utills/RemoveSlash";
import { useNavigate } from "react-router-dom";

const TeamMemberWidget = ({
  itemStyles,
  height,
  width,
  widgetId,
  memberData,
}) => {
  const [border, setBorder] = useState("");
  const [defaultCorners, setDefaultCorners] = useState("true");
  const navigate = useNavigate();
  const user = useSelector((state) => state.login.userData);

  const universalStyles = useSelector(
    (state) => state.bodyStyling.universalStyles
  );

  useEffect(() => {
    if (universalStyles.corners && defaultCorners == "true") {
      setBorder(universalStyles.corners);
    }
  }, [universalStyles.corners, universalStyles.font]);

  return (
    <div
      className={`team-member `}
      style={{
        borderRadius: border === "round" ? 20 : border === "sharp" ? 0 : null,
      }}
    >
      <div className="d-flex flex-column align-items-center gap-2">
        <img
          alt="Profile Image"
          src={
            memberData && memberData?.user_details?.profile_pic
              ? `${process.env.REACT_APP_BASE_URL}/${removeStartingSlash(
                  memberData.user_details.profile_pic
                )}`
              : Assets.ProfilePlaceholder
          }
          style={{
            height: 80,
            width: 80,
            borderRadius: 100,
            objectFit: "cover",
          }}
        />
        <h3
          onClick={() => {
            if (memberData.user_id == user.id) {
              navigate("/userProfile");
            } else {
              navigate("/otherUserProfile", {
                state: memberData.user_id,
              });
            }
          }}
          className="profile-name"
        >
          {memberData && memberData.user_details
            ? `${memberData.user_details.first_name} ${memberData.user_details.last_name}`
            : ""}
        </h3>
        <div className="d-flex gap-2">
          <img className="profile-icon" alt="Profile Image" src={Assets.Chat} />
          <a
            href={`mailto:${
              memberData && memberData.user_details
                ? memberData.user_details.email
                : ""
            }`}
          >
            <img
              className="profile-icon"
              alt="Profile Image"
              src={Assets.Email}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default TeamMemberWidget;
