import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-photo-view/dist/react-photo-view.css";

import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
import { store, persistor } from "./store/store";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter } from "react-router-dom";
import { DarkModeProvider } from "./context/DarkModeContext";
import AppRoutes from "./AppRoutes";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    // <React.StrictMode>
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <BrowserRouter>
                <DarkModeProvider>
                    <Toaster
                        position="top-center"
                        // toastOptions={{
                        //     style: {
                        //         maxWidth: 500,
                        //         wordBreak: "break-all",
                        //         alignmentBaseline:'baseline'
                        //     },
                        // }}
                    />
                    <AppRoutes />
                </DarkModeProvider>
            </BrowserRouter>
        </PersistGate>
    </Provider>
    // </React.StrictMode>
);
