import React, { useEffect, useState } from "react";
import { ReactionBarSelector } from "@charkour/react-reactions";
import { formatLikes } from "../../utills/FormatLikes";
import { useSelector } from "react-redux";
import XHR from "../../utills/XHR";

const ReactionBar = ({ commentData, reply, hideReply }) => {
  const [isVisible, setVisible] = useState(false);
  const [userReaction, setUserReaction] = useState("");
  const user = useSelector((state) => state.login.userData);

  const hostname='https://redefine.church'

  useEffect(() => {
    if (commentData?.user_reaction !== "") {
      setUserReaction(commentData.user_reaction);
    } else {
      setUserReaction("");
    }
  }, [commentData]);

  const reactApi = (reactionId) => {
    const options = {
      method: "POST",
      url: `${hostname}/api/v1/updatePostCommentReaction`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.api_token}`,
      },
      data: {
        user_id: user.id,
        comment_id: commentData.id,
        reaction_id: reactionId,
      },
    };
    XHR(options)
      .then((resp) => {
        if (resp.data.success) {
        }
      })
      .catch((e) => {
        console.log("ERR>", e);
      });
  };

  const reactions = { 1: "👍", 2: "❤️", 3: "🙏", 4: "🙌", 5: "🔥", 6: "💧" };

  return (
    <div
      className="postaction"
      style={{
        position: "relative",
      }}
    >
      {isVisible && (
        <ReactionBarSelector
          iconSize={30}
          onSelect={(key) => {
            setUserReaction((prev) => (prev == key ? "" : key));
            reactApi(key);
            setVisible(false);
          }}
          reactions={[
            {
              label: "like",
              node: <div>👍</div>,
              key: "1",
            },
            {
              label: "love",
              node: <div>❤️</div>,
              key: "2",
            },
            {
              label: "prayer",
              node: <div>🙏</div>,
              key: "3",
            },
            {
              label: "praise",
              node: <div>🙌</div>,
              key: "4",
            },
            {
              label: "fire",
              node: <div>🔥</div>,
              key: "5",
            },
            {
              label: "water",
              node: <div>💧</div>,
              key: "6",
            },
          ]}
          style={{
            position: "absolute",
            top: "-65px",
            backgroundColor: "#292626",
          }}
        />
      )}
      {/* Reactions */}
      <div style={{ cursor: "pointer" }} className="likereplybtn">
        <span style={{ fontSize: 12 }} onClick={() => setVisible(!isVisible)}>
          {userReaction === "" ? "Like " : reactions[userReaction]}
          {commentData.reactions.length > 0 && userReaction === ""
            ? `${commentData.reactions.length} reacted`
            : userReaction !== ""
            ? `You ${
                commentData.reactions.length === 0
                  ? "reacted"
                  : `and ${formatLikes(commentData.reactions.length)} others`
              } `
            : null}
        </span>
        {hideReply !== true && (
          <span style={{ fontSize: 12 }} onClick={reply}>
            Reply
          </span>
        )}
      </div>
    </div>
  );
};

export default ReactionBar;
