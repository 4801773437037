import React, { useState, useEffect, useRef } from "react";
import "../../home/styles.css";
import { useSelector } from "react-redux";
import useResizeObserver from "use-resize-observer";
import { getGradient } from "../../../utills/getGradient";

const JoinWidget = ({ itemStyles, height, width, widgetId }) => {
  const mainRef = useRef(null);
  const mainDimensions = useResizeObserver({ ref: mainRef });

  const joinRef = useRef(null);
  const joinDimensions = useResizeObserver({ ref: joinRef });

  useEffect(() => {
    if (joinDimensions) {
      setTextHeight(joinDimensions.height);
      setTextWidth(joinDimensions.width);
    }
  }, [joinDimensions]);

  const [isEditText, setEditText] = useState(false);
  const [image, setImage] = useState("");
  const [title, setTitle] = useState("");
  const [link, setLink] = useState("");
  const [color, setColor] = useState(
    "linear-gradient(149deg, #d5afff 0, #9d53f1 100%)"
  );
  const [font, setFont] = useState({
    color: "#ffffff",
    isBold: false,
    isItalic: false,
    isUnderlined: false,
    family: "gilroymedium",
    size: 34,
    isDefaultFont: 1,
  });
  const [textWidth, setTextWidth] = useState(0);
  const [textHeight, setTextHeight] = useState(0);
  const [border, setBorder] = useState("");
  const universalStyles = useSelector(
    (state) => state.bodyStyling.universalStyles
  );
  const [defaultCorners, setDefaultCorners] = useState("true");
  const { default_widget_colors, mainColor } = universalStyles;

  useEffect(() => {
    if (universalStyles.corners && defaultCorners == "true") {
      setBorder(universalStyles.corners);
    }
    if (universalStyles.font) {
      font.isDefaultFont == 1 &&
        setFont((prevFont) => ({
          ...prevFont,
          family: universalStyles.font,
        }));
    }
  }, [universalStyles.corners, universalStyles.font]);

  useEffect(() => {
    if (itemStyles) {
      // setColor(itemStyles.background_color);
      if (
        itemStyles.background_color !== "0" &&
        itemStyles.background_color !== null
      ) {
        try {
          const bgstyle = JSON.parse(itemStyles.background_color);
          setColor(bgstyle);
        } catch (error) {
          const bgstyle = itemStyles.background_color;
          setColor(bgstyle);
        }
      } else {
        if (itemStyles.background_image) {
          // console.log("IMG HERE>", itemStyles.background_image);
          setImage(itemStyles.background_image);
        }
      }
      // setImage(itemStyles.background_image);
      setTitle(itemStyles.title);
      if (itemStyles.title_font_style) {
        try {
          const fontstyle = JSON.parse(itemStyles.title_font_style);
          setFont({
            ...fontstyle,
            color: itemStyles.title_color,
            family: itemStyles.title_font,
            size: itemStyles.title_font_size,
          });
        } catch (error) {
          const fontstyle = itemStyles.title_font_style;
          setFont({
            ...fontstyle,
            color: itemStyles.title_color,
            family: itemStyles.title_font,
            size: itemStyles.title_font_size,
          });
        }
      }
      setDefaultCorners(itemStyles.defaultCorners);
      setLink(itemStyles.widget_link);
      setTimeout(() => {
        setTextWidth(itemStyles.text_width);
      }, 200);
      setTextHeight(itemStyles.text_height);
      setBorder(itemStyles.border);
      setDefault(itemStyles.is_default);
      setImagePosition(itemStyles.image_position);
      setImgRepeat(itemStyles.image_repeat);
    }
  }, [itemStyles]);

  const [isDefault, setDefault] = useState(0);

  const [imagePosition, setImagePosition] = useState("cover");
  const [imageRepeat, setImgRepeat] = useState("no-repeat");

  return (
    <a
      style={{ textDecoration: "none" }}
      href={itemStyles?.widget_link}
      target="_blank"
    >
      <div
        id="joinGroup"
        ref={mainRef}
        className={`joinGroup`}
        style={{
          ...((image == "0" || image == null || image == false) && {
            background: `${
              isDefault && default_widget_colors.groups
                ? default_widget_colors.groups.is_default === "0"
                  ? default_widget_colors.groups.color
                  : default_widget_colors.groups.is_default === "1"
                  ? mainColor
                  : getGradient(color)
                : getGradient(color)
            }`,
          }),
          ...(image !== "0" &&
            image !== null &&
            image !== false && {
              backgroundImage: `url(${process.env.REACT_APP_BASE_URL}${image})`,
              backgroundPosition: "center",
              backgroundRepeat: imageRepeat,
              backgroundSize: imagePosition,
            }),
          borderRadius: border === "round" ? 20 : border === "sharp" ? 0 : null,
        }}
      >
        {/* )} */}
        <div className="d-flex align-items-center justify-content-center">
          <span
            ref={joinRef}
            // class="divtext"
            role="textbox"
            data-placeholder="type here..."
            className={`${isEditText ? "editText" : ""}  `}
            // ref={refSpan}
            suppressContentEditableWarning
            style={{
              fontSize: Number(font.size),
              color: font.color ? font.color : "white",
              textDecorationLine: font.isUnderlined ? "underline" : "none",
              fontWeight: font.isBold ? "bold" : "normal",
              fontStyle: font.isItalic ? "italic" : "normal",
              // fontFamily: font.family,
              fontFamily:
                font.family === "default"
                  ? universalStyles.font
                  : font.family
                  ? font.family
                  : "sans-serif",
              width: textWidth === 0 ? "10em" : textWidth,
              resize:
                widgetId == localStorage.getItem("customId") ? "both" : "none",
              textAlign: "center",
              backgroundColor: "transparent",
              maxHeight: mainDimensions.height,
              maxWidth: mainDimensions.width,
              overflow: "hidden",
              pointerEvents:
                widgetId == localStorage.getItem("customId") ? "" : "none",
              borderColor:
                widgetId == localStorage.getItem("customId")
                  ? "#be9494"
                  : "transparent",
            }}
          >
            {title}
          </span>
        </div>
      </div>
    </a>
  );
};

export default JoinWidget;
