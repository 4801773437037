import React, { useState } from "react";
import { Info,SquareArrowOutUpRight } from "lucide-react";

const FloatingLogin = ({handleShow}) => {
  return (
    <div onClick={handleShow} className="float">
      <div className="d-flex align-items-center login-caution gap-2">
        <Info color="red" size={20} />
        <span style={{ color: "#fff" }}>LOGIN REQUIRED!</span>
      </div>
      <div className="d-flex gap-1">
        <span style={{ color: "#fff", fontSize: 12 }}>
          Please login to your account
        </span>
        <SquareArrowOutUpRight size={20} color="#fff" />
      </div>
    </div>
  );
};

export default FloatingLogin;
