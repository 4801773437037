import React, { useEffect, useState } from "react";

const ButtonComp = ({ title, onClick }) => {

  return (
    <div
      className="modal-Btn"
      onClick={onClick}
    >
      <span>{title}</span>
    </div>
  );
};

export default ButtonComp;
