import logo from "./logo.svg";
import backarrow from "./backarrow.svg";
import laptop from "./laptop.svg";
import smartphone from "./smartphone.svg";
import undoico from "./undoico.svg";
import darktheme from "./darktheme.svg";
import lighttheme from "./lighttheme.svg";
import dropdownarrow from "./dropdownarrow.svg";
import playimg from "./playimg.png";
import deletebtn from "./deletebtn.svg";
import editicon from "./editicon.svg";
import orangeImage from "./orangeImage.svg";
import image from "./image.svg";
import link from "./link.svg";
import paintbucket1 from "./paint_bucket_1.svg";
import boldbutton from "./bold-button.svg";
import italicbutton from "./italicbutton.svg";
import uploadplaceholder from "./uploadplaceholder.svg";
import Tbutton from "./Tbutton.svg";
import editText from "./editText4.png";
import dragIcon from "./move.png";
import defaultIcon from "./default.png";
import placeholder2 from "./placeholder2.png";
import sermon1 from "./sermon1.jpg";
import sermon2 from "./sermon2.jpg";
import sermon3 from "./sermon3.jpg";
import sermon4 from "./sermon4.jpg";
import sermon5 from "./sermon5.jpg";
import sermon6 from "./sermon6.png";
import DollarIcon from "../images/dollar.png";
import GiveBanner from "../images/give-banner-img.png";

import ProfilePlaceholder from "../images/placeholderimage.png";
import Email from "../images/email.svg";
import Chat from "../images/chat.svg";
import Calender from "../images/calender.png";
import Event1 from "../images/event-1.png";
import Speaker from "../images/speaker.png";
import Pdf from "../images/pdf.png";
import PdfBlu from "../images/pdf-blue.png";

import Serve from "../images/serve-img.png";
import Circle from "../images/circle.png";
import TriCircle from "../images/tri-circle.png";

import AboutBanner from "../images/about-bg.png";
import OurBeliefs from "../images/our-beliefs.png";
import OurCode from "../images/our-code.png";
import Leadership from "../images/our-leadership.png";

import Leader1 from "../images/leader-1.png";
import Leader2 from "../images/leader-2.png";
import Leader3 from "../images/leader-3.png";
import Leader4 from "../images/leader-4.png";
import Leader5 from "../images/leader-5.png";
import Leader6 from "../images/leader-6.png";

//
const Assets = {
  Leader1,
  Leader2,
  Leader3,
  Leader4,
  Leader5,
  Leader6,
  AboutBanner,
  OurBeliefs,
  OurCode,
  Leadership,
  TriCircle,
  Circle,
  Serve,
  PdfBlu,
  Pdf,
  Speaker,
  Event1,
  Calender,
  Chat,
  Email,
  ProfilePlaceholder,
  DollarIcon,
  GiveBanner,
  sermon1,
  sermon2,
  sermon3,
  sermon4,
  sermon5,
  sermon6,
  placeholder2,
  logo,
  backarrow,
  laptop,
  smartphone,
  undoico,
  darktheme,
  lighttheme,
  dropdownarrow,
  playimg,
  editicon,
  deletebtn,
  orangeImage,
  image,
  link,
  paintbucket1,
  boldbutton,
  uploadplaceholder,
  italicbutton,
  Tbutton,
  editText,
  dragIcon,
  defaultIcon,
};
export default Assets;
