import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { DarkModeContext } from "../context/DarkModeContext";
import Header from "../components/header";
import Loader from "../components/Loader";
import Footer from "../components/footer";
import LogoutHeader from "../components/header/LogoutHeader";

const PublicRoute = ({ component: Component }) => {
  const { darkMode, handleLightMode, handleDarkMode, borderCorners } =
    useContext(DarkModeContext);
  const isLoading = useSelector((state) => state.bodyStyling.submitLoader);
  const user = useSelector((state) => state.login.userData);
  const { pathname } = useLocation();

  return (
    <div>
      {!user?.api_token ? (
        <LogoutHeader darkMode={darkMode} />
      ) : (
        <Header darkMode={darkMode} />
      )}
      <div
        className={`mainWrapper ${darkMode ? "darkMode" : "lightMode"} `}
        style={{
          flexDirection: "column",
          alignItems: "center",
          justifyContent:
            pathname === "/about-us" ||
            pathname === "/our-beliefs" ||
            pathname === "/our-leadership"
              ? "flex-start"
              : "center",
        }}
      >
        <Loader loading={isLoading} />
        {pathname === "/serve" && (
          <div className="d-flex align-items-end justify-content-center give-banner serve-banner">
            <h1 style={{ color: "#fff" }}>Redefine Serve</h1>
          </div>
        )}
        {/* {pathname === "/kids" && (
          <div className="d-flex align-items-end justify-content-center give-banner kids-banner">
            <h1 style={{ color: "#fff" }}>Redefine Kidz</h1>
          </div>
        )}
        {pathname === "/group" && (
          <div className="d-flex align-items-end justify-content-center give-banner group-banner">
            <h1 style={{ color: "#fff" }}>Redefine Groups</h1>
          </div>
        )} */}
        {pathname === "/about-us" && (
          <div className="d-flex align-items-end justify-content-center give-banner about-banner">
            <h1 style={{ color: "#fff" }}>Why Redefine</h1>
          </div>
        )}
        <Component darkMode={darkMode} />
      </div>
      <Footer darkMode={darkMode} />
    </div>
  );
};

export default PublicRoute;
