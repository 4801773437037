import React, { useState } from "react";
import Assets from "../../assets/images";

const OurLeaderShip = () => {
  const data = [
    {
      id: 1,
      img: Assets.Leader1,
      name: "Pastor Jordan and Tayler Hardison",
      position: "",
      description:
        "Pastor Jordan Hardison is a compassionate pastor who’s unwavering mission is to see broken people restored through the transformative power of Jesus Christ. Meanwhile, Tayler, his wife, brings her own unique background as a Pastor's kid and early involvement in ministry. Her experiences have shaped her into a devoted mother, an ardent follower of Christ, and a woman with a strong desire to serve the women of Redefine Church. Together, Pastor Jordan and Tayler form a dynamic team, committed to leading their congregation with love, understanding, and a shared dedication to healing and restoration.",
    },
    {
      id: 2,
      img: Assets.Leader2,
      name: "Pastor Uttam Ghorai",
      position: "Redefine India Campus Pastor",
      description:
        "Pastor Uttam Ghorai is from the small village of Chakmakrampur in Kharagpur India. Growing up in extreme poverty, Pastor Uttam's life was changed when a family sponsored him through Compassion International. The youngest in his family, Pastor Uttam passionately studied throughout his childhood, ultimately achieving a bachelor's degree in theology and a second bachelor's degree in divinity. Pastor Uttam is currently establishing our Redefine Church campus in Kolkata, India.",
    },
    {
      id: 3,
      img: Assets.Leader4,
      name: "Nicole Waddell",
      position: "Worship Director",
      description:
        "Nichole Waddell is a devoted mother, wife, and passionate Worship Director at Redefine Church. With a background in graphic design, production, and worship, she brings a unique skill set to her role. Her personal journey of faith fuels her desire to create transformative worship experiences, blending visuals and heartfelt music. Nichole's authenticity, vulnerability, and love for God and people make her an invaluable asset to the Redefine Church community.",
    },
    {
      id: 4,
      img: Assets.Leader3,
      name: "Julianna Turner",
      position: "Creative Arts Director",
      description:
        "Julianna Turner is the Creative Arts Director at Redefine Church. With a background in worship and creative arts, Julianna brings her passion and expertise to create meaningful and transformative worship experiences. Currently pursuing a degree in psychology, she combines her love for worship with a deep understanding of human emotions and connections. Julianna's genuine love for people shines through in her role as she seeks to create an atmosphere where individuals can encounter God in a profound way.",
    },
    {
      id: 5,
      img: Assets.Leader5,
      name: "Aaron Turner",
      position: "Redefine YTH Director",
      description:
        "Aaron Turner is a dedicated small business owner, an avid lover of the outdoors, and a passionate leader for the next generation of Christ followers. Serving as the YTH Director at Redefine Church, Aaron brings his enthusiasm and commitment to empowering young adults in their faith journeys. His love for adventure and connecting with others creates an engaging and dynamic environment where young adults can grow and thrive in their relationship with God.",
    },
    {
      id: 6,
      img: Assets.Leader6,
      name: "Rui Pereira",
      position: "Chief Financial Officer",
      description:
        "Rui Pereira, a devoted father and husband, is a business owner and enthusiastic about soccer and fitness. As the Chief Financial Officer (CFO) of Redefine Church, he utilizes his expertise in finance to ensure its financial success. Rui's family holds a special place in the church as foundational members, further strengthening their commitment to its mission and vision.",
    },
  ];

  const [showId, setShowId] = useState("");

  return (
    <div className="our-leadership ">
      <h1 style={{fontSize:34}} >Our Leadership</h1>
      <div className="grid-images">
        {data.map((item) => {
          return (
            <div
              key={item.id}
              className="leadership-img"
              style={{ backgroundImage: `url(${item.img})` }}
              onMouseEnter={() =>
                setTimeout(() => {
                  setShowId(item.id);
                }, 150)
              }
              onMouseLeave={() => setShowId("")}
            >
              {showId !== item.id ? (
                <h2>
                  {item.name}
                  <br />
                  <span style={{ fontSize: 14 }}>{item.position} </span>
                </h2>
              ) : (
                <p className="">{item.description}</p>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OurLeaderShip;
