import React, { useRef, useEffect, useState, useContext } from "react";
import "../../home/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { Player, ControlBar, BigPlayButton } from "video-react";
import "video-react/dist/video-react.css";
import HLSSource from "./HLSSource";
import liveGif from "../../../assets/live.gif";
import { DarkModeContext } from "../../../context/DarkModeContext";

const VideoWidget = ({ widgetId }) => {
  const dispatch = useDispatch();
  const playerRef = useRef(null);
  const { darkMode } = useContext(DarkModeContext);
  const data = useSelector((state) => state.bodyStyling.styling);
  const universalStyles = useSelector(
    (state) => state.bodyStyling.universalStyles
  );
  // const {liveVideo,latestVideo,liveVideoStatus}=data
  // console.log("LIVE>", data);

  const [streamId, setStreamId] = useState(null);
  const [error, setError] = useState(false);
  const [border, setBorder] = useState("");

  useEffect(() => {
    if (universalStyles.corners) {
      setBorder(universalStyles.corners);
    }

    const handleVisibilityChange = () => {
      const player = playerRef.current;
      if (!player) return;

      if (document.hidden) {
        player.pause();
      } else {
        const promise = player.play();
        if (promise !== undefined) {
          promise.catch((error) => {
            console.log("Play was prevented: ", error);
          });
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (data?.liveVideo) {
      setStreamId(data?.liveVideo?.stream_session_id);
    } else if (data?.latestVideo) {
      setStreamId(data?.latestVideo?.stream_session_id);
    }
  }, [data]);

  // "https://live.redefine.church/live/xQzqNoSwek/index.m3u8"
  return (
    <div className="d-flex col-md-12 align-items-center justify-content-center">
      <Player ref={playerRef} autoPlay muted>
        <HLSSource
          isVideoChild
          src={`https://live.redefine.church/live/${streamId}/index.m3u8`}
          // src={
          //   "https://demo.unified-streaming.com/k8s/features/stable/video/tears-of-steel/tears-of-steel.ism/.m3u8"
          // }
          setError={setError}
          clearError={() => setError(null)}
        />
        {data?.liveVideo && <img className="live-gif" src={liveGif} />}
        {error && (
          <span
            style={{
              color: darkMode ? "#fff" : "#000",
              position: "absolute",
              top: 10,
              fontSize: 14,
            }}
          >
            {error}
          </span>
        )}
        <ControlBar
          className={border === "round" ? "bottom-border" : ""}
          autoHide={true}
        />
        <BigPlayButton position="center" />
      </Player>
    </div>
  );
};
export default VideoWidget;
