import React, { useState, useEffect, useRef } from "react";
import GridLayout from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { useDispatch, useSelector } from "react-redux";
//Components
import ConnectWidget from "../../pages/Widgets/connectWidget";
import GivingWidget from "../../pages/Widgets/givingWidget";
import GroupWidget from "../../pages/Widgets/groupWidget";
import QuoteWidget from "../../pages/Widgets/quoteWidget";
import GroupWidget2 from "../../pages/Widgets/groupWidget2";
import JoinWidget from "../../pages/Widgets/joinWidget";
import VideoWidget from "../../pages/Widgets/videoWidget";
import "./styles.css";
import "../../pages/home/styles.css";
import EventListWidget from "../../pages/Widgets/eventListWidget";
import CustomWidget from "../../pages/Widgets/customWidget";
import ColumnWidget from "../../pages/Widgets/columnWidget";
import RowWidget from "../../pages/Widgets/rowWidget";

const SortableGrid = ({ widgetsOrder }) => {
    const dispatch = useDispatch();
    const styles = useSelector((state) => state.bodyStyling.universalStyles);

    const [widgets, setWidgets] = useState([]);
    useEffect(() => {
        if (widgetsOrder) {
            setWidgets(widgetsOrder);
        }
    }, [widgetsOrder]);

    const [screenSize, setScreenSize] = useState({
        width: window.innerWidth,
    });
    useEffect(() => {
        const handleResize = () => {
            setScreenSize({
                width: window.innerWidth,
            });
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const [playerHeight, setPlayerHeight] = useState(
        6
        // window.innerWidth > 2048 && window.innerWidth <= 2304 ? 8 : 7
    );

    const handleResize = () => {
        if (window.innerWidth < 1940 && window.innerWidth > 1740) {
            setPlayerHeight(6);
        } else if (window.innerWidth >= 1536 && window.innerWidth < 1707) {
            setPlayerHeight(6);
        } else if (window.innerWidth == 1707) {
            setPlayerHeight(6);
        } else if (window.innerWidth > 2048 && window.innerWidth <= 2304) {
            setPlayerHeight(5.5);
        } else if (window.innerWidth == 3584) {
            setPlayerHeight(3);
        } else if (window.innerWidth > 2304 && window.innerWidth <= 3702) {
            setPlayerHeight(4.5);
        } else if (window.innerWidth == 2048) {
            setPlayerHeight(5.5);
        } else if (window.innerWidth <= 1490 && window.innerWidth > 1291) {
            setPlayerHeight(6);
        } else if (window.innerWidth <= 1290 && window.innerWidth > 981) {
            setPlayerHeight(5);
        } else if (window.innerWidth <= 990 && window.innerWidth > 891) {
            setPlayerHeight(4);
        } else if (window.innerWidth <= 750 && window.innerWidth > 540) {
            setPlayerHeight(3);
        } else if (window.innerWidth <= 540 && window.innerWidth > 350) {
            setPlayerHeight(2.5);
        } else if (window.innerWidth <= 350) {
            setPlayerHeight(2);
        }
    };

    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    // const getRowHeight = () => {
    //   if (window.innerWidth > 1536 && window.innerWidth <= 1707) {
    //     return 110;
    //   } else if (window.innerWidth > 1707 && window.innerWidth <= 1920) {
    //     return 120;
    //   } else if (window.innerWidth > 1920 && window.innerWidth <= 2048) {
    //     return 115;
    //   } else if (window.innerWidth > 2048 && window.innerWidth <= 2304) {
    //     return 120;
    //   } else if (window.innerWidth > 2304 && window.innerWidth <= 3072) {
    //     return 150;
    //   } else if (window.innerWidth > 3072 && window.innerWidth <= 4608) {
    //     return 250;
    //   }else if (window.innerWidth > 4608) {
    //     return 250;
    //   }
    //   return 100;
    // };

    const getRowHeight = () => {
        if (window.innerWidth > 1536 && window.innerWidth <= 1707) {
            return 110;
        } else if (window.innerWidth > 1707 && window.innerWidth <= 1920) {
            return 120;
        } else if (window.innerWidth > 1920 && window.innerWidth <= 2048) {
            return 115;
        } else if (window.innerWidth > 2048 && window.innerWidth <= 2304) {
            return 120;
        } else if (window.innerWidth > 2304 && window.innerWidth <= 2389) {
            return 110;
        } else if (window.innerWidth > 2389 && window.innerWidth <= 2688) {
            return 120;
        } else if (window.innerWidth > 2688 && window.innerWidth <= 3072) {
            return 150;
        } else if (window.innerWidth > 3072 && window.innerWidth <= 3584) {
            return 150;
        } else if (window.innerWidth > 3584 && window.innerWidth <= 4608) {
            return 150;
        }
        // For moile devices less than 450px
        else if (window.innerWidth <= 450) {
            return 60;
        }
        return 100;
    };

    // console.log("WIDTH>", screenSize.width);
    const gridElement = document.querySelector(".layout");

    return (
        <GridLayout
            className="layout"
            // cols={2}
            cols={screenSize.width < 700 ? 1 : 2}
            // rowHeight={100}
            rowHeight={getRowHeight()}
            // width={screenSize.width - screenSize.width * 0.15}
            // width={screenSize.width - screenSize.width * 0.140}
            // width={screenSize.width - screenSize.width * 0.20}
            // width={
            //     gridElement
            //         ? gridElement.clientWidth - 20
            //         : screenSize.width - screenSize.width * 0.15
            // }
            width={
                gridElement
                    ? gridElement.clientWidth
                    : screenSize.width - screenSize.width * 0.15
            }
            autoSize
            isBounded
            isResizable={false}
            allowOverlap={false}
            margin={styles && styles.corners === "sharp" ? [0, 0] : [20, 20]}
            containerPadding={[0, 0]}
            isDraggable={false}
        >
            {widgets &&
                widgets.map((item) => {
                    return (
                        <div
                            style={{
                                // zIndex:item.widgetInfo.name === "giving"? 1:'',
                                position: "relative",
                                width: "100%",
                                // ...(item.widgetInfo.name === "player" && {
                                //   overflow: "hidden",
                                // }),
                                ...(item.widgetInfo.name === "player" && {
                                    borderRadius:
                                        styles.corners === "sharp" ? 0 : 20,
                                }),
                            }}
                            key={item.widget_id}
                            data-grid={{
                                x: item.x,
                                y: item.y,
                                w: screenSize.width < 700 ? 1 : item.w,
                                h:
                                    item.widgetInfo.name === "player"
                                        ? playerHeight
                                        : // : item.h == 3 &&
                                          //   screenSize.width >= 1792 &&
                                          //   screenSize.width < 2389
                                          // ? 2.8
                                          // : item.h == 6 &&
                                          //   screenSize.width >= 1792 &&
                                          //   screenSize.width < 2389
                                          // ? 5.6
                                          // : item.h == 3 &&
                                          //   screenSize.width >= 2389 &&
                                          //   screenSize.width < 3584
                                          // ? 2.3
                                          // : item.h == 6 &&
                                          //   screenSize.width >= 2389 &&
                                          //   screenSize.width < 3584
                                          // ? 4.6
                                          // : item.h == 3 && screenSize.width >= 3584
                                          // ? 1.8
                                          // : item.h == 6 && screenSize.width >= 3584
                                          // ? 3.6
                                          item.h,
                            }}
                        >
                            {item.widgetInfo.name === "player" ? (
                                <VideoWidget
                                    widgetId={Number(item.widget_id)}
                                />
                            ) : // item.widget_id == "2"
                            item.widgetInfo.name === "connect" ? (
                                <ConnectWidget
                                    widgetId={Number(item.widget_id)}
                                    height={item.h}
                                    width={item.w}
                                    itemStyles={item.widget_styles[0]}
                                    x={item.x}
                                />
                            ) : // item.widget_id == "3"
                            item.widgetInfo.name === "giving" ? (
                                <GivingWidget
                                    widgetId={Number(item.widget_id)}
                                    height={item.h}
                                    width={item.w}
                                    itemStyles={item.widget_styles[0]}
                                    x={item.x}
                                />
                            ) : // item.widget_id == "6"
                            item.widgetInfo.name === "ourcode" ? (
                                <QuoteWidget
                                    widgetId={Number(item.widget_id)}
                                    height={item.h}
                                    width={item.w}
                                    itemStyles={item.widget_styles[0]}
                                    x={item.x}
                                />
                            ) : // item.widget_id == "4"
                            item.widgetInfo.name === "map" ? (
                                <GroupWidget
                                    widgetId={Number(item.widget_id)}
                                    height={item.h}
                                    width={item.w}
                                    itemStyles={item.widget_styles[0]}
                                    x={item.x}
                                />
                            ) : // item.widget_id == "5"
                            item.widgetInfo.name === "serve" ? (
                                <GroupWidget2
                                    widgetId={Number(item.widget_id)}
                                    height={item.h}
                                    width={item.w}
                                    itemStyles={item.widget_styles[0]}
                                    x={item.x}
                                />
                            ) : // item.widget_id == "7"
                            item.widgetInfo.name === "groups" ? (
                                <JoinWidget
                                    widgetId={Number(item.widget_id)}
                                    height={item.h}
                                    width={item.w}
                                    itemStyles={item.widget_styles[0]}
                                    x={item.x}
                                />
                            ) : // item.widget_id == "8"
                            item.widgetInfo.name === "eventList" ? (
                                <EventListWidget
                                    widgetId={Number(item.widget_id)}
                                    itemStyles={item.widget_styles[0]}
                                    height={item.h}
                                    width={item.w}
                                    x={item.x}
                                    eventData={item?.event || {}}
                                />
                            ) : item.widgetInfo.name === "column" ? (
                                <ColumnWidget
                                    widgetId={Number(item.widget_id)}
                                    itemStyles={item.widget_styles[0]}
                                    height={item.h}
                                    width={item.w}
                                    x={item.x}
                                />
                            ) : item.widgetInfo.name === "row" ? (
                                <RowWidget
                                    widgetId={Number(item.widget_id)}
                                    itemStyles={item.widget_styles[0]}
                                    height={item.h}
                                    width={item.w}
                                    x={item.x}
                                />
                            ) : (
                                <CustomWidget
                                    widgetId={Number(item.widget_id)}
                                    itemStyles={item.widget_styles[0]}
                                    height={item.h}
                                    width={item.w}
                                    x={item.x}
                                />
                            )}
                        </div>
                    );
                })}
        </GridLayout>
    );
};

export default SortableGrid;
