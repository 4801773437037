import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import useResizeObserver from "use-resize-observer";
//Assets
import blackBg from "../../../assets/images/blackBg.jpg";
import "../../home/styles.css";
//Actions
import { getGradient } from "../../../utills/getGradient";

const QuoteWidget = ({ itemStyles, widgetId }) => {
  const [color, setColor] = useState("");
  const [image, setImage] = useState("");
  const [initialImage, setInitialImage] = useState(blackBg);
  const [font, setFont] = useState({
    color: "#ffffff",
    isBold: false,
    isItalic: false,
    isUnderlined: false,
    family: "frontage",
    size: 34,
    isDefaultFont: 1,
  });
  const [title, setTitle] = useState("");
  const spanRef = useRef(null);
  const [link, setLink] = useState("");
  const [textWidth, setTextWidth] = useState(0);
  const [textHeight, setTextHeight] = useState(0);
  const [border, setBorder] = useState("");
  const [defaultCorners, setDefaultCorners] = useState("true");

  const universalStyles = useSelector(
    (state) => state.bodyStyling.universalStyles
  );

  const { default_widget_colors, mainColor } = universalStyles;

  useEffect(() => {
    if (universalStyles.corners && defaultCorners == "true") {
      setBorder(universalStyles.corners);
    }
    if (universalStyles.font) {
      font.isDefaultFont == 1 &&
        setFont((prevFont) => ({
          ...prevFont,
          family: universalStyles.font,
        }));
    }
  }, [universalStyles.corners, universalStyles.font]);

  useEffect(() => {
    if (itemStyles) {
      // setColor(itemStyles.background_color);
      if (
        itemStyles.background_color !== "0" &&
        itemStyles.background_color !== null
      ) {
        try {
          const bgstyle = JSON.parse(itemStyles.background_color);
          setColor(bgstyle);
        } catch (error) {
          const bgstyle = itemStyles.background_color;
          setColor(bgstyle);
        }
      } else {
        if (itemStyles.background_image) {
          // console.log("IMG HERE>", itemStyles.background_image);
          setImage(itemStyles.background_image);
        }
      }
      // setImage(itemStyles.background_image);
      setTitle(itemStyles.title);
      if (itemStyles.title_font_style) {
        try {
          const fontstyle = JSON.parse(itemStyles.title_font_style);
          setFont({
            ...fontstyle,
            color: itemStyles.title_color,
            family: itemStyles.title_font,
            size: itemStyles.title_font_size,
          });
        } catch (error) {
          const fontstyle = itemStyles.title_font_style;
          setFont({
            ...fontstyle,
            color: itemStyles.title_color,
            family: itemStyles.title_font,
            size: itemStyles.title_font_size,
          });
        }
      }
      setBorder(itemStyles.border);
      setLink(itemStyles.widget_link);
      setTextHeight(itemStyles.text_height);
      setTextWidth(itemStyles.text_width);
      setDefaultCorners(itemStyles.defaultCorners);
      setDefault(itemStyles.is_default);
      setImagePosition(itemStyles.image_position);
      setImgRepeat(itemStyles.image_repeat);
    }
  }, [itemStyles]);

  const [isDefault, setDefault] = useState(0);

  const [imagePosition, setImagePosition] = useState("cover");
  const [imageRepeat, setImgRepeat] = useState("no-repeat");

  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      const span = spanRef.current;
      if (span) {
        const width = span.offsetWidth;
        const height = span.offsetHeight;
        setTextHeight(height);
        setTextWidth(width);
        // Utilize width and height for further actions
      }
    });
    observer.observe(spanRef.current, { attributes: true, childList: true });
    return () => observer.disconnect();
  }, []);

  const mainRef = useRef(null);
  const mainDimensions = useResizeObserver({ ref: mainRef });

  return (
    <a
      style={{ textDecoration: "none" }}
      href={itemStyles?.widget_link}
      target="_blank"
    >
      <div
        id="imgBg"
        ref={mainRef}
        className={`imgBg col-md-12`}
        style={{
          ...((image == "0" || image == null || image == false) && {
            background: `${
              isDefault && default_widget_colors.ourcode
                ? default_widget_colors.ourcode.is_default === "0"
                  ? default_widget_colors.ourcode.color
                  : default_widget_colors.ourcode.is_default === "1"
                  ? mainColor
                  : getGradient(color)
                : getGradient(color)
            }`,
          }),
          ...(((image !== "0" && image !== null && image !== false) ||
            !initialImage) && {
            backgroundImage: image
              ? `url(${process.env.REACT_APP_BASE_URL}${image})`
              : `url(${initialImage})`,
            backgroundPosition: "center",
            backgroundRepeat: imageRepeat,
            backgroundSize: imagePosition,
          }),
          borderRadius: border === "round" ? 20 : border === "sharp" ? 0 : null,
        }}
      >
        <div
          className="quoteText d-flex flex-column align-items-center justify-content-center"
          // style={{ maxHeight: getSize("height") }}
        >
          <span
            id="editableSpan"
            // class="divtext"
            role="textbox"
            data-placeholder="type here..."
            suppressContentEditableWarning
            ref={spanRef}
            style={{
              fontSize: Number(font.size),
              color: font.color ? font.color : "white",
              textDecorationLine: font.isUnderlined ? "underline" : "none",
              fontWeight: font.isBold ? "bold" : "normal",
              fontStyle: font.isItalic ? "italic" : "normal",
              // fontFamily: font.family,
              fontFamily:
                font.family === "default"
                  ? universalStyles.font
                  : font.family
                  ? font.family
                  : "sans-serif",
              resize:
                widgetId == localStorage.getItem("customId") ? "both" : "none",
              textAlign: "center",
              width: textWidth == 0 ? 100 : textWidth,
              height: textHeight == 0 ? 50 : textHeight,
              backgroundColor: "transparent",
              maxHeight: mainDimensions.height - 40,
              maxWidth: mainDimensions.width - 20,
              // maxHeight: getSize("height"),
              // maxWidth: getSize("width"),
              overflow: "hidden",
              pointerEvents:
                widgetId == localStorage.getItem("customId") ? "" : "none",
              borderColor:
                widgetId == localStorage.getItem("customId")
                  ? "#be9494"
                  : "transparent",
            }}
          >
            {title}
          </span>
        </div>
      </div>
    </a>
  );
};

export default QuoteWidget;
