import React from "react";
import "./styles.css"; // Import the CSS file
import { removeStartingSlash } from "../../utills/RemoveSlash";

const LiveProfile = ({ item,placeholder ,hostname}) => {
    return (
        <div className="live-profile-container">
            <div className="profile-border">
                <img
                    src={
                        item?.profile_pic && item.profile_pic !== null
                            ? `${hostname}/${removeStartingSlash(item.profile_pic)}`
                            : placeholder
                    }
                    alt="Profile"
                    className="profile-image"
                />
            </div>
            <div className="live-tag">LIVE</div>
        </div>
    );
};

export default LiveProfile;
