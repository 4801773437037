import React, { useCallback, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import "../../pages/home/styles.css";
import ButtonComp from "../Button";
import ModalInput from "../InputField";
import { ChevronLeft } from "lucide-react";
import { Circles } from "react-loader-spinner";
import { useSelector } from "react-redux";

const PasswordHintModal = ({ isModalVisible, onHide }) => {
  return (
    <Modal
      size="lg"
      backdrop="static"
    //   keyboard={false}
      show={isModalVisible}
      onHide={() => onHide(false)}
    >
      <Modal.Header style={{ backgroundColor: "#fff" }} closeButton>
        <Modal.Title style={{ fontWeight: "600" }}>Password Hint</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: "#fff" }}>
        <ul>
          <li style={{ fontWeight: "600" }}>
            Must contain at least 6 characters
          </li>
          <li style={{ fontWeight: "bold" }}>
            Must contain at least one uppercase letter
          </li>
          <li style={{ fontWeight: "bold" }}>
            Must contain at least one lowercase letter
          </li>
          <li style={{ fontWeight: "bold" }}>
            Must contain at least one number
          </li>
          <li style={{ fontWeight: "bold" }}>
            Must contain at least one special character (!#$%&@~)
          </li>
        </ul>
      </Modal.Body>
      <Modal.Footer style={{ backgroundColor: "#fff" }}>
        <Button variant="danger" onClick={() => onHide(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PasswordHintModal;
