import React, { useState, useEffect, useRef } from "react";
import "../../home/styles.css";
import Assets from "../../../assets/images";
import { useDispatch, useSelector } from "react-redux";
import { Play, X } from "lucide-react";
import { Modal } from "react-bootstrap";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { removeStartingSlash } from "../../../utills/RemoveSlash";
import toast from "react-hot-toast";

const SongsWidget = ({ itemStyles, height, width, widgetId }) => {
  const [border, setBorder] = useState("");
  const [defaultCorners, setDefaultCorners] = useState("true");
  const [openSongIds, setOpenSongIds] = useState([]);
  const songsLibrary = useSelector((state) => state.bodyStyling.songsLibrary);

  const [modalOpenSongIds, setModalOpenSongIds] = useState([]);

  const songRefs = useRef({});
  const modalSongRefs = useRef({});

  const universalStyles = useSelector(
    (state) => state.bodyStyling.universalStyles
  );
  const audioRef = useRef(null);
  const [songsModal, showSongsModal] = useState(false);

  const [playingSongId, setPlayingSongId] = useState(null);

  const handlePlay = (id) => {
    try {
      setPlayingSongId(id === playingSongId ? null : id);
      // setTimeout(() => {
      //   if (
      //     audioRef.current &&
      //     audioRef.current.audio &&
      //     audioRef.current.audio.current
      //   ) {
      //     const audioElement = audioRef.current.audio.current;

      //     // Add a canplaythrough event listener to check if the file is playable
      //     audioElement.addEventListener(
      //       "canplaythrough",
      //       () => {
      //         audioElement.play(); // Play the audio only if it's playable
      //       },
      //       { once: true }
      //     );

      //     // Optional: Add an error event listener to handle cases where the file is not playable
      //     audioElement.addEventListener(
      //       "error",
      //       (e) => {
      //         console.error("Audio load/play error:", e);
      //       },
      //       { once: true }
      //     );

      //     // Load the audio source to trigger loading events
      //     audioElement.load();
      //   } else {
      //     console.warn("Audio element not found.");
      //   }
      // }, 200);
    } catch (error) {
      console.log("ERR>", error);
    }
  };

  useEffect(() => {
    if (universalStyles.corners && defaultCorners == "true") {
      setBorder(universalStyles.corners);
    }
  }, [universalStyles.corners, universalStyles.font]);

  const toggleDropdown = (id) => {
    const isOpen = openSongIds.includes(id);

    if (isOpen) {
      // Collapse the content smoothly
      const element = songRefs.current[id];
      element.style.maxHeight = `${element.scrollHeight}px`; // Set to current height to trigger transition
      requestAnimationFrame(() => {
        element.style.maxHeight = "0";
        element.style.opacity = "0";
        element.style.paddingTop = "0";
      });

      // Remove from openSongIds after the transition ends
      setTimeout(() => {
        setOpenSongIds((prevOpenSongIds) =>
          prevOpenSongIds.filter((songId) => songId !== id)
        );
      }, 300); // Match the duration of your CSS transition
    } else {
      // Expand the content smoothly
      setOpenSongIds((prevOpenSongIds) => [...prevOpenSongIds, id]);
      const element = songRefs.current[id];
      element.style.maxHeight = `${element.scrollHeight + 10}px`;
      element.style.opacity = "1";
      element.style.paddingTop = "10px";
    }
  };

  const toggleModalDropdown = (id) => {
    const isOpen = modalOpenSongIds.includes(id);

    if (isOpen) {
      // Collapse the content smoothly
      const element = modalSongRefs.current[id];
      element.style.maxHeight = `${element.scrollHeight}px`; // Set to current height to trigger transition
      requestAnimationFrame(() => {
        element.style.maxHeight = "0";
        element.style.opacity = "0";
        element.style.paddingTop = "0";
      });

      // Remove from openSongIds after the transition ends
      setTimeout(() => {
        setModalOpenSongIds((prevOpenSongIds) =>
          prevOpenSongIds.filter((songId) => songId !== id)
        );
      }, 300); // Match the duration of your CSS transition
    } else {
      // Expand the content smoothly
      setModalOpenSongIds((prevOpenSongIds) => [...prevOpenSongIds, id]);
      const element = modalSongRefs.current[id];
      element.style.maxHeight = `${element.scrollHeight + 10}px`;
      element.style.opacity = "1";
      element.style.paddingTop = "10px";
    }
  };

  return (
    <div
      className={`songs-list `}
      style={{
        borderRadius: border === "round" ? 20 : border === "sharp" ? 0 : null,
      }}
    >
      <div className="d-flex align-items-center justify-content-between">
        <h4 style={{ color: "#000" }}>Songs to learn</h4>
        <h5
          onClick={() => showSongsModal(true)}
          style={{ color: "#000", cursor: "pointer" }}
        >
          View all
        </h5>
      </div>
      {/* Songs list */}
      <div
        className="songs-cont d-flex flex-column gap-2 mt-3"
        style={{ paddingBottom: 10, overflow: "auto" }}
      >
        {songsLibrary &&
          songsLibrary.length > 0 &&
          songsLibrary.map((item, index) => {
            return (
              <div key={index} className="d-flex flex-column">
                <div
                  className="d-flex align-items-center"
                  key={item.id}
                  style={{
                    borderBottom: "1px solid #f3f3f3",
                    paddingBlock: 10,
                    cursor: "pointer",
                  }}
                  onClick={() => toggleDropdown(item.id)}
                >
                  <Play
                    // onClick={() => toggleDropdown(item.id)}
                    fill="#000"
                    size={20}
                    style={{
                      cursor: "pointer",
                      transform: openSongIds.includes(item.id)
                        ? "rotate(0.25turn)"
                        : "rotate(1turn)",
                    }}
                  />
                  <span
                    style={{
                      color: "#000",
                      marginInline: 40,
                      fontWeight: "600",
                      fontSize: 16,
                      fontFamily: "sans-serif",
                    }}
                  >
                    5:00
                  </span>
                  <span
                    style={{
                      color: "#000",
                      fontWeight: "600",
                      fontSize: 16,
                      fontFamily: "sans-serif",
                    }}
                  >
                    {item?.song_name}
                  </span>
                </div>
                <div
                  ref={(el) => (songRefs.current[item.id] = el)}
                  className={`song-content d-flex flex-column gap-3 ${
                    openSongIds.includes(item.id) ? "open" : ""
                  }`}
                >
                  <div className="d-flex">
                    <h6 className="additional-data">Full Name:</h6>
                    <h6 className="additional-data">''</h6>
                  </div>
                  <div className="d-flex">
                    <h6 className="additional-data">Extension Number:</h6>
                    <h6 className="additional-data">''</h6>
                  </div>
                  <div className="d-flex">
                    <h6 className="additional-data">Extra Info:</h6>
                    <h6 className="additional-data">''</h6>
                  </div>
                </div>
              </div>
            );
          })}
      </div>

      <Modal
        size={"lg"}
        show={songsModal}
        onHide={() => {
          showSongsModal(false);
          setPlayingSongId(null);
        }}
      >
        <Modal.Body style={{ padding: 0 }} className="cancel-modal col-md-12">
          <div
            className="confirmation-dialog"
            style={{
              backgroundColor: "black",
              borderRadius: 10,
              padding: 20,
            }}
          >
            <div className="d-flex justify-content-end">
              <X
                onClick={() => showSongsModal(false)}
                size={20}
                fill="#8F9090"
                color="#8F9090"
                strokeWidth={5}
                style={{ cursor: "pointer" }}
              />
            </div>
            <div className="d-flex my-3" style={{ border: "1px solid #fff" }} />
            <div
              className="songs-cont d-flex flex-column gap-2 mt-3"
              style={{ paddingBottom: 10, overflow: "auto", maxHeight: 230 }}
            >
              {songsLibrary.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="d-flex flex-column"
                    style={{ backgroundColor: "#fff" }}
                  >
                    <div
                      className="d-flex align-items-center"
                      key={item.id}
                      style={{
                        borderBottom: "1px solid #f3f3f3",
                        padding: 10,
                        cursor: "pointer",
                      }}
                      onClick={() => toggleModalDropdown(item.id)}
                    >
                      <Play
                        // onClick={() => toggleDropdown(item.id)}
                        fill="#000"
                        size={20}
                        style={{
                          cursor: "pointer",
                          transform: modalOpenSongIds.includes(item.id)
                            ? "rotate(0.25turn)"
                            : "rotate(1turn)",
                        }}
                      />
                      <span
                        style={{
                          color: "#000",
                          marginInline: 40,
                          fontWeight: "600",
                          fontSize: 16,
                          fontFamily: "sans-serif",
                        }}
                      >
                        5:00
                      </span>
                      <span
                        style={{
                          color: "#000",
                          fontWeight: "600",
                          fontSize: 16,
                          fontFamily: "sans-serif",
                        }}
                      >
                        {item.song_name}
                      </span>
                      <a
                        // href={`http://137.184.155.215${item.song_mp3}`}
                        target="_blank"
                        onClick={(e) => {
                          handlePlay(item.id);
                          e.stopPropagation();
                        }}
                      >
                        <img
                          src={Assets.Speaker}
                          style={{
                            width: 18,
                            height: 18,
                            filter: "brightness(0)",
                            marginLeft: 20,
                          }}
                        />
                      </a>
                    </div>
                    {playingSongId === item.id && (
                      <div
                        style={{
                          transition: "max-height 0.3s ease",
                          overflow: "hidden",
                        }}
                      >
                        <AudioPlayer
                          ref={audioRef}
                          // src={
                          //   "https://redefine.church/images/song_arr_key_file/66a93ee686510_Another%20Stone%20-%20Default%20-%20Db%20-%20(w:o.Ref).mp3"
                          // }
                          src={`${process.env.REACT_APP_BASE_URL}/${removeStartingSlash(
                            item.song_mp3
                          )}`}
                          // autoPlay
                          // autoPlayAfterSrcChange
                          preload="auto"
                          showDownloadProgress
                          loop={false}
                          customAdditionalControls={[]}
                          onPlayError={(e) => {
                            console.log("SONG ERR>", e);
                            toast.error(e.message, { duration: 5000,id:'onPlyErr' });
                          }}
                          // onError={(e) => {
                          //   if (e.target.error.message) {
                          //     toast.error(e.target.error.message, {
                          //       id: "onErr",
                          //       duration: 3000,
                          //     });
                          //   }
                          //   console.log("ON ERR>", e.target.error.message);
                          // }}
                          // onPlay={() =>
                          //   console.log(`Playing ${item.song_name}`)
                          // }
                          // onCanPlay={(e) => console.log("CAN PLAY>", e)}
                          // onCanPlayThrough={(e) =>
                          //   console.log("PLAY THROUGH>", e)
                          // }
                          // onChangeCurrentTimeError={(e) =>
                          //   console.log("CURRENT TIME ERR>", e)
                          // }
                          onEnded={() => {
                            setPlayingSongId(null);
                          }} 
                          // onPause={() => {
                          // }} 
                        />
                      </div>
                    )}
                    <div
                      ref={(el) => (modalSongRefs.current[item.id] = el)}
                      className={`song-content d-flex flex-column gap-3 ${
                        modalOpenSongIds.includes(item.id) ? "open" : ""
                      }`}
                      style={{ paddingInline: 10 }}
                    >
                      <div className="d-flex">
                        <h6 className="additional-data">Full Name:</h6>
                        <h6 className="additional-data">''</h6>
                      </div>
                      <div className="d-flex">
                        <h6 className="additional-data">Extension Number:</h6>
                        <h6 className="additional-data">''</h6>
                      </div>
                      <div className="d-flex">
                        <h6 className="additional-data">Extra Info:</h6>
                        <h6 className="additional-data">''</h6>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="d-flex my-3" style={{ border: "1px solid #fff" }} />
            <div className="d-flex align-items-center justify-content-end">
              <button
                onClick={() => showSongsModal(false)}
                style={{
                  backgroundColor: "#6c757d",
                  color: "#fff",
                  padding: 6,
                  borderRadius: 5,
                }}
              >
                Close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SongsWidget;
