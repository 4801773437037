import React, { useEffect, useState, useRef } from "react";
import "../../home/styles.css";
import { useSelector } from "react-redux";
import { Player, ControlBar, BigPlayButton } from "video-react";
import "video-react/dist/video-react.css";
import HLSSource from "../videoWidget/HLSSource";
import liveGif from "../../../assets/live.gif";

const WatchPlayerWidget = ({ widgetId, itemStyles }) => {
  const playerRef = useRef(null);
  const data = useSelector((state) => state.bodyStyling.styling);
  const [streamId, setStreamId] = useState(null);

  useEffect(() => {
    if (data?.liveVideo) {
      setStreamId(data?.liveVideo?.stream_session_id);
    } else if (data?.latestVideo) {
      setStreamId(data?.latestVideo?.stream_session_id);
    }
  }, [data]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      const player = playerRef.current;
      if (!player) return;

      if (document.hidden) {
        player.pause();
      } else {
        const promise = player.play();
        if (promise !== undefined) {
          promise.catch((error) => {
            console.log("Play was prevented: ", error);
          });
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <div className="d-flex col-md-12 align-items-center justify-content-center">
      <Player ref={playerRef} autoPlay muted>
        <HLSSource
          isVideoChild
          src={`https://live.redefine.church/live/${streamId}/index.m3u8`}
        />
        {data?.liveVideo && (
          <img className="live-gif" style={{ right: 10 }} src={liveGif} />
        )}
        <ControlBar autoHide={true} />
        <BigPlayButton position="center" />
      </Player>
    </div>
  );
};

export default WatchPlayerWidget;

// OLD
// import React, { useEffect, useState } from "react";
// import "../../home/styles.css";
// import { useSelector } from "react-redux";

// const WatchPlayerWidget = ({ widgetId, itemStyles }) => {
//   const editingMode = useSelector((state) => state.bodyStyling.isEdit);
//   const universalStyles = useSelector(
//     (state) => state.bodyStyling.universalStyles
//   );

//   const [border, setBorder] = useState("");
//   const [defaultCorners, setDefaultCorners] = useState("true");

//   useEffect(() => {
//     if (itemStyles) {
//       setDefaultCorners(itemStyles.defaultCorners);
//       setBorder(itemStyles.border);
//     }
//   }, [itemStyles]);

//   useEffect(() => {
//     if (universalStyles.corners && itemStyles.defaultCorners == "true") {
//       setBorder(universalStyles.corners);
//     }
//   }, [universalStyles.corners, itemStyles]);

//   return (
//     <div
//       className={`videoDiv ${
//         editingMode.widgtId == widgetId ? "edit-mode" : ""
//       }`}
//       style={{
//         borderRadius: border === "round" ? 20 : border === "sharp" ? 0 : null,
//       }}
//     ></div>
//   );
// };

// export default WatchPlayerWidget;
