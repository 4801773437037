import React, { useState, useEffect } from "react";
import "../../home/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { getGradient } from "../../../utills/getGradient";
import { CalendarDays, CreditCard } from "lucide-react";
import {
    useStripe,
    useElements,
    CardElement,
    Elements,
} from "@stripe/react-stripe-js";
import ReactDatePicker, { CalendarContainer } from "react-datepicker";
import ReactDOM from "react-dom";
import { Circles } from "react-loader-spinner";
import toast from "react-hot-toast";
import { postRequest } from "../../../utills/requests";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import {
    hasError,
    loginSuccess,
    requestLogin,
    setUserProfile,
} from "../../../store/slices/loginSlice";
import { useNavigate } from "react-router-dom";

const GivingLogoutWidget = ({ itemStyles, width, height, widgetId }) => {
    const editingMode = useSelector((state) => state.bodyStyling.isEdit);
    const guestGivingData = useSelector(
        (state) => state.giving.guestGivingData
    );
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [border, setBorder] = useState("");
    const universalStyles = useSelector(
        (state) => state.bodyStyling.universalStyles
    );
    const { default_widget_colors, mainColor } = universalStyles;
    const [defaultCorners, setDefaultCorners] = useState("true");
    const [startDate, setStartDate] = useState(new Date());

    const [giftType, setGiftType] = useState(1);
    const [frequency, setFrequency] = useState("Weekly");
    const [loading, setLoading] = useState(false);

    const stripe = useStripe();
    const elements = useElements();

    const [amount, setAmount] = useState(0);

    const [data, setData] = useState({
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        password: "",
        confirmPassword: "",
        cardName: "",
    });

    const mapping = {
        Weekly: "Weekly",
        "Bi-Weekly": "Biweekly",
        Monthly: "Monthly",
    };

    const renderCalendarContainer = ({ children }) => {
        return ReactDOM.createPortal(
            <CalendarContainer>{children}</CalendarContainer>,
            document.body
        );
    };

    useEffect(() => {
        if (guestGivingData?.amount) {
            setAmount(guestGivingData?.amount);
        }
        if (guestGivingData?.startDate) {
            setStartDate(guestGivingData?.startDate);
        }
        if (guestGivingData?.giftType) {
            setGiftType(guestGivingData?.giftType);
        }
        if (guestGivingData?.frequency) {
            setFrequency(guestGivingData?.frequency);
        }
    }, [guestGivingData]);

    useEffect(() => {
        if (universalStyles.corners && defaultCorners == "true") {
            setBorder(universalStyles.corners);
        }
        if (universalStyles.font) {
            font.isDefaultFont == 1 &&
                setFont((prevFont) => ({
                    ...prevFont,
                    family: universalStyles.font,
                }));
            buttonFont.isDefaultFont == 1 &&
                setButtonFont((prevFont) => ({
                    ...prevFont,
                    family: universalStyles.font,
                }));
        }
    }, [universalStyles.corners, universalStyles.font]);

    useEffect(() => {
        if (itemStyles) {
            // setColor(itemStyles.background_color);
            if (itemStyles.background_color) {
                try {
                    const bgstyle = JSON.parse(itemStyles.background_color);
                    setColor(bgstyle);
                } catch (error) {
                    const bgstyle = itemStyles.background_color;
                    setColor(bgstyle);
                }
            }
            setBtnTxt(itemStyles.button_text);
            // setBtnBg(itemStyles.button_background_color);
            if (itemStyles.button_background_color) {
                try {
                    const bgstyle = JSON.parse(
                        itemStyles.button_background_color
                    );
                    setBtnBg(bgstyle);
                } catch (error) {
                    const bgstyle = itemStyles.button_background_color;
                    setBtnBg(bgstyle);
                }
            }
            setTxtColor(itemStyles.button_text_color);
            if (itemStyles.title_font_style) {
                try {
                    const fontstyle = JSON.parse(itemStyles.title_font_style);
                    setFont({
                        ...fontstyle,
                        color: itemStyles.title_color,
                        family: itemStyles.title_font,
                        size: itemStyles.title_font_size,
                    });
                } catch (error) {
                    const fontstyle = itemStyles.title_font_style;
                    setFont({
                        ...fontstyle,
                        color: itemStyles.title_color,
                        family: itemStyles.title_font,
                        size: itemStyles.title_font_size,
                    });
                }
            }
            if (itemStyles.button_font_style) {
                try {
                    const buttonStyle = JSON.parse(
                        itemStyles.button_font_style
                    );
                    setButtonFont({
                        ...buttonStyle,
                        color: itemStyles.button_text_color,
                        family: itemStyles.button_font,
                        size: itemStyles.button_font_size,
                    });
                    setDefaultButton(buttonStyle.isDefaultButton);
                } catch (error) {
                    const buttonStyle = itemStyles.button_font_style;
                    setButtonFont({
                        ...buttonStyle,
                        color: itemStyles.button_text_color,
                        family: itemStyles.button_font,
                        size: itemStyles.button_font_size,
                    });
                    setDefaultButton(buttonStyle.isDefaultButton);
                    console.error(
                        "Error parsing heading title font style:",
                        error
                    );
                }
            }

            setLink(itemStyles.widget_link);
            setBtnLink(itemStyles.button_link);
            setBorder(itemStyles.border);
            setDefaultCorners(itemStyles.defaultCorners);
            setDefault(itemStyles.is_default);
        }
    }, [itemStyles]);

    const [isDefaultButton, setDefaultButton] = useState(0);
    const [buttonFont, setButtonFont] = useState({
        color: "#ffffff",
        isBold: false,
        isItalic: false,
        isUnderlined: false,
        family: "gilroymedium",
        size: 18,
        isDefaultFont: 1,
    });

    const [isDefault, setDefault] = useState(0);
    const [isEditText, setEditText] = useState(false);
    const [color, setColor] = useState("#f8acac");
    const [font, setFont] = useState({
        color: "#ffffff",
        isBold: false,
        isItalic: false,
        isUnderlined: false,
        family: "frontage",
        size: 22,
        isDefaultFont: 1,
    });
    const [btnBg, setBtnBg] = useState("");
    const [btnTxt, setBtnTxt] = useState("");
    const [txtColor, setTxtColor] = useState("");
    const [link, setLink] = useState("");
    const [btnlink, setBtnLink] = useState("");

    const [err, setErr] = useState({
        first: "",
        last: "",
        amount: "",
        email: "",
        phone: "",
        password: "",
        confirm: "",
        cardName: "",
        cardErr: "",
    });

    const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;

    const handleSubmit = async () => {
        if (amount <= 0) {
            toast.error("Please enter a valid amount.", { id: "amountl237" });
            return;
        }
        if (data.firstName.trim().length == 0) {
            toast.error("Please enter first name.", { id: "firstil27" });
            return;
        }
        if (data.lastName.trim().length == 0) {
            toast.error("Please enter last name.", { id: "last237" });
            return;
        }
        if (data.email.trim().length == 0) {
            toast.error("Please enter your email.", { id: "shdgshemail" });
            return;
        }
        if (data.phone.trim().length == 0) {
            toast.error("Please enter your phone no.", { id: "shdmail" });
            return;
        }
        if (!passwordRegex.test(data.password)) {
            setErr((prev) => {
                return {
                    ...prev,
                    password:
                        "The password must be a minimum of 8 characters and should include at least one special character and one uppercase letter. For example: 'User@12345678'",
                };
            });
            return;
        } else {
            setErr((prev) => {
                return {
                    ...prev,
                    password: "",
                };
            });
        }
        if (data.password !== data.confirmPassword) {
            toast.error("Passwords does not match.", {
                id: "passhemmatch",
            });
            return;
        }
        if (data.cardName.trim().length == 0) {
            toast.error("Please enter card holder name.", {
                id: "name",
            });
            return;
        }

        if (!stripe || !elements) {
            return;
        }
        setLoading(true);
        const cardElement = elements.getElement(CardElement);
        const { error, token } = await stripe.createToken(cardElement);
        if (error) {
            console.log("[error]", error);
            error?.message && toast.error(error.message);
            setLoading(false);
        } else {
            console.log("[PaymentMethod]", token);
            submitData(token.id);
        }
    };

    const submitData = async (token) => {
        const payload = {
            amount: amount,
            gift: giftType == 1 ? "No" : "Yes",
            datepicker_date: moment(startDate).format("MM/DD/YYYY"),
            first_name: data.firstName,
            last_name: data.lastName,
            email: data.email,
            phone: data.phone,
            password: data.password,
            password_confirmation: data.confirmPassword,
            name: data.cardName,
            frequency: mapping[frequency],
            currency_code: "USD",
            item_name: "Giving",
            item_number: "Giving -" + uuidv4(),
            stripeToken: token,
        };
        try {
            const response = await postRequest({
                endpoint: "/stripePostguest",
                payload: payload,
            });

            if (response.data.ordStatus === "success") {
                toast.success(response.data.message, {
                    duration: 4000,
                    id: "payment-success",
                });
                console.log("RESP>", response.data);
                loginUser();
            }
        } catch (error) {
            console.log("ERR>", error);
            if (error.response.data.message) {
                toast.error(error.response.data.message);
            }
        } finally {
            setLoading(false);
        }
    };

    const loginUser = async () => {
        const payload = {
            username: data.firstName + " " + data.lastName,
            email: data.email,
            password: data.password,
        };
        dispatch(requestLogin(true));
        try {
            const response = await postRequest({
                endpoint: "/loginReact",
                payload: payload,
            });
            if (response?.data?.status === true) {
                const userData = {
                    ...response.data.user,
                    is_part_of_any_team: response.data?.is_part_of_any_team,
                    permissions: response.data?.permissions,
                };
                dispatch(loginSuccess(userData));
                dispatch(setUserProfile(response.data.user_profile));
                localStorage.setItem(
                    "accessToken",
                    response?.data?.user?.api_token
                );
                toast.dismiss();
                toast.success("Logged In", { duration: 3000 });
                navigate("/giving");
            } else {
                if (response?.data?.message) {
                    dispatch(hasError(response?.data?.message));
                }
                console.log("ERR>", response?.data?.message);
            }
        } catch (error) {
            console.log("ERR>", error);
            if (error?.data?.message) {
                toast.error(error.data.message, { duration: 3000 });
            }
        } finally {
            dispatch(requestLogin(false));
        }
    };

    return (
        <div
            className={`col-md-12 giving`}
            style={{
                background: `${
                    isDefault && default_widget_colors.giving
                        ? default_widget_colors.giving.is_default === "0"
                            ? default_widget_colors.giving.color
                            : default_widget_colors.giving.is_default === "1"
                            ? mainColor
                            : getGradient(color)
                        : getGradient(color)
                }`,
                justifyContent: height == 8 ? "space-around" : "space-between",
                borderRadius:
                    border === "round" ? 20 : border === "sharp" ? 0 : null,
            }}
        >
            <div className="d-flex flex-column logout-giving-cont">
                <div className="d-flex flex-column">
                    <div className="d-flex p-2">
                        <h2
                            style={{
                                color: font.color ? font.color : "white",
                                textDecorationLine: font.isUnderlined
                                    ? "underline"
                                    : "none",
                                fontWeight: font.isBold ? "bold" : "normal",
                                fontStyle: font.isItalic ? "italic" : "normal",
                                fontFamily: font.family,
                                fontSize: Number(font.size),
                                outline: isEditText ? "dotted" : "unset",
                                pointerEvents:
                                    editingMode.widgtId == widgetId
                                        ? "all"
                                        : "none",
                                margin: 0,
                            }}
                            className="maintitle"
                        >
                            Give
                        </h2>
                    </div>
                    <div
                        className="d-flex col-md-12 amount-Input"
                        style={{ paddingBottom: 10 }}
                    >
                        <span style={{ fontSize: 13, color: "#fff" }}>$</span>
                        <input
                            value={amount}
                            min={0}
                            onChange={(e) => setAmount(e.target.value)}
                            type="number"
                            placeholder="Amount"
                            style={{
                                border: "unset",
                                backgroundColor: "transparent",
                                color: "#fff",
                                outline: "unset",
                                width: "90%",
                            }}
                        />
                    </div>
                </div>

                {/* radio  button */}
                <div
                    className="d-flex flex-column col-md-12 my-2"
                    style={{ rowGap: 10 }}
                >
                    <div
                        onClick={() => setGiftType(1)}
                        className="d-flex gap-2"
                        style={{ cursor: "pointer" }}
                    >
                        <div className={`outerCircle outerCircleHide}`}>
                            <div className={giftType == 1 ? "circleDot" : ""} />
                        </div>
                        <h6 className="gift-type">One Time Gift</h6>
                    </div>
                    <div
                        onClick={() => setGiftType(2)}
                        className="d-flex gap-2"
                        style={{ cursor: "pointer" }}
                    >
                        <div className={`outerCircle outerCircleHide}`}>
                            <div className={giftType == 2 ? "circleDot" : ""} />
                        </div>
                        <h6 className="gift-type">Reoccuring Gift</h6>
                    </div>
                </div>
                {/*  */}
                {/* Info section */}
                <div className=" d-flex flex-column col-md-12 mb-3 gap-3">
                    {giftType == 2 && (
                        <div className="inputs-wrapper">
                            <div className="amount-Input">
                                <ReactDatePicker
                                    className="date-input"
                                    popperContainer={renderCalendarContainer}
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    showIcon
                                    minDate={new Date()}
                                    isClearable
                                    placeholderText="MM/DD/YYYY"
                                    icon={
                                        <CalendarDays
                                            size={20}
                                            color="#fff"
                                            style={{
                                                top: -2,
                                            }}
                                        />
                                    }
                                    closeOnScroll
                                    autoFocus={false}
                                    // disabled
                                    // To restrict keyboard to open on mobile browsers
                                    onFocus={(e) => (e.target.readOnly = true)}
                                />
                            </div>
                            {/* Frequency */}
                            <div className="d-flex column-gap-3 row-gap-1 flex-wrap align-items-end justify-content-end">
                                {["Weekly", "Bi-Weekly", "Monthly"].map(
                                    (item) => {
                                        return (
                                            <div
                                                className="d-flex col-md-3 align-items-center justify-content-center"
                                                onClick={() =>
                                                    setFrequency(item)
                                                }
                                                style={{
                                                    backgroundColor:
                                                        frequency === item
                                                            ? "black"
                                                            : "#fff",
                                                    paddingInline: 10,
                                                    // paddingBlock: 6,
                                                    minWidth: "fit-content",
                                                    cursor: "pointer",
                                                    height: 30,
                                                    borderRadius: 2,
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontSize: 12,
                                                        color: "#fb5656",
                                                    }}
                                                >
                                                    {item}
                                                </span>
                                            </div>
                                        );
                                    }
                                )}
                            </div>
                        </div>
                    )}
                    <div className="inputs-wrapper">
                        <div
                            className="d-flex amount-Input"
                            style={{ paddingBottom: 10, width: "100%" }}
                        >
                            <input
                                value={data.firstName}
                                onChange={(e) =>
                                    setData((prev) => {
                                        return {
                                            ...prev,
                                            firstName: e.target.value,
                                        };
                                    })
                                }
                                type="text"
                                placeholder="First Name"
                                style={{
                                    border: "unset",
                                    backgroundColor: "transparent",
                                    color: "#fff",
                                    outline: "unset",
                                    width: "100%",
                                }}
                            />
                        </div>
                        <div
                            className="d-flex amount-Input"
                            style={{ paddingBottom: 10, width: "100%" }}
                        >
                            <input
                                value={data.lastName}
                                onChange={(e) =>
                                    setData((prev) => {
                                        return {
                                            ...prev,
                                            lastName: e.target.value,
                                        };
                                    })
                                }
                                type="text"
                                placeholder="Last Name"
                                style={{
                                    border: "unset",
                                    backgroundColor: "transparent",
                                    color: "#fff",
                                    outline: "unset",
                                    width: "100%",
                                }}
                            />
                        </div>
                    </div>
                    <div className="inputs-wrapper">
                        <div
                            className="d-flex amount-Input"
                            style={{ paddingBottom: 10, width: "100%" }}
                        >
                            <input
                                value={data.email}
                                onChange={(e) =>
                                    setData((prev) => {
                                        return {
                                            ...prev,
                                            email: e.target.value,
                                        };
                                    })
                                }
                                type="email"
                                placeholder="Email Address"
                                required
                                style={{
                                    border: "unset",
                                    backgroundColor: "transparent",
                                    color: "#fff",
                                    outline: "unset",
                                    width: "100%",
                                }}
                            />
                        </div>
                        <div
                            className="d-flex amount-Input"
                            style={{ paddingBottom: 10, width: "100%" }}
                        >
                            <input
                                value={data.phone}
                                onChange={(e) =>
                                    setData((prev) => {
                                        return {
                                            ...prev,
                                            phone: e.target.value,
                                        };
                                    })
                                }
                                type="text"
                                placeholder="Phone Number"
                                style={{
                                    border: "unset",
                                    backgroundColor: "transparent",
                                    color: "#fff",
                                    outline: "unset",
                                    width: "100%",
                                }}
                            />
                        </div>
                    </div>
                    <div className="inputs-wrapper">
                        <div
                            className="d-flex amount-Input"
                            style={{ paddingBottom: 10, width: "100%" }}
                        >
                            <input
                                value={data.password}
                                onChange={(e) =>
                                    setData((prev) => {
                                        return {
                                            ...prev,
                                            password: e.target.value,
                                        };
                                    })
                                }
                                placeholder="Password"
                                type="password"
                                required
                                style={{
                                    border: "unset",
                                    backgroundColor: "transparent",
                                    color: "#fff",
                                    outline: "unset",
                                    width: "100%",
                                }}
                            />
                        </div>
                        <div
                            className="d-flex amount-Input"
                            style={{ paddingBottom: 10, width: "100%" }}
                        >
                            <input
                                value={data.confirmPassword}
                                type="password"
                                onChange={(e) =>
                                    setData((prev) => {
                                        return {
                                            ...prev,
                                            confirmPassword: e.target.value,
                                        };
                                    })
                                }
                                placeholder="Confirm Password"
                                style={{
                                    border: "unset",
                                    backgroundColor: "transparent",
                                    color: "#fff",
                                    outline: "unset",
                                    width: "100%",
                                }}
                            />
                        </div>
                    </div>
                    {err.password && (
                        <p style={{ color: "#fbff00", fontSize: 13 }}>
                            {err.password}
                        </p>
                    )}
                </div>
                {/* Card section */}
                <div className="d-flex flex-column flex-wrap justify-content-between mt-3">
                    <div
                        className="d-flex justify-content-center align-items-center mb-3 "
                        style={{
                            backgroundColor: "#fff",
                            alignSelf: "flex-start",
                            paddingBlock: 2,
                            paddingInline: 20,
                            columnGap: 5,
                            borderRadius: 2,
                        }}
                    >
                        <CreditCard size={18} color="#fb5656" />
                        <h6 style={{ color: "#fb5656", margin: 0 }}>Card</h6>
                    </div>
                    <div
                        className="d-flex col-md-12 amount-Input mb-2"
                        style={{ paddingBottom: 10 }}
                    >
                        <input
                            value={data.cardName}
                            onChange={(e) => {
                                setData((prev) => {
                                    return {
                                        ...prev,
                                        cardName: e.target.value,
                                    };
                                });
                            }}
                            placeholder="Card Holder Name"
                            type="text"
                            style={{
                                border: "unset",
                                backgroundColor: "transparent",
                                color: "#fff",
                                outline: "unset",
                                width: "100%",
                            }}
                        />
                    </div>
                    <CardElement
                        // options={{ style: { base: { fontSize: "16px" } } }}
                        id="card-element2"
                        options={{
                            style: {
                                base: {
                                    color: "#fff", // Set the color of the input text to white
                                    "::placeholder": {
                                        color: "#fff", // Set the color of the input placeholder text to white
                                    },
                                },
                            },
                        }}
                    />
                    <div
                        className="d-flex align-items-center submit-btn-give halffield mt-4"
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <button
                            onClick={handleSubmit}
                            style={{
                                background: getGradient(btnBg),
                                color: txtColor,
                                fontSize: Number(buttonFont.size),
                                fontFamily:
                                    buttonFont.family === "default"
                                        ? universalStyles.font
                                        : buttonFont.family
                                        ? buttonFont.family
                                        : "sans-serif",
                                fontStyle: buttonFont.isItalic
                                    ? "italic"
                                    : "normal",
                                textDecorationLine: buttonFont.isUnderlined
                                    ? "underline"
                                    : "none",
                                fontWeight: buttonFont.isBold
                                    ? "bold"
                                    : "normal",
                                padding: 6,
                                paddingInline: 10,
                                borderRadius: 5,
                                minWidth: 120,
                            }}
                        >
                            {loading ? (
                                <Circles
                                    height="20"
                                    width="20"
                                    color={txtColor ? txtColor : "#000"}
                                    ariaLabel="circles-loading"
                                    wrapperClass=""
                                    visible={true}
                                    wrapperStyle={{
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                />
                            ) : btnTxt ? (
                                btnTxt
                            ) : (
                                "Give Now"
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GivingLogoutWidget;
