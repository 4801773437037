import React, { useState } from "react";
import { Toaster } from "react-hot-toast";
// Assets
import play_svg from "../../assets/svg/play.svg";
import heart_svg from "../../assets/svg/heart.svg";
import message_svg from "../../assets/svg/message.svg";
import people_svg from "../../assets/svg/people.svg";
import placeholder from "../../assets/placeholder.png";
import link_svg from "../../assets/svg/Link_color_white.svg";
import Hamburger from "hamburger-react";
import { ChevronDown, ChevronDownSquare, ChevronUp } from "lucide-react";
import { useSelector } from "react-redux";

export default function Header({ darkMode }) {
    const userProfile = useSelector((state) => state.login.userProfile);
    const user = useSelector((state) => state.login.userData);
    const hostname = "dashboard.redefine.church";
    const [isOpen, setOpen] = useState(false);
    const [innerDrop, setInnerDrop] = useState(false);

    const { permissions } = user || {};

    const redirectToLaravel = (route) => {
        const url = `${route}?apiToken=${user?.api_token}`;
        // Redirect to the Laravel app
        window.location.href = url;
    };

    return (
        <header
            className="newHeader blueHeader"
            style={{ backgroundColor: darkMode ? "#171717" : "#fff" }}
        >
            <Toaster position="top-center" />
            <div className="container-fluid">
                <div className="row d-flex align-items-center">
                    <div className="col-md-3 logo-main mainHeader">
                        <a href="/">
                            <img
                                style={{
                                    filter: !darkMode
                                        ? "brightness(0)"
                                        : "unset",
                                }}
                                src="https://images.ctfassets.net/1itkm9rji8jb/48jN6KXbjOANg70fZ2oHFE/66ead803c36b23e6752a0a2602e659bc/logo__1_.png"
                                alt="Logo"
                            />
                        </a>
                    </div>
                    {/* hamburger  */}
                    <div className="col-md-3 logo-main header-Logo">
                        <div style={{ width: 60 }} />
                        <a href="/">
                            <img
                                style={{
                                    filter: !darkMode
                                        ? "brightness(0)"
                                        : "unset",
                                }}
                                src="https://images.ctfassets.net/1itkm9rji8jb/48jN6KXbjOANg70fZ2oHFE/66ead803c36b23e6752a0a2602e659bc/logo__1_.png"
                                alt="Logo"
                            />
                        </a>
                        <div>
                            <Hamburger
                                color={darkMode ? "#fff" : "#000"}
                                toggled={isOpen}
                                toggle={setOpen}
                            />
                            <div
                                // className="mobileMenuDesign"
                                className={`mobileMenuDesign ${
                                    isOpen ? "open" : ""
                                }`}
                                // style={{ display: isOpen ? "block" : "none" }}
                                // style={{
                                //   maxHeight: isOpen && innerDrop ? 640 : isOpen ? 600 : 0,
                                // }}
                            >
                                <div
                                    className="admin-dashboard-left"
                                    style={{ maxHeight: 550, overflow: "auto" }}
                                >
                                    <div className="dropdownnewpro">
                                        <div className="innerdropdownmain">
                                            <div className="innerdropdownmainLEft">
                                                <a href="/userProfile">
                                                    <img
                                                        src={
                                                            userProfile?.profile_pic
                                                                ? `${process.env.REACT_APP_BASE_URL}/${userProfile?.profile_pic}`
                                                                : placeholder
                                                        }
                                                        alt=""
                                                    />
                                                    <h5>{user?.username}</h5>
                                                </a>
                                            </div>
                                            <div
                                                onClick={() =>
                                                    setInnerDrop(!innerDrop)
                                                }
                                                className="innerdropdownmainRight"
                                            >
                                                <div className="angledownopenmenu">
                                                    {innerDrop ? (
                                                        <ChevronUp
                                                            color="#fff"
                                                            size={20}
                                                        />
                                                    ) : (
                                                        <ChevronDown
                                                            color="#fff"
                                                            size={20}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className={`menuDropinner ${
                                                innerDrop ? "open" : ""
                                            }`}
                                        >
                                            <ul>
                                                <li className="">
                                                    {user?.role == 1 ? (
                                                        <a
                                                            // href={`http://${hostname}/admin/users`}
                                                            onClick={(e) => {
                                                                redirectToLaravel(
                                                                    `http://${hostname}/admin/users`
                                                                );
                                                            }}
                                                            className="nav-link"
                                                        >
                                                            <span>
                                                                Dashboard
                                                            </span>
                                                        </a>
                                                    ) : (
                                                        <a
                                                            href={"#"}
                                                            className="nav-link"
                                                        >
                                                            <span>Profile</span>
                                                        </a>
                                                    )}
                                                </li>
                                                <li>
                                                    <a
                                                        onClick={() => {
                                                            localStorage.clear();
                                                        }}
                                                        href="/"
                                                        className="nav-link"
                                                    >
                                                        <span>Logout</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* {user?.role == "1" && ( */}
                                        <>
                                            <h3>Shortcuts</h3>
                                            <div className="header-menu-main-left">
                                                <div className="header-menu newmenuDesign">
                                                    <ul>
                                                        {(permissions?.user_access ||
                                                            user?.role ==
                                                                1) && (
                                                            <li className="">
                                                                <a
                                                                    // href={`http://${hostname}/admin/users`}
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        redirectToLaravel(
                                                                            `http://${hostname}/admin/users`
                                                                        );
                                                                    }}
                                                                >
                                                                    <img
                                                                        src="https://images.ctfassets.net/1itkm9rji8jb/5Ge0I3BVeTdtrZTCOpiosW/9f770ffed189d3a9c63237d55c5e7166/ico-people.svg"
                                                                        alt=""
                                                                    />
                                                                    <span>
                                                                        People
                                                                    </span>
                                                                </a>
                                                            </li>
                                                        )}
                                                        {(permissions?.scheduling ||
                                                            user?.role ==
                                                                1) && (
                                                            <li className="">
                                                                <a
                                                                    // href={`http://${hostname}/admin/schedule`}
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        redirectToLaravel(
                                                                            `http://${hostname}/admin/schedule`
                                                                        );
                                                                    }}
                                                                >
                                                                    <img
                                                                        src="https://images.ctfassets.net/1itkm9rji8jb/262tDgGk3FOuhQ8nlVUoiY/d0232b2f29e5193aaedae4a4b6bfe08f/ico-sch.svg"
                                                                        alt=""
                                                                    />
                                                                    <span>
                                                                        Schedules
                                                                    </span>
                                                                </a>
                                                            </li>
                                                        )}
                                                        {(permissions?.groups ||
                                                            user?.role ==
                                                                1) && (
                                                            <li className="">
                                                                <a
                                                                    // href={`/group`}
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        redirectToLaravel(
                                                                            `http://${hostname}/admin/groups`
                                                                        );
                                                                    }}
                                                                >
                                                                    <img
                                                                        src="https://images.ctfassets.net/1itkm9rji8jb/4X5RS42wn101bgkUkdQqF0/2b5fe2c4b5a56b3fda01fd2b66a955de/ico-group.svg"
                                                                        alt=""
                                                                    />
                                                                    <span>
                                                                        Groups
                                                                    </span>
                                                                </a>
                                                            </li>
                                                        )}
                                                        {(permissions?.kids_system ||
                                                            user?.role ==
                                                                1) && (
                                                            <li className="">
                                                                <a
                                                                    // href={`/kids`}
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        redirectToLaravel(
                                                                            `http://${hostname}/admin/all-kids`
                                                                        );
                                                                    }}
                                                                >
                                                                    <img
                                                                        src="https://images.ctfassets.net/1itkm9rji8jb/1Qy0TsteEmKbYWTPuaSZSG/faa5a80eadea3cec0e5cc5ed381f1c34/ico-kid.svg"
                                                                        alt=""
                                                                    />
                                                                    <span>
                                                                        Kids
                                                                    </span>
                                                                </a>
                                                            </li>
                                                        )}
                                                        {(permissions?.finances ||
                                                            user?.role ==
                                                                1) && (
                                                            <li className="">
                                                                <a
                                                                    // href={`/giving`}
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        redirectToLaravel(
                                                                            `http://${hostname}/admin/giving`
                                                                        );
                                                                    }}
                                                                >
                                                                    <img
                                                                        src="https://images.ctfassets.net/1itkm9rji8jb/2Tm3pHUA0oiUCmU5nsT4l4/a7e43a934909b17de1d9af6837ddfb02/ico-giving.svg"
                                                                        alt=""
                                                                    />
                                                                    <span>
                                                                        Giving
                                                                    </span>
                                                                </a>
                                                            </li>
                                                        )}
                                                        {(permissions?.live_streaming_set_up ||
                                                            user?.role ==
                                                                1) && (
                                                            <li className="">
                                                                <a
                                                                    // href={`http://${hostname}/admin/livestream`}
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        redirectToLaravel(
                                                                            `http://${hostname}/admin/livestream`
                                                                        );
                                                                    }}
                                                                >
                                                                    <img
                                                                        src="https://images.ctfassets.net/1itkm9rji8jb/6lyyozpxIzbB1ESrcbFC1v/27d14aac52adc9aaada713d7b48f7b22/ico-stream.svg"
                                                                        alt=""
                                                                    />
                                                                    <span>
                                                                        Live
                                                                        Stream
                                                                    </span>
                                                                </a>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>
                                            </div>
                                        </>
                                    {/* )} */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex col-md-5 align-items-center justify-content-between icons-container">
                        <a href={`/timeline`}>
                            <img
                                style={{
                                    filter: !darkMode
                                        ? "brightness(0)"
                                        : "unset",
                                }}
                                className="header-Icon"
                                src={link_svg}
                                alt="Logo"
                            />
                        </a>
                        <a href={`/watch`}>
                            <img
                                style={{
                                    filter: !darkMode
                                        ? "brightness(0)"
                                        : "unset",
                                }}
                                className="header-Icon"
                                src={play_svg}
                                alt="Logo"
                            />
                        </a>
                        <a href={user.is_part_of_any_team ? "/team" : "/serve"}>
                            <img
                                style={{
                                    filter: !darkMode
                                        ? "brightness(0)"
                                        : "unset",
                                }}
                                className="header-Icon"
                                src={people_svg}
                                alt="Logo"
                            />
                        </a>
                        <a href={`/group`}>
                            <img
                                style={{
                                    filter: !darkMode
                                        ? "brightness(0)"
                                        : "unset",
                                }}
                                className="header-Icon"
                                src={message_svg}
                                alt="Logo"
                            />
                        </a>
                        <a href={`/giving`}>
                            <img
                                style={{
                                    filter: !darkMode
                                        ? "brightness(0)"
                                        : "unset",
                                }}
                                className="header-Icon"
                                src={heart_svg}
                                alt="Logo"
                            />
                        </a>
                    </div>

                    <div className="col-md-3 newheaderRight">
                        <div className="headerRightIcon">
                            <div className="newiconheader">
                                {/* <div className="nottii">
                  <img
                    src="https://images.ctfassets.net/1itkm9rji8jb/5uZjMW3E2yBJxOl8sEq3tk/f9e2392657dc94c5d31489e0fa8b5f68/mesg.svg"
                    alt=""
                  />
                </div> */}
                                {/* <div className="mes">
                  <img
                    src="https://images.ctfassets.net/1itkm9rji8jb/4SRholtYMrPCO2YQDLNEyG/2efd1ecafa092541f1e4862d10378bc0/noti.svg"
                    alt=""
                  />
                  <div className="cirlenoti"></div>
                </div> */}
                                <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        // navigate('/userProfile')
                                    }}
                                    className="profileIMg"
                                >
                                    <img
                                        style={{
                                            height: 35,
                                            width: 35,
                                            borderRadius: 50,
                                        }}
                                        src={
                                            userProfile?.profile_pic
                                                ? `${process.env.REACT_APP_BASE_URL}/${userProfile?.profile_pic}`
                                                : placeholder
                                        }
                                        alt=""
                                    />
                                    <div className="mobileMenuDesign">
                                        <div className="admin-dashboard-left">
                                            <div className="dropdownnewpro">
                                                <div className="innerdropdownmain">
                                                    <div className="innerdropdownmainLEft">
                                                        <a href="/userProfile">
                                                            <img
                                                                style={{
                                                                    height: 30,
                                                                    width: 30,
                                                                }}
                                                                src={
                                                                    userProfile?.profile_pic
                                                                        ? `${process.env.REACT_APP_BASE_URL}/${userProfile?.profile_pic}`
                                                                        : placeholder
                                                                }
                                                                alt=""
                                                            />
                                                            <h5>
                                                                {user?.username}
                                                            </h5>
                                                        </a>
                                                    </div>
                                                    <div
                                                        onClick={() =>
                                                            setInnerDrop(
                                                                !innerDrop
                                                            )
                                                        }
                                                        className="innerdropdownmainRight"
                                                    >
                                                        <div className="angledownopenmenu">
                                                            {innerDrop ? (
                                                                <ChevronUp
                                                                    color="#fff"
                                                                    size={20}
                                                                />
                                                            ) : (
                                                                <ChevronDown
                                                                    color="#fff"
                                                                    size={20}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className={`menuDropinner ${
                                                        innerDrop ? "open" : ""
                                                    }`}
                                                >
                                                    <ul>
                                                        <li className="">
                                                            {user?.role == 1 ? (
                                                                <a
                                                                    // href={`http://${hostname}/admin/users`}
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        redirectToLaravel(
                                                                            `http://${hostname}/admin/users`
                                                                        );
                                                                    }}
                                                                    className="nav-link"
                                                                >
                                                                    <span>
                                                                        Dashboard
                                                                    </span>
                                                                </a>
                                                            ) : (
                                                                <a
                                                                    href={`#`}
                                                                    className="nav-link"
                                                                >
                                                                    <span>
                                                                        Profile
                                                                    </span>
                                                                </a>
                                                            )}
                                                        </li>
                                                        <li>
                                                            <a
                                                                onClick={() => {
                                                                    localStorage.clear();
                                                                }}
                                                                href="/"
                                                                className="nav-link"
                                                            >
                                                                <span>
                                                                    Logout
                                                                </span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            {/* {user?.role == 1 && ( */}
                                                <>
                                                    <h3>Shortcuts</h3>
                                                    <div className="header-menu-main-left">
                                                        <div className="header-menu newmenuDesign">
                                                            <ul>
                                                                {(permissions?.user_access ||
                                                                    user?.role ==
                                                                        1) && (
                                                                    <li className="">
                                                                        <a
                                                                            // href={`http://${hostname}/admin/users`}
                                                                            onClick={(
                                                                                e
                                                                            ) => {
                                                                                redirectToLaravel(
                                                                                    `http://${hostname}/admin/users`
                                                                                );
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src="https://images.ctfassets.net/1itkm9rji8jb/5Ge0I3BVeTdtrZTCOpiosW/9f770ffed189d3a9c63237d55c5e7166/ico-people.svg"
                                                                                alt=""
                                                                            />
                                                                            <span>
                                                                                People
                                                                            </span>
                                                                        </a>
                                                                    </li>
                                                                )}
                                                                {(permissions?.scheduling ||
                                                                    user?.role ==
                                                                        1) && (
                                                                    <li className="">
                                                                        <a
                                                                            // href={`http://${hostname}/admin/schedule`}
                                                                            onClick={(
                                                                                e
                                                                            ) => {
                                                                                redirectToLaravel(
                                                                                    `http://${hostname}/admin/schedule`
                                                                                );
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src="https://images.ctfassets.net/1itkm9rji8jb/262tDgGk3FOuhQ8nlVUoiY/d0232b2f29e5193aaedae4a4b6bfe08f/ico-sch.svg"
                                                                                alt=""
                                                                            />
                                                                            <span>
                                                                                Schedules
                                                                            </span>
                                                                        </a>
                                                                    </li>
                                                                )}
                                                                {(permissions?.groups ||
                                                                    user?.role ==
                                                                        1) && (
                                                                    <li className="">
                                                                        <a
                                                                            // href={`/group`}
                                                                            onClick={(
                                                                                e
                                                                            ) => {
                                                                                redirectToLaravel(
                                                                                    `http://${hostname}/admin/groups`
                                                                                );
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src="https://images.ctfassets.net/1itkm9rji8jb/4X5RS42wn101bgkUkdQqF0/2b5fe2c4b5a56b3fda01fd2b66a955de/ico-group.svg"
                                                                                alt=""
                                                                            />
                                                                            <span>
                                                                                Groups
                                                                            </span>
                                                                        </a>
                                                                    </li>
                                                                )}
                                                                {(permissions?.kids_system ||
                                                                    user?.role ==
                                                                        1) && (
                                                                    <li className="">
                                                                        <a
                                                                            // href={`kids`}
                                                                            onClick={(
                                                                                e
                                                                            ) => {
                                                                                redirectToLaravel(
                                                                                    `http://${hostname}/admin/all-kids`
                                                                                );
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src="https://images.ctfassets.net/1itkm9rji8jb/1Qy0TsteEmKbYWTPuaSZSG/faa5a80eadea3cec0e5cc5ed381f1c34/ico-kid.svg"
                                                                                alt=""
                                                                            />
                                                                            <span>
                                                                                Kids
                                                                            </span>
                                                                        </a>
                                                                    </li>
                                                                )}
                                                                {(permissions?.finances ||
                                                                    user?.role ==
                                                                        1) && (
                                                                    <li className="">
                                                                        <a
                                                                            // href={`/giving`}
                                                                            onClick={(
                                                                                e
                                                                            ) => {
                                                                                redirectToLaravel(
                                                                                    `http://${hostname}/admin/giving`
                                                                                );
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src="https://images.ctfassets.net/1itkm9rji8jb/2Tm3pHUA0oiUCmU5nsT4l4/a7e43a934909b17de1d9af6837ddfb02/ico-giving.svg"
                                                                                alt=""
                                                                            />
                                                                            <span>
                                                                                Giving
                                                                            </span>
                                                                        </a>
                                                                    </li>
                                                                )}
                                                                {(permissions?.live_streaming_set_up ||
                                                                    user?.role ==
                                                                        1) && (
                                                                    <li className="">
                                                                        <a
                                                                            // href={`http://${hostname}/admin/livestream`}
                                                                            onClick={(
                                                                                e
                                                                            ) => {
                                                                                redirectToLaravel(
                                                                                    `http://${hostname}/admin/livestream`
                                                                                );
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src="https://images.ctfassets.net/1itkm9rji8jb/6lyyozpxIzbB1ESrcbFC1v/27d14aac52adc9aaada713d7b48f7b22/ico-stream.svg"
                                                                                alt=""
                                                                            />
                                                                            <span>
                                                                                Live
                                                                                Stream
                                                                            </span>
                                                                        </a>
                                                                    </li>
                                                                )}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </>
                                            {/* )} */}
                                        </div>
                                    </div>
                                </div>
                                <div className="openmobilemenu">
                                    <i className="fa fa-bars"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}
