import { createSlice } from "@reduxjs/toolkit";
import { getRequest } from "../../utills/requests";
import { toast } from "react-hot-toast";
import { setWatchData } from "./watchSlice";
import { v4 as uuidv4 } from "uuid";

const initialState = {
    isLoading: false,
    error: null,
    styling: [],
    homePageOrder: [],
    universalStyles: {},
    changeTracker: false,
    submitLoader: false,
    isEdit: {},
    widgetsStyles: [],
    teamsData: {},
    songsLibrary: [],
    teamId: "",
    eventsData: {},
    loginModalState: false,
};

const slice = createSlice({
    name: "styling",
    initialState,
    reducers: {
        startLoading(state, action) {
            state.isLoading = action.payload;
        },

        stopLoading(state, action) {
            state.isLoading = action.payload;
        },

        hasError(state, action) {
            state.error = action.payload;
        },

        setStyling(state, action) {
            state.styling = action.payload;
        },
        updateHomePageOrder(state, action) {
            state.homePageOrder = action.payload;
        },
        updateUniversalStyles(state, action) {
            state.universalStyles = action.payload;
        },
        updateTracker(state, action) {
            state.changeTracker = action.payload;
        },
        submitRequest(state, action) {
            state.submitLoader = action.payload;
        },
        emptyState(state) {
            state.styling = [];
        },
        setEdit(state, action) {
            state.isEdit = action.payload;
        },
        updateStyling(state, action) {
            state.styling = state.styling.map((resource) =>
                resource.id === action.payload.id ? action.payload : resource
            );
        },
        setTeamsData(state, action) {
            state.teamsData = action.payload;
        },
        setSongsLibrary(state, action) {
            state.songsLibrary = action.payload;
        },
        setTeamId(state, action) {
            state.teamId = action.payload;
        },
        setEventsData(state, action) {
            state.eventsData = action.payload;
        },
        setLoginModalState(state, action) {
            state.loginModalState = action.payload;
        },
    },
});

// Reducer
export default slice.reducer;
const actions = slice.actions;

export const {
    updateHomePageOrder,
    updateUniversalStyles,
    updateTracker,
    submitRequest,
    emptyState,
    setEdit,
    setStyling,
    stopLoading,
    startLoading,
    setTeamsData,
    setSongsLibrary,
    setTeamId,
    setEventsData,
    setLoginModalState,
} = actions;

export const getStyling = (id) => async (dispatch) => {
    dispatch(actions.startLoading(true));
    dispatch(submitRequest(true));
    try {
        const res = await getRequest({
            endpoint: `/react-front?page_id=${id}`,
        });
        let tempData = { ...res };
        // GROUPS
        const groupWidget = res?.homepageOrder?.find(
            (item) => item.widgetInfo?.name === "group-list"
        );
        if (groupWidget !== undefined && res?.groups && res.groups.length > 0) {
            const newOrder = res?.homepageOrder.filter(
                (item) => item.widgetInfo?.name !== "group-list"
            );
            res.groups.forEach((element, index) => {
                newOrder.push({
                    ...groupWidget,
                    widget_id: uuidv4(),
                    group: element,
                    x: index % 2 == 0 ? 0 : 2,
                });
            });
            tempData = { ...tempData, homepageOrder: newOrder };
        }
        // TEAM
        const teamWidget = res?.homepageOrder?.find(
            (item) => item.widgetInfo?.name === "team-list"
        );

        if (teamWidget !== undefined && res?.teams && res.teams.length > 0) {
            const newOrder = res?.homepageOrder.filter(
                (item) => item.widgetInfo?.name !== "team-list"
            );
            res.teams.forEach((element, index) => {
                newOrder.push({
                    ...teamWidget,
                    widget_id: uuidv4(),
                    team: element,
                    x: (index * 2) % 6,
                });
            });
            tempData = { ...tempData, homepageOrder: newOrder };
        }
        // EVENTS
        const eventWidget = res?.homepageOrder?.find(
            (item) => item.widgetInfo?.name === "eventList"
        );
        if (eventWidget !== undefined && res?.series && res.series.length > 0) {
            const newOrder = tempData?.homepageOrder.filter(
                (item) => item.widgetInfo?.name !== "eventList"
            );
            res.series.forEach((element, index) => {
                newOrder.push({
                    ...eventWidget,
                    widget_id: uuidv4(),
                    event: element,
                    x: index % 2 == 0 ? 0 : 2,
                });
            });
            tempData = { ...tempData, homepageOrder: newOrder };
        }
        dispatch(actions.setStyling(tempData));

        dispatch(actions.stopLoading(false));
        dispatch(updateUniversalStyles(res.universal_styles));
        if ((id == 2 || id == 10) && res?.data) {
            dispatch(setWatchData(res.data));
        }

        if ((id == 5 || id == 13) && res?.team_data) {
            dispatch(setTeamsData(res?.team_data));
            if (res?.team_data?.teams && res?.team_data?.teams.length > 0) {
                dispatch(setTeamId(res.team_data.teams[0].id));
            }
            dispatch(setSongsLibrary(res?.songs));
        }
    } catch (err) {
        dispatch(actions.stopLoading());
        toast.error(
            err.response === undefined
                ? "Something went wrong"
                : err.response.data.message,
            { id: "styleErr" }
        );
    } finally {
        dispatch(submitRequest(false));
    }
};
