import React, { useCallback, useEffect, useState } from "react";
import "../../home/styles.css";
import { useSelector } from "react-redux";
import { Send } from "lucide-react";
import broadcast from "../../../assets/svg/broadcast.svg";
import { ReactionBarSelector } from "@charkour/react-reactions";
import ChatItem from "./ChatItem";

const ChatWidget = ({ widgetId, itemStyles }) => {
  const universalStyles = useSelector(
    (state) => state.bodyStyling.universalStyles
  );

  const [border, setBorder] = useState("");
  const [defaultCorners, setDefaultCorners] = useState("true");
  const [isFocused, setFocused] = useState(false);

  useEffect(() => {
    if (itemStyles) {
      setDefaultCorners(itemStyles.defaultCorners);
      setBorder(itemStyles.border);
    }
  }, [itemStyles]);

  useEffect(() => {
    if (universalStyles.corners && itemStyles.defaultCorners == "true") {
      setBorder(universalStyles.corners);
    }
  }, [universalStyles.corners, itemStyles]);

  const messages = [
    { id: 1, name: "Admin", msg: "Hi, how are you?", time: "5 min" },
    { id: 2, name: "Jane doe", msg: "I'm good, what about u?", time: "1 hour" },
    { id: 3, name: "Jack Garet", msg: "ola, como estas?", time: "2 days" },
    { id: 4, name: "Admin", msg: "Hi, how are you?", time: "5 min" },
    { id: 5, name: "Jane doe", msg: "I'm good, what about u?", time: "1 hour" },
    { id: 6, name: "Jack Garet", msg: "ola, como estas?", time: "2 days" },
    {
      id: 1,
      name: "Admin",
      msg: "Hi, how are you? just checking long text how appears",
      time: "5 min",
    },
    { id: 7, name: "Jane doe", msg: "I'm good, what about u?", time: "1 hour" },
    { id: 8, name: "Jack Garet", msg: "ola, como estas?", time: "2 days" },
  ];

  const [visibleReactionId, setVisibleReactionId] = useState(null);

  const handleMouseEnter = (id) => {
    setVisibleReactionId(id);
  };

  const handleMouseLeave = () => {
    setVisibleReactionId(null);
  };

  const ChatComp = React.useCallback(
    ({ item, visibleReactionId, handleMouseEnter, handleMouseLeave }) => {
      return (
        <ChatItem
          item={item}
          visibleReactionId={visibleReactionId}
          handleMouseEnter={handleMouseEnter}
          handleMouseLeave={handleMouseLeave}
        />
      );
    },
    []
  );

  return (
    <div
      className={"chatBox d-flex flex-column"}
      style={{
        borderRadius: border === "round" ? 20 : border === "sharp" ? 0 : null,
        overflow: "hidden",
      }}
    >
      <div className="chat-Section" style={{ overflow: "auto" }}>
        {messages.map((item, i) => {
          return (
            <ChatComp
              key={i}
              item={item}
              visibleReactionId={visibleReactionId}
              handleMouseEnter={handleMouseEnter}
              handleMouseLeave={handleMouseLeave}
            />
          );
        })}
      </div>

      <div
        className={`d-flex align-items-center col-md-12 justify-content-between mb-3 ${
          isFocused ? "input-container" : ""
        }`}
        style={{ backgroundColor: "#212121", padding: 10, borderRadius: 5 }}
      >
        <div className="col-md-8">
          <input
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            className="chat-Input"
            style={{ width: "100%" }}
            placeholder="Type something"
          />
        </div>
        <div className="col-md-4 d-flex  justify-content-end gap-2">
          <img className="broadcast" src={broadcast} alt="Logo" />
          <Send color="#3578ff" />
        </div>
      </div>
    </div>
  );
};

export default ChatWidget;
