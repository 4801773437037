import React, { useState, useEffect } from "react";
import "../../home/styles.css";
import Assets from "../../../assets/images";
import { useSelector } from "react-redux";
// Calendar
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import listPlugin from "@fullcalendar/list";
import moment from "moment";
//
import { Modal } from "react-bootstrap";
import { Circles } from "react-loader-spinner";
import { CircleAlert, X } from "lucide-react";
import { getRequest } from "../../../utills/requests";
import toast from "react-hot-toast";
import Loader from "../../../components/Loader";

const CalenderWidget = ({ itemStyles, height, width, widgetId }) => {
  const [border, setBorder] = useState("");
  const [defaultCorners, setDefaultCorners] = useState("true");
  const user = useSelector((state) => state.login.userData);

  const [modal, showModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [calenderLoader, setCalenderLoader] = useState(false);

  const [reason, setReason] = useState("");
  const [date, setDate] = useState("");
  const [cancelLoader, setCancelLoader] = useState(false);
  const [cancelModal, showCancelModal] = useState(false);

  const [blockedDates, setBlockedDates] = useState([]);
  const [events, setEvents] = useState([]);

  const universalStyles = useSelector(
    (state) => state.bodyStyling.universalStyles
  );

  useEffect(() => {
    setTimeout(() => {
      getCalendarData();
    }, 200);
  }, []);

  const getCalendarData = async () => {
    setCalenderLoader(true);
    try {
      const response = await getRequest({
        endpoint: `/calendarData?user_id=${user.id}`,
      });
      const { blocked_dates = [], events = [] } = response || {};
      if (blocked_dates.length > 0) {
        setBlockedDates(blocked_dates);
      }
      if (events.length > 0) {
        manageEvents(events);
      }
    } catch (error) {
      console.log("ERR>", error);
    } finally {
      setCalenderLoader(false);
    }
  };

  const manageEvents = (events) => {
    const data = events.map((item) => {
      return {
        title: item.event_name,
        date: item.event_date,
        color:
          item.event_date == moment(new Date()).format("YYYY-MM-DD")
            ? "#3478ff"
            : "",
        allDay: true,
      };
    });
    data && setEvents(data);
  };

  useEffect(() => {
    if (universalStyles.corners && defaultCorners == "true") {
      setBorder(universalStyles.corners);
    }
  }, [universalStyles.corners, universalStyles.font]);

  const CommingSoon = () => {
    return (
      <>
        <div className="d-flex" style={{ height: "100%" }}>
          <img
            alt="calender Image"
            src={Assets.Calender}
            style={{
              width: "100%",
            }}
          />
        </div>
        <div
          className="d-flex align-items-center justify-content-center"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
          }}
        >
          <p style={{ fontWeight: "bold" }}>Calendar coming soon!</p>
        </div>
      </>
    );
  };

  const handleDateClick = (arg) => {
    if (blockedDates.find((item) => item.block_date == arg.dateStr)) {
      setDate(arg.dateStr);
      showCancelModal(true);
    } else {
      setDate(arg.dateStr);
      setTimeout(() => {
        showModal(true);
      }, 100);
    }
  };

  const addBlockoutDate = async () => {
    if (reason.trim().length == 0) {
      toast.error("Please enter reason for blockout", { duration: 2000 });
      return;
    }
    setLoader(true);
    try {
      const response = await getRequest({
        endpoint: `/blockDate?user_id=${user.id}&blockDate=${date}&reason=${reason}`,
      });
      console.log("RES>", response);
      if (response.msg) {
        toast.success(response.msg, { duration: 2000 });
        getCalendarData();
        showModal(false);
        setReason("");
      }
    } catch (error) {
      console.log("ERR>", error);
    } finally {
      setLoader(false);
    }
  };

  const removeBlockoutDate = async () => {
    setCancelLoader(true);
    try {
      const response = await getRequest({
        endpoint: `/blockDate?user_id=${user.id}&blockDate=${date}`,
      });
      console.log("RES>", response);
      if (response.msg) {
        toast.success(response.msg, { duration: 2000 });
        getCalendarData();
        showCancelModal(false);
      }
    } catch (error) {
      console.log("ERR>", error);
    } finally {
      setCancelLoader(false);
    }
  };

  const dayCellContent = (e) => {
    const isBlocked = blockedDates.find(
      (item) => item.block_date == moment(e.date).format("YYYY-MM-DD")
    );
    return (
      <div
        className="day-number"
        style={{
          backgroundColor: isBlocked ? "#ff3434" : "transparent",
        }}
      >
        <h5>{e.dayNumberText}</h5>
      </div>
    );
  };

  return (
    <div
      className={`calendar`}
      style={{
        borderRadius: border === "round" ? 20 : border === "sharp" ? 0 : null,
      }}
    >
      {/* <CommingSoon/> */}
      <Loader loading={calenderLoader} />
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin, listPlugin]}
        initialView="dayGridMonth"
        events={events}
        // events={[
        //   {
        //     title: "Morning Meeting",
        //     start: "2024-08-01 12:30 PM",
        //     extendedProps: {
        //       department: "Sales",
        //       description: "Discuss Q3 targets",
        //       priority: "high",
        //     },
        //   },
        //   {
        //     title: "Lunch Break",
        //     start: "2024-08-01",
        //     extendedProps: {
        //       department: "General",
        //       description: "Relax and recharge",
        //       priority: "low",
        //     },
        //   },
        //   {
        //     title: "Project Discussion",
        //     start: "2024-08-01",
        //     color: "#3478ff",
        //     backgroundColor: "#3478ff",
        //     extendedProps: {
        //       department: "Development",
        //       description: "Discuss the new feature rollout",
        //       priority: "medium",
        //     },
        //   },
        //   {
        //     title: "Lunch Break",
        //     start: "2024-08-01T12:00:00",
        //     extendedProps: {
        //       department: "General",
        //       description: "Relax and recharge",
        //       priority: "low",
        //     },
        //   },
        //   {
        //     title: "Project Discussion",
        //     start: "2024-08-01T15:00:00",
        //     extendedProps: {
        //       department: "Development",
        //       description: "Discuss the new feature rollout",
        //       priority: "medium",
        //     },
        //   },
        // ]}
        headerToolbar={{
          left: "dayGridMonth,listWeek",
          center: "title",
          right: "prev today next", // Add list views in the toolbar
        }}
        buttonText={{
          listDay: "Day",
          listWeek: "Week",
          listMonth: "Month",
        }}
        eventDidMount={(info) => {
          if (info.event._def.allDay) {
            const timeElement = info.el.querySelector('.fc-list-event-time');
            if (timeElement) {
              timeElement.style.display = 'none'; // Hide the "all-day" text
            }
          }
        }}
        dayCellContent={dayCellContent}
        // eventContent={(eventInfo) => {
        //   return (
        //     <div>
        //       <strong>{eventInfo.event.title}</strong>
        //       <em>{eventInfo.event._def.extendedProps.department}</em>
        //     </div>
        //   );
        // }}
        dayHeaders={true} //to hide/show day headers
        eventColor="#383939"
        dateClick={handleDateClick}
        dayMaxEventRows={3}
        eventTimeFormat={{
          hour: "2-digit",
          minute: "2-digit",
          meridiem: "narrow",
        }}
        displayEventTime={true}
      />

      <Modal
        // size={"sm"}
        centered
        show={modal}
        onHide={() => showModal(false)}
        // backdrop="static"
      >
        <Modal.Body style={{ padding: 0 }} className="cancel-modal col-md-12">
          <div
            className="confirmation-dialog"
            style={{
              backgroundColor: "#1F2020",
              borderRadius: 10,
              padding: 20,
            }}
          >
            <div className="d-flex justify-content-between">
              <h4 style={{ fontSize: 20, color: "#fff" }}>
                Add Block Out Date
              </h4>
              <X
                onClick={() => showModal(false)}
                size={20}
                fill="#8F9090"
                color="#8F9090"
                strokeWidth={5}
                style={{ cursor: "pointer" }}
              />
            </div>
            <div className="d-flex flex-column mt-4">
              <div className="d-flex col-md-12 inputCont align-items-center">
                <input
                  className="givingInput2"
                  value={date}
                  type="text"
                  disabled
                  placeholder="Blockout Date"
                  style={{ padding: 10, width: "90%" }}
                />
              </div>
              <div className="d-flex col-md-12 inputCont align-items-center ">
                <input
                  className="givingInput2"
                  value={reason}
                  type="text"
                  onChange={(e) => setReason(e.target.value)}
                  placeholder="Enter Reason for Blockout"
                  style={{ padding: 10, width: "90%" }}
                />
              </div>
            </div>

            <div className="d-flex my-3" />
            <div
              className="d-flex align-items-center justify-content-end"
              style={{ columnGap: 4 }}
            >
              <button
                disabled={loader}
                onClick={addBlockoutDate}
                style={{
                  backgroundColor: "#28a745",
                  color: "#fff",
                  padding: 6,
                  paddingInline: 10,
                  borderRadius: 5,
                }}
              >
                {loader ? (
                  <Circles
                    height="20"
                    width="20"
                    color="#fff"
                    ariaLabel="circles-loading"
                    wrapperClass=""
                    visible={true}
                  />
                ) : (
                  "Add"
                )}
              </button>

              <button
                onClick={() => showModal(false)}
                style={{
                  backgroundColor: "#dc3545",
                  color: "#fff",
                  padding: 6,
                  borderRadius: 5,
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Cancel Modal */}
      <Modal
        // size={""}
        centered
        show={cancelModal}
        onHide={() => showCancelModal(false)}
        backdrop="static"
      >
        <Modal.Body className="cancel-modal col-md-12">
          <div className="d-flex col-md-12 flex-column align-items-center justify-content-center">
            <CircleAlert size={50} color="#f8bb86" />
            <h3 style={{ color: "#000", fontWeight: "600", marginTop: 20 }}>
              You want to unblock this date?
            </h3>
            {/* <h6 style={{ color: "#000", marginTop: 10 }}>
              Once cancelled, you will not be able to recover this subscription?
            </h6> */}
          </div>
          <div className="d-flex col-md-12 align-items-center justify-content-center gap-3 mt-3">
            <input
              style={{
                borderRadius: 5,
                backgroundColor: "#efefef",
                color: "red",
                padding: 10,
                width: "30%",
              }}
              type="button"
              value={"No"}
              onClick={() => showCancelModal(false)}
            />
            <button
              className="d-flex align-items-center justify-content-center"
              onClick={removeBlockoutDate}
              style={{
                borderRadius: 5,
                backgroundColor: "#e64942",
                padding: 10,
                color: "#fff",
                paddingInline: 20,
                width: "30%",
              }}
            >
              {cancelLoader ? (
                <Circles
                  height="20"
                  width="20"
                  color="#fff"
                  ariaLabel="circles-loading"
                  wrapperClass=""
                  visible={true}
                />
              ) : (
                "Yes"
              )}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CalenderWidget;
