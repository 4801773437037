import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    goalDropDown: false,
    donateDropDown: false,
    givingData: {},
    guestGivingData: {},
};

const slice = createSlice({
    name: "giving",
    initialState,
    reducers: {
        setDonateDropDown(state, action) {
            state.donateDropDown = action.payload;
        },
        setGoalDropDown(state, action) {
            state.goalDropDown = action.payload;
        },
        setGivingData(state, action) {
            state.givingData = action.payload;
        },
        setGuestGivingData(state, action) {
            state.guestGivingData = action.payload;
        },
    },
});

// Reducer
export default slice.reducer;
const actions = slice.actions;

export const {
    setDonateDropDown,
    setGoalDropDown,
    setGivingData,
    setGuestGivingData,
} = actions;
