import React from "react";
import ReactEcharts from "echarts-for-react";

const LineChart = ({ data }) => {
    const option = {
        title: {
            text: "YOUR GIVING HISTORY",
            textStyle: {
                color: "#ffffff", // Change text color inside the tooltip
                fontSize: window.innerWidth > 468 ? 24 : 18,
            },
            left: "2%",
        },
        tooltip: {
            show: true,
            trigger: "axis",
            backgroundColor: "#000",
            textStyle: {
                color: "#ffffff", // Change text color inside the tooltip
            },
        },
        legend: {
            show: false, // Enable the legend
            data: ["Payment"], // Legend item names should match the 'name' in series
            left: "center", // Positioning of the legend (can be 'left', 'right', 'center')
            top: "bottom", // Vertical positioning (can be 'top', 'bottom')
            textStyle: {
                color: "#fff", // Text color for the legend
            },
            padding: [0, 0, 0, 0],
        },
        grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
        },
        xAxis: {
            type: "category",
            boundaryGap: false,
            data: [
                "JAN",
                "FEB",
                "MAR",
                "APR",
                "MAY",
                "JUN",
                "JUL",
                "AUG",
                "SEP",
                "OCT",
                "NOV",
                "DEC",
            ],
            axisLabel: {
                color: "#fff", // Change X-axis label color
                fontSize: 16,
            },
        },
        yAxis: {
            type: "value",
            splitLine: {
                show: true, // Ensure grid lines are displayed
                lineStyle: {
                    color: "#ccc", // Color of the grid lines
                    type: "dashed", // Line style, can be 'solid', 'dashed', or 'dotted'
                },
            },
            axisLabel: {
                color: "#fff", // Change X-axis label color
                fontSize: 16,
                formatter: (value) => {
                    // Format values greater than or equal to 1000 into 'k' suffix format
                    if (value >= 1000) {
                        return `${value / 1000}k`;
                    }
                    return value;
                },
            },
        },
        series: [
            {
                name: "Payment",
                type: "line",
                smooth: true,
                // data: [30, 120, 150, 80, 230, 120, 200, 150, 80, 70, 110, 130],
                data,
                itemStyle: {
                    color: "#fff",
                },
                lineStyle: {
                    width: 3,
                },
                areaStyle: {
                    opacity: 0.1,
                },
                symbolSize: 10, // Increase the size of the intersecting dot
                symbol: "circle",
            },
        ],
    };

    return (
        <ReactEcharts
            option={option}
            style={{ height: "100%", width: "100%" }}
        />
    );
};

export default LineChart;
