import React, { useState, useEffect } from "react";
import "../../home/styles.css";
import { useDispatch, useSelector } from "react-redux";

const NotesWidget = ({ widgetId, itemStyles }) => {
  const universalStyles = useSelector(
    (state) => state.bodyStyling.universalStyles
  );

  const dispatch = useDispatch();
  const [border, setBorder] = useState("");
  const [defaultCorners, setDefaultCorners] = useState("true");

  useEffect(() => {
    if (itemStyles) {
      setDefaultCorners(itemStyles.defaultCorners);
      setBorder(itemStyles.border);
    }
  }, [itemStyles]);

  useEffect(() => {
    if (universalStyles.corners && itemStyles.defaultCorners == "true") {
      setBorder(universalStyles.corners);
    }
  }, [universalStyles.corners, itemStyles]);


  return (
    <div
      className={`chatBox`}
      style={{
        borderRadius: border === "round" ? 20 : border === "sharp" ? 0 : null,
        padding:25
      }}
    >
      <textarea className="chat-Input" style={{minHeight:'80%'}} placeholder="Take notes..." />
      <div
        className="align-items-center justify-content-center"
        style={{
          backgroundColor: "#3478ff",
          alignSelf: "flex-end",
          borderRadius: 6,
        }}
      >
        <p
          style={{
            margin: 0,
            color: "#ffffff",
            paddingInline: 20,
            paddingBlock: 10,
          }}
        >
          Watch Now
        </p>
      </div>
    </div>
  );
};

export default NotesWidget;
