import React, { useState, useEffect, useCallback, useContext } from "react";
import "../home/styles.css";
import Assets from "../../assets/images";
import { useDispatch, useSelector } from "react-redux";
import {
    setCurrentSelectedEvent,
    setEventId,
    setEventNoteText,
    setSelectedEventDeatails,
    setSeriesId,
} from "../../store/slices/watchSlice";
import redDot from "../../assets/redDot6.gif";
import { DarkModeContext } from "../../context/DarkModeContext";

const Sermon = ({ borderRadius, width }) => {
    const data = useSelector((state) => state.watch.sermonsData);

    const dispatch = useDispatch();
    const currentSelectedEventData = useSelector(
        (state) => state.watch.currentSelectedEventData
    );
    const selectedSeries = useSelector((state) => state.watch.seriesId);
    const eventId = useSelector((state) => state.watch.eventId);
    const { darkMode } = useContext(DarkModeContext);

    const [events, setEvents] = useState([]);

    const liveEvent = useSelector((state) => state.watch.liveEvent);

    const selectedEvent = useSelector(
        (state) => state.watch.selectedEventDetails
    );

    useEffect(() => {
        if (data && selectedSeries) {
            const series = data?.series?.find(
                (series) => series.series_id == selectedSeries
            );
            if (series && series.events) {
                setEvents(series.events);
            }
        }
    }, [selectedSeries, data]);

    const SermonItem = useCallback(({ item, liveEvent, eventId }) => {
        return (
            <div
                key={item.event_id}
                className="d-flex align-items-center"
                style={{
                    backgroundColor:
                        item.event_id == eventId
                            ? "#007bff"
                            : darkMode
                            ? "#232323"
                            : "#d8d8d8",
                    borderRadius: 6,
                    padding: 4,
                    columnGap: 10,
                    cursor: "pointer",
                    position: "relative",
                    // flexWrap: "wrap",
                }}
                onClick={() => {
                    dispatch(setEventId(item.event_id));
                    if (item?.notes?.[0]?.note_text) {
                        dispatch(setEventNoteText(item.notes[0].note_text));
                    } else {
                        dispatch(setEventNoteText(""));
                    }
                }}
            >
                <img
                    src={`${process.env.REACT_APP_BASE_URL}${item?.banner_1}`}
                    style={{ height: 50, width: 60, borderRadius: 6 }}
                />
                <p
                    style={{
                        color:
                            item.event_id == eventId && !darkMode
                                ? "#fff"
                                : darkMode
                                ? "#fff"
                                : "#000",
                        fontSize: 12,
                        margin: 0,
                        flexWrap: "wrap",
                    }}
                >
                    {item.event_name}
                </p>
                {liveEvent?.event_id == item.event_id && (
                    <img
                        style={{
                            position: "absolute",
                            height: 16,
                            top: 0,
                            right: 0,
                        }}
                        src={redDot}
                    />
                )}
            </div>
        );
    }, []);

    const SeriesItem = useCallback(({ item, selectedSeries, liveEvent }) => {
        return (
            <div
                onClick={() => {
                    dispatch(setSeriesId(item.series_id));
                }}
                key={item.series_id}
                className="d-flex align-items-center"
                style={{
                    backgroundColor:
                        item.series_id == selectedSeries
                            ? "#007bff"
                            : darkMode
                            ? "#232323"
                            : "#d8d8d8",
                    borderRadius: 6,
                    padding: 4,
                    columnGap: 10,
                    cursor: "pointer",
                    position: "relative",
                }}
            >
                <img
                    src={`${process.env.REACT_APP_BASE_URL}${item?.banner_1}`}
                    style={{ height: 50, width: 60, borderRadius: 6 }}
                />
                <p
                    style={{
                        color:
                            item.series_id == selectedSeries && !darkMode
                                ? "#fff"
                                : darkMode
                                ? "#fff"
                                : "#000",
                        fontSize: 12,
                        margin: 0,
                        flexWrap: "wrap",
                    }}
                >
                    {item?.series_name}
                </p>
                {liveEvent?.id == item.series_id && (
                    <img
                        style={{
                            position: "absolute",
                            height: 16,
                            top: 0,
                            right: 0,
                        }}
                        src={redDot}
                    />
                )}
            </div>
        );
    }, []);

    return (
        <div
            className={`d-flex mobile-sermon-Cont`}
            style={{
                borderRadius: borderRadius,
                height: "30%",
                width: width,
            }}
        >
            {/*1st  */}
            <div
                className="col-md-6 sermon-wrapper"
                style={{ paddingBottom: 50 }}
            >
                <p className="sermon-heading">Sermons</p>
                <div className="d-flex flex-column sermon-section">
                    {events.map((item) => {
                        return (
                            <SermonItem
                                key={item.event_id}
                                item={item}
                                currentSelectedEventData={
                                    currentSelectedEventData
                                }
                                liveEvent={liveEvent}
                                selectedEvent={selectedEvent}
                                eventId={eventId}
                            />
                        );
                    })}
                </div>
            </div>
            {/* 2nd */}
            <div
                className="col-md-6 sermon-series-wrapper"
                style={{ paddingBottom: 50 }}
            >
                <p className="sermon-heading">Sermons Series</p>
                <div className="d-flex flex-column sermon-series-section">
                    {data &&
                        data?.series?.map((item) => {
                            return (
                                <SeriesItem
                                    key={item.series_id}
                                    item={item}
                                    selectedSeries={selectedSeries}
                                    liveEvent={liveEvent}
                                    selectedEvent={selectedEvent}
                                />
                            );
                        })}
                </div>
            </div>
        </div>
    );
};

export default Sermon;
