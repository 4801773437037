import React, { useState, useEffect } from "react";
import { ReactionBarSelector } from "@charkour/react-reactions";
import { Trash2 } from "lucide-react";
import Like from "../../assets/svg/Like.svg";
import Heart from "../../assets/svg/Heart2.svg";
import XHR from "../../utills/XHR";
import { useSelector } from "react-redux";

const ChatItem = React.memo(
  ({
    item,
    visibleReactionId,
    handleMouseEnter,
    handleMouseLeave,
    deleteMessage,
  }) => {
    const user = useSelector((state) => state.login.userData);
    const liveEvent = useSelector((state) => state.watch.liveEvent);
    const selectedEvent = useSelector(
      (state) => state.watch.selectedEventDetails
    );
    const [selectedEmoji, setSelectedEmoji] = useState(false);

    const emojis = {
      1: "👍",
      2: "❤️",
      3: "🙏",
      4: "🙌",
      5: "🔥",
      6: "💧",
    };

    // const emojis = {
    //   1: <img style={{ height: 16 }} src={Like} />,
    //   2: <img style={{ height: 16 }} src={Heart} />,
    //   3: "🙏",
    //   4: "🙌",
    //   5: "🔥",
    //   6: "💧",
    // };

    const getEmoji = (id) => {
      return emojis[id] ? emojis[id] : "";
    };

    useEffect(() => {
      if (item?.check_user?.length > 0 && item.check_user.includes(user.id)) {
        setSelectedEmoji(true);
      } else {
        setSelectedEmoji(false);
      }
    }, [item]);

    const reactions = [
      {
        label: "Like",
        key: "1",
        node: (
          <div>
            👍
            {/* <img src={Like} /> */}
          </div>
        ),
      },
      {
        label: "Love",
        key: "2",
        node: (
          <div>
            ❤️
            {/* <img src={Heart} /> */}
          </div>
        ),
      },
      {
        label: "Prayer",
        key: "3",
        node: <div>🙏</div>,
      },
      {
        label: "Praise",
        key: "4",
        node: <div>🙌</div>,
      },
      {
        label: "Fire",
        key: "5",
        node: <div>🔥</div>,
      },
      {
        label: "Water",
        key: "6",
        node: <div>💧</div>,
      },
    ];

    const reactApi = (reactionId, msgId) => {
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.api_token}`,
        },
        method: "POST",
        data: {
          reaction_id: reactionId,
          msg_id: msgId,
          user_id: user.id,
        },
      };
      XHR(
        `${process.env.REACT_APP_BASE_URL}/api/v1/send-live-chat-reaction`,
        options
      )
        .then((response) => {
          // console.log('RESP>', response)
        })
        .catch((error) => {
          console.log("ERRRR>", error);
        });
    };

    return (
      <div
        className="mb-2 pb-2"
        style={{
          paddingRight: 6,
          position: "relative",
          borderBottom: "1px solid #2c2c2c",
        }}
        onMouseLeave={handleMouseLeave}
      >
        <div
          className="d-flex justify-content-between mb-3"
          style={{ width: "100%" }}
        >
          <div className="d-flex align-items-center gap-2">
            <p style={{ color: "#3478ff", margin: 0, fontSize: 14 }}>
              {item.user_name}
            </p>
            {item?.deleteStatus !== "deleting" ? (
              (user.role == 1 ||
                (item.user_id == user.id &&
                  liveEvent &&
                  liveEvent?.stream_session_id ===
                    selectedEvent?.stream_session_id)) &&
              item.message_status !== "posting" &&
              item.message_status !== "failed" && (
                <Trash2
                  onClick={() => deleteMessage(item.id)}
                  fill="black"
                  color="red"
                  size={18}
                  style={{ cursor: "pointer" }}
                />
              )
            ) : (
              <span style={{ fontSize: 12, color: "#b30000" }}>
                Deleting...
              </span>
            )}
          </div>
          <p style={{ color: "grey", margin: 0, fontSize: 12 }}>{item.time}</p>
        </div>
        <div
          className="mb-3"
          style={{
            backgroundColor: "#272727",
            padding: 6,
            borderRadius: 10,
            width: "fit-content",
            marginTop: 4,
            cursor: "pointer",
            marginBlock: 4,
          }}
        >
          <p style={{ color: "#ffffff", margin: 0, fontSize: 14 }}>
            {item.description}
          </p>
        </div>
        <div
          style={{
            cursor:
              liveEvent &&
              liveEvent?.stream_session_id === selectedEvent?.stream_session_id
                ? "pointer"
                : "default",
            display: "flex",
            position: "relative",
          }}
        >
          <div
            onMouseEnter={() => {
              if (item?.message_status == "posting") return;
              if (
                liveEvent &&
                liveEvent?.stream_session_id !==
                  selectedEvent?.stream_session_id
              )
                return;
              if (item?.deleteStatus == "deleting") return;
              handleMouseEnter(item.id);
            }}
            style={{
              alignSelf: "flex-start",
            }}
          >
            {item?.message_status == "posting" ||
            item?.message_status == "failed" ? (
              <span style={{ fontSize: 12, color: "rgba(255,255,255,0.8)" }}>
                {item?.message_status == "posting" ? "Posting..." : "Failed!"}
              </span>
            ) : (
              <span style={{ fontSize: 12, color: "rgba(255,255,255,0.8)" }}>
                {/* {!selectedEmoji && "Like"} */}
                {!selectedEmoji && item?.reactions?.length == 0 && (
                  <img src={Like} />
                )}
                <span style={{ letterSpacing: -3, marginRight: 8 }}>
                  {item?.reactions?.length > 0 &&
                    getEmoji(item?.reactions?.[0]?.chat_reaction)}
                  {item?.reactions?.length > 1 &&
                    getEmoji(item?.reactions?.[1]?.chat_reaction)}
                  {item?.reactions?.length > 2 &&
                    getEmoji(item?.reactions?.[2]?.chat_reaction)}
                </span>
                {selectedEmoji && item?.total_reactions == 1
                  ? `You reacted`
                  : item?.total_reactions > 1 && selectedEmoji
                  ? `You and ${item.total_reactions - 1} other`
                  : !selectedEmoji && item.total_reactions > 0
                  ? `${item.total_reactions} ${
                      item.total_reactions == 1 ? "person reacted" : "reactions"
                    }`
                  : null}
              </span>
            )}
          </div>
          {visibleReactionId == item.id && (
            <div style={{ position: "absolute", top: -30, left: "-8px" }}>
              <ReactionBarSelector
                reactions={reactions}
                iconSize={25}
                style={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }}
                onSelect={(e) => {
                  reactApi(e, item.id);
                  handleMouseLeave();
                }}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default ChatItem;
