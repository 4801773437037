import React, { useEffect, useRef, useState } from "react";
import { Table, Tooltip, DatePicker, Dropdown } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import { getRequest } from "../../utills/requests";
import { Circles } from "react-loader-spinner";

const { RangePicker } = DatePicker;

const ViewStatement = () => {
    const [date, setDate] = useState({ start: new Date(), end: new Date() });
    const [tableData, setTableData] = useState([]);
    const [totalPaid, setTotalPaid] = useState(0);
    const [loader, setLoader] = useState(false);
    const [pageData, setPageData] = useState({});
    const [filterApplied, setFilterApplied] = useState(false);
    const [excelLoader, setExcelLoader] = useState(false);

    useEffect(() => {
        getViewStaement("", "", 1);
    }, []);

    const getViewStaement = async (fromDate, toDate, page, filter) => {
        const from = fromDate ? moment(fromDate).format("YYYY-MM-DD") : "";
        const to = toDate ? moment(toDate).format("YYYY-MM-DD") : "";

        try {
            setLoader(true);
            const response = await getRequest({
                endpoint: `/viewGivingStatement?page=${page}&fromDate=${from}&toDate=${to}`,
            });
            if (response?.gifts?.data) {
                mapData(response?.gifts?.data);
                setTotalPaid(response?.totalPay);
                setPageData(response?.gifts);
            }
            filter && setFilterApplied(true);
            console.log("DATA>", response);
        } catch (error) {
            console.log("ERR", error);
        } finally {
            setLoader(false);
        }
    };

    const filterData = () => {
        getViewStaement(date.start, date.end, "", true);
    };

    const mapData = (data) => {
        const updatedData = data.map((item, index) => {
            const type =
                item?.is_manual_payment == 1
                    ? "Mannual"
                    : item?.interval
                    ? item.interval
                    : "One Time";
            return {
                ...item,
                key: index,
                created_at: moment(item?.created_at).format("MM/DD/YYYY"),
                giftType: type,
                amount: `$${item?.amount}`,
            };
        });
        updatedData && setTableData(updatedData);
    };

    // Table columns
    const columns = [
        {
            title: "Date",
            width: "",
            dataIndex: "created_at",
            key: "0",
            // fixed: "left",
            render: (name) => <Tooltip title={name}>{name}</Tooltip>,
        },
        {
            title: "Gift Type",
            dataIndex: "giftType",
            key: "1",
            render: (name) => <Tooltip title={name}>{name}</Tooltip>,
        },
        {
            title: "Payment Method",
            dataIndex: "payment_method",
            key: "2",
            render: (name) => <Tooltip title={name}>{name}</Tooltip>,
        },
        {
            title: "Amount",
            dataIndex: "amount",
            key: "3",
            render: (name) => <Tooltip title={name}>{name}</Tooltip>,
        },
    ];

    const items = [
        {
            label: "Today",
            key: "0",
        },
        {
            label: "Yesterday",
            key: "1",
        },
        {
            label: "Last 7 Days",
            key: "2",
        },
        {
            label: "Last 30 Days",
            key: "3",
        },
        {
            label: "This Month",
            key: "4",
        },
        {
            label: "Last Month",
            key: "5",
        },
        {
            label: "Custom Range",
            key: "6",
        },
    ];

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const calculateDisplayedRecords = () => {
        const start = (currentPage - 1) * pageSize + 1;
        const end = Math.min(currentPage * pageSize, pageData?.total || 0);
        return `${start}-${end}`;
    };

    const handlePageChange = (page, pageSize) => {
        setCurrentPage(page);
        if (filterApplied) {
            getViewStaement(date.start, date.end, page);
        } else {
            getViewStaement("", "", page);
        }
    };

    // const totalPaid = tableData.reduce((sum, record) => sum + record.amount, 0);

    const [pickerState, setPickerState] = useState(false);

    const onChange = (date, dateString) => {
        console.log("DATE>", dateString);
        setDate({
            start: dateString[0],
            end: dateString[1],
        });
        setPickerState(false);
    };

    const onClick = ({ key }) => {
        const today = moment();

        switch (key) {
            case "0": // Today
                setDate({ start: today.toDate(), end: today.toDate() });
                break;
            case "1": // Yesterday
                const yesterday = today.subtract(1, "days");
                setDate({ start: yesterday.toDate(), end: yesterday.toDate() });
                break;
            case "2": // Last 7 days
                const sevenDaysAgo = today.clone().subtract(6, "days");
                setDate({ start: sevenDaysAgo.toDate(), end: today.toDate() });
                break;
            case "3": // Last week
                const thirtyDaysAgo = today.clone().subtract(29, "days");
                setDate({ start: thirtyDaysAgo.toDate(), end: today.toDate() });
                break;
            case "4": // This month
                setDate({
                    start: today.startOf("month").toDate(),
                    end: today.endOf("month").toDate(),
                });
                break;
            case "5": // Last month
                const lastMonthStart = today
                    .subtract(1, "months")
                    .startOf("month");
                const lastMonthEnd = lastMonthStart.clone().endOf("month");
                setDate({
                    start: lastMonthStart.toDate(),
                    end: lastMonthEnd.toDate(),
                });
                break;
            case "6": // Custom
                setPickerState(true);
                break;
            default:
                break;
        }
    };

    const handleClickOutside = (event) => {
        const pickerDropdown = document.querySelector(".ant-picker-panels");
        if (
            // Click outside input
            !pickerDropdown ||
            !pickerDropdown.contains(event.target) // Click outside calendar
        ) {
            setPickerState(false); // Close picker
        }
    };
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside); // Cleanup
        };
    }, []);

    const generateExcel = async () => {
        if (excelLoader) return;
        const from = moment(date.start).format("YYYY-MM-DD");
        const to = moment(date.end).format("YYYY-MM-DD");

        try {
            setExcelLoader(true);
            const endpoint = filterApplied
                ? `${process.env.REACT_APP_BASE_URL}/api/v1/giving-export-react?fromDate=${from}&toDate=${to}`
                : `${process.env.REACT_APP_BASE_URL}/api/v1/giving-export-react?fromDate=&toDate=`;
            const token = localStorage.getItem("accessToken");
            const response = await fetch(endpoint, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            // Set the file name - you might want to get this from the response headers
            a.download = "giving-export.csv";
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Download error:", error);
        } finally {
            setExcelLoader(false);
        }
    };

    return (
        <div className="d-flex container row py-2">
            <div className="d-flex col-md-12 align-items-center justify-content-between flex-wrap">
                <div className="col-md-6">
                    <h2 style={{ color: "#fff", fontWeight: "bold" }}>
                        Giving Statement
                    </h2>
                </div>
                <div className="d-flex col-md-6 justify-content-end">
                    <button
                        onClick={generateExcel}
                        className="excel-generate-button"
                        disabled={excelLoader}
                    >
                        {excelLoader ? (
                            <Circles
                                height="20"
                                width="20"
                                color="#fff"
                                ariaLabel="circles-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                            />
                        ) : (
                            "Generate"
                        )}
                    </button>
                </div>
            </div>

            <div className="d-flex flex-wrap gap-4 my-4">
                <div className="d-flex">
                    <Dropdown
                        menu={{
                            items,
                            selectable: true,
                            defaultSelectedKeys: ["0"],
                            onClick,
                        }}
                        trigger={["click"]}
                        // arrow={true}
                    >
                        <RangePicker
                            onClick={(e) => e.preventDefault()}
                            id={{
                                start: "startInput",
                                end: "endInput",
                            }}
                            allowClear={false}
                            open={pickerState}
                            value={[
                                dayjs(
                                    moment(date.start).format("MMMM DD,YYYY"),
                                    "MMMM DD,YYYY"
                                ),
                                dayjs(
                                    moment(date.end).format("MMMM DD,YYYY"),
                                    "MMMM DD,YYYY"
                                ),
                            ]}
                            onChange={onChange}
                            format={{ format: "MMMM DD,YYYY", type: "mask" }}
                            inputReadOnly
                        />
                    </Dropdown>
                </div>
                <button onClick={filterData} className="excel-generate-button">
                    Apply Filter
                </button>
            </div>
            {/* Table */}
            <div className="py-2">
                <Table
                    style={{ backgroundColor: "#000" }}
                    columns={columns}
                    dataSource={tableData || []}
                    pagination={{
                        position: ["bottomLeft"],
                        current: pageData?.current_page || 1,
                        pageSize: pageSize,
                        total: pageData?.total || 0,
                        onChange: handlePageChange,
                        showSizeChanger: false,
                    }}
                    scroll={{ y: 900, x: 600 }}
                    // bordered
                    rowClassName={(record, index) =>
                        index % 2 === 0 ? "even-row" : "odd-row"
                    }
                    rowHoverable={false}
                    loading={loader}
                />
            </div>
            <div
                className="d-flex flex-column mt-2 mb-4"
                style={{ columnGap: 10 }}
            >
                <p>
                    Displaying {tableData.length} of {pageData?.total} record(s){" "}
                </p>
                <h4>Total Paid:</h4>
                <h3 style={{ color: "#007bff" }}>${totalPaid}</h3>
            </div>
        </div>
    );
};

export default ViewStatement;
