export const getGradient = (gradient) => {
    if(gradient.style){
        try {
            const bgstyle = JSON.parse(gradient);
            if (bgstyle.style) {
              return bgstyle.style;
            }
          } catch (error) {
            return gradient.style;
          }
    }
    return gradient;
  };