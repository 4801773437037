import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Circles } from "react-loader-spinner";
import { removeStartingSlash as removeSlash } from "../../utills/RemoveSlash";
import placeholder from "../../assets/placeholder.png";
import moment from "moment";
import toast from "react-hot-toast";
import Skeleton from "react-loading-skeleton";
import { database } from "../../firebase/firebaseConfig.js";
import { ref, onChildAdded, off, onValue } from "firebase/database";
import { MoveLeft } from "lucide-react";
import XHR from "../../utills/XHR.js";
import LikeBar from "../../components/Reactions/LikeBar.js";

export default function OtherUserProfile() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [userData, setUserData] = useState(false);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [requestState, setRequestState] = useState(0);

  const user = useSelector((state) => state.login.userData);

  // const hostname = "redefine.church";
const hostname=process.env.REACT_APP_BASE_URL

  useEffect(() => {
    if (location.state) {
      getUserData(location.state);
    }
  }, [location.state]);

  useEffect(() => {
    if (userData) {
      setRequestState(userData?.user_detail?.is_request_sent);
    }
  }, [userData]);

  let newItems = false;

  useEffect(() => {
    // add posts comment listener
    const PostCommentRef = ref(database, "PostCommentAdded");
    onChildAdded(PostCommentRef, handlePostCommment);
    // add posts listener
    const PostReactionChangedRef = ref(database, "PostReactionChanged");
    onChildAdded(PostReactionChangedRef, handlePostReaction);

    // handle initial items
    // onValue(addPostRef, handleInitialItems)
    onValue(PostReactionChangedRef, handleInitialItems);
  }, []);

  const handleInitialItems = () => {
    newItems = true;
  };

  const handlePostCommment = (snapshot) => {
    if (!newItems) return;
    if (snapshot.exists()) {
      const e = snapshot.val();
      console.log("Comment added>", e);
      if (e.is_reply == 0) {
        setUserData((prev) => {
          const index = prev?.posts?.findIndex((item) => item.id == e.post_id);
          if (index !== -1) {
            const newArray = [...prev?.posts];
            const commentCount = newArray[index]?.comment_count + 1;
            newArray[index] = {
              ...newArray[index],
              comment_count: commentCount,
            };
            const updatedData = { ...prev, posts: newArray };
            return updatedData;
          }
          return prev;
        });
      }
    }
  };

  const handlePostReaction = (snapshot) => {
    if (!newItems) return;
    const loggedinUserId = user.id;
    if (snapshot.exists()) {
      console.log("Post reaction", snapshot.val());
      const e = snapshot.val();
      if (e) {
        const { postId, userId, action } = e;

        setUserData((prev) => {
          const postIndex = prev?.posts?.findIndex(
            (item) => item?.id == postId
          );

          const postObj = prev?.posts?.[postIndex];
          if (postIndex !== -1) {
            if (action === "deleted" && postObj?.reaction_count > 0) {
              const updatedObj = {
                ...postObj,
                reaction_count: --postObj.reaction_count,
                is_reacted:
                  userId == loggedinUserId ? false : postObj.is_reacted,
              };

              const updatePostsArray = [...prev.posts];
              updatePostsArray[postIndex] = updatedObj;

              const tempData = { ...prev, posts: updatePostsArray };
              return tempData;
            } else if (action === "added") {
              const updatedObj = {
                ...postObj,
                reaction_count: ++postObj.reaction_count,
                is_reacted:
                  userId == loggedinUserId ? true : postObj.is_reacted,
              };

              const updatePostsArray = [...prev.posts];
              updatePostsArray[postIndex] = updatedObj;

              const tempData = { ...prev, posts: updatePostsArray };
              return tempData;
            }
          }
          return prev;
        });
      }
    }
  };

  const getUserData = (userId) => {
    // uncomment this
    try {
      const options = {
        headers: {
          "Content-Type": "multipart-formdata",
          Authorization: `Bearer ${user.api_token}`,
        },
        method: "GET",
      };
      setLoader(true);
      XHR(
        `${hostname}/api/v1/getUserProfile?user_id=${userId}`,
        options
      )
        .then((resp) => {
          // console.log("RESP>>", resp?.data);
          if (resp.data.success) {
            setUserData(resp.data);
          }
        })
        .catch((e) => {
          console.log("ERR>", e);
        })
        .finally(() => {
          setLoader(false);
        });
    } catch (error) {
      console.log("ERR>", error);
    }
  };

  const handleConnectionRequest = (id, state) => {
    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.api_token}`,
      },
      method: "POST",
      data: {
        sender_id: user.id,
        reciever_id: id,
        action: state,
      },
    };
    XHR(`${hostname}/api/v1/sendConnectionRequest`, options)
      .then((response) => {
        if (response.data.success) {
          setRequestState(state === "cancel" ? 0 : 1);
          toast.success(response.data.message);
        }
        console.log("RESP>", response);
      })
      .catch((error) => {
        console.log("ERRRR>", error);
      });
  };

  return (
    // <section className="profilemain1">
      <div className="container">
        <div className="row">
          <div
            onClick={() => {
              navigate(-1);
            }}
            className="col-md-2"
            style={{ cursor: "pointer" }}
          >
            <MoveLeft
              style={{ marginTop: 30 }}
              size={30}
              color="#ffffff"
              fill="#ffffff"
            />
          </div>
          <div className="col-md-8">
            <div className="newpromainwrap">
              <div className="newpromaininner">
                <div className="profilebannermain mt-4">
                  <div className="bannerProfilephoto">
                    {loader ? (
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ overflow: "hidden", borderRadius: 10 }}
                      >
                        <Skeleton
                          baseColor="#716e6e"
                          height={250}
                          width={2560}
                        />
                      </div>
                    ) : (
                      <img
                        src={
                          userData?.user_detail?.banner_pic !== null
                            ? `${hostname}/${removeSlash(
                                userData?.user_detail?.banner_pic
                              )}`
                            : "https://images.ctfassets.net/1itkm9rji8jb/4B4goqdaK0JYabBZnu40Ir/68781d15f9fe8f564864a6b6a4d3116e/OXTE6R98U7.jpg"
                        }
                        alt=""
                      />
                    )}
                  </div>
                  <div className="profileimageEditprofilemain">
                    <div className="prfileimageconnection">
                      <div className="profileimage">
                        {loader ? (
                          <Skeleton
                            circle
                            baseColor="#716e6e"
                            height={140}
                            width={140}
                          />
                        ) : (
                          <img
                            className="profilePic"
                            src={
                              userData?.user_detail?.profile_pic !== null
                                ? `${hostname}/${removeSlash(
                                    userData?.user_detail?.profile_pic
                                  )}`
                                : placeholder
                            }
                            alt=""
                          />
                        )}
                      </div>
                      <div className="nameconnection">
                        {loader ? (
                          <>
                            <Skeleton
                              baseColor="#716e6e"
                              height={15}
                              width={250}
                              style={{ marginTop: 10 }}
                            />
                            <Skeleton
                              baseColor="#716e6e"
                              height={10}
                              width={200}
                            />
                          </>
                        ) : (
                          <>
                            <h3 style={{ fontSize: 20 ,fontWeight:'500',lineHeight:1.2}}>
                              {userData?.user_detail?.username}
                            </h3>
                            <h5 style={{ fontSize: 16 ,fontWeight:'500',lineHeight:1.2}}>
                              {userData?.connection_detail?.length} Connections
                            </h5>
                          </>
                        )}
                      </div>
                    </div>
                    {loader ? (
                      <Skeleton
                        baseColor="#716e6e"
                        height={50}
                        width={170}
                        style={{ marginTop: 20 }}
                      />
                    ) : (
                      !userData?.user_detail?.is_connected && (
                        <div
                          onClick={() => {
                            handleConnectionRequest(
                              userData?.user_detail?.id,
                              requestState ? "cancel" : "send"
                            );
                          }}
                          className="profileeditmain"
                        >
                          <p
                            className="hasnewbtn"
                            style={{
                              backgroundColor: requestState
                                ? "green"
                                : "#3478FF",
                              fontSize: 18,
                            }}
                          >
                            {" "}
                            {requestState
                              ? "Request sent"
                              : "Send connection"}{" "}
                          </p>
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className="profilesecionmainwrwap row ">
                  <div className="prosecleft col-md-4">
                    <div className="aboutbox hasboxbg">
                      {loader ? (
                        <>
                          <Skeleton
                            baseColor="#716e6e"
                            height={10}
                            width={200}
                            style={{ marginTop: 10 }}
                          />
                          <Skeleton
                            baseColor="#716e6e"
                            height={10}
                            width={150}
                          />
                          <Skeleton
                            baseColor="#716e6e"
                            height={10}
                            width={200}
                          />
                          <Skeleton
                            baseColor="#716e6e"
                            height={10}
                            width={150}
                          />
                          <Skeleton
                            baseColor="#716e6e"
                            height={10}
                            width={200}
                          />
                          <Skeleton
                            baseColor="#716e6e"
                            height={10}
                            width={150}
                          />
                          <Skeleton
                            baseColor="#716e6e"
                            height={10}
                            width={200}
                            style={{ marginBottom: 10 }}
                          />
                        </>
                      ) : (
                        <>
                          <div className="titleaboutLInk">
                            <h5>About</h5>
                          </div>
                          <div className="aboutifo">
                            <ul>
                              <li>
                                <span>Position:</span>{" "}
                                {
                                  userData?.user_detail?.position_name
                                    ?.position_name
                                }
                              </li>
                              <li>
                                <span>Hometown:</span>
                                {userData?.user_detail?.city}
                              </li>
                              <li>
                                <span>Current City:</span>
                                {userData?.user_detail?.city}
                              </li>
                              <li>
                                <span>Status:</span>
                                {userData?.user_detail?.marital_status}
                              </li>
                            </ul>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="prosecright col-md-8">
                    {loader ? (
                      <div className="mindUploadPost hasboxbg">
                        <div className="uoloadtextmain">
                          <div className="uoloadtext">
                            <div className="profileimg">
                              <Skeleton
                                baseColor="#716e6e"
                                circle
                                height={50}
                                width={50}
                              />
                            </div>
                            <div className="profileText">
                              <Skeleton
                                baseColor="#716e6e"
                                height={10}
                                width={200}
                                style={{ marginTop: 10 }}
                              />
                              <Skeleton
                                baseColor="#716e6e"
                                height={10}
                                width={150}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="postContent">
                          <Skeleton
                            baseColor="#716e6e"
                            height={10}
                            width={200}
                            style={{ marginTop: 10 }}
                          />
                          <Skeleton
                            baseColor="#716e6e"
                            height={10}
                            width={300}
                          />
                          <Skeleton
                            baseColor="#716e6e"
                            height={10}
                            width={400}
                          />
                          <div
                            className="d-flex align-items-center justify-content-center"
                            style={{ overflow: "hidden", borderRadius: 10 }}
                          >
                            <Skeleton
                              baseColor="#716e6e"
                              height={300}
                              width={1000}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      userData &&
                      userData?.posts.map((item) => {
                        return (
                          <div
                            key={item.id}
                            className="mindUploadPost hasboxbg"
                          >
                            <div className="uoloadtextmain">
                              <div className="uoloadtext">
                                <div className="profileimg">
                                  <img
                                    src={`${hostname}/${removeSlash(
                                      item?.profile_pic
                                    )}`}
                                    alt=""
                                  />
                                </div>
                                <div className="profileText">
                                  <h4>{item.username}</h4>
                                  <span
                                    style={{ fontSize: 10 }}
                                    className="time mt-2"
                                  >
                                    {moment(item.created_at).format(
                                      "MMM DD, YYYY"
                                    )}
                                  </span>
                                </div>
                                <span className="tag">{item.post_type}</span>
                              </div>
                            </div>
                            <a
                              onClick={() => {
                                navigate("/post", { state: item.id });
                              }}
                            >
                              <div className="postContent">
                                <p className="mb-2">{item.post_content}</p>

                                {item.post_img && (
                                  <div className="d-flex align-items-center justify-content-center">
                                    <img
                                      style={{
                                        maxWidth: "100%",
                                        height: "auto",
                                      }}
                                      src={`${hostname}/${removeSlash(
                                        item.post_img
                                      )}`}
                                    />
                                  </div>
                                )}
                              </div>
                            </a>
                            <LikeBar
                              otherUserProfile={true}
                              postData={item}
                              edit={false}
                            />
                          </div>
                        );
                      })
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    // </section>
  );
}
