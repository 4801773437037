import React from "react";
import Assets from "../../assets/images";

const AboutUs = () => {
  const data = [
    {
      id: 1,
      name: "Our<br/>Beliefs",
      img: Assets.OurBeliefs,
      route: "/our-beliefs",
    },
    { id: 2, name: "Our<br/>Code", img: Assets.OurCode, route: "/our-code" },
    {
      id: 3,
      name: "Our<br/>Leadership",
      img: Assets.Leadership,
      route: "/our-leadership",
    },
  ];

  return (
    <div className="about-us">
      {data.map((item) => {
        return (
          <a href={item.route} style={{textDecoration:'none'}} >
            <div key={item.id} className="about-content">
              <img src={item.img} />
              <h3 dangerouslySetInnerHTML={{ __html: item.name }}></h3>
            </div>
          </a>
        );
      })}
    </div>
  );
};

export default AboutUs;
