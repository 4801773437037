import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    isLoading: false,
    timeLineData: [],
    error: false,
    postLoader: false,
}

const slice = createSlice({
    name: 'connect',
    initialState,
    reducers: {
        setLoading(state, action) {
            state.isLoading = action.payload
        },
        hasError(state, action) {
            state.error = action.payload
        },
        setConnectData(state, action) {
            state.timeLineData = action.payload
        },
        requestingPost(state, action) {
            state.postLoader = action.payload
        },
    }
})

// Reducer
export default slice.reducer
//Actions
export const {
    setLoading,
    hasError,
    setConnectData,
    requestingPost,
} = slice.actions
