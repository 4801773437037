import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Circles } from "react-loader-spinner";
import "./styles.css";
//Assets
// import Assets from "../../assets/images";
//Actions
import { getStyling } from "../../store/slices/bodyStyling";
import {
    hasError,
    loginSuccess,
    requestLogin,
    setUserProfile,
} from "../../store/slices//loginSlice";
//Components
import SortableGrid from "../../components/GridLayout/SortableGrid";
import LoginModal from "../../components/Modal/LoginModal";
import FloatingLogin from "../../components/FloatingLogin/index";
import toast from "react-hot-toast";
import { postRequest } from "../../utills/requests";
import { useNavigate } from "react-router-dom";

function Home(props) {
    const { darkMode } = props;
    const dispatch = useDispatch();

    const data = useSelector((state) => state.bodyStyling.styling);
    const loading = useSelector((state) => state.bodyStyling.isLoading);
    const loginLoader = useSelector((state) => state.login.isLoading);
    const user = useSelector((state) => state.login.userData);

    const navigate = useNavigate();

    useEffect(() => {
        if (window.innerWidth <= 450) {
            dispatch(getStyling(9));
        } else {
            dispatch(getStyling(1));
        }
    }, []);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        if (!user?.api_token) {
            setTimeout(() => {
                handleShow();
            }, 2000);
        }
    }, []);

    const handleLogin = async (data) => {
        if (loginLoader) {
            return;
        }
        dispatch(requestLogin(true));
        try {
            const response = await postRequest({
                endpoint: "/loginReact",
                payload: data,
            });
            if (response?.data?.status === true) {
                const userData = {
                    ...response.data.user,
                    is_part_of_any_team: response.data?.is_part_of_any_team,
                    permissions: response.data?.permissions,
                };
                dispatch(loginSuccess(userData));
                dispatch(setUserProfile(response.data.user_profile));
                localStorage.setItem(
                    "accessToken",
                    response?.data?.user?.api_token
                );
                toast.success("Logged In", { duration: 3000 });
                setTimeout(() => {
                    setShow(false);
                    navigate("/timeline");
                    toast.dismiss();
                }, 500);
            } else {
                if (response?.data?.message) {
                    dispatch(hasError(response?.data?.message));
                }
                console.log("ERR>", response?.data?.message);
            }
        } catch (error) {
            console.log("ERR>", error);
            if (error?.data?.message) {
                toast.error(error.data.message, { duration: 3000 });
            }
        } finally {
            dispatch(requestLogin(false));
        }
    };

    const handleSignup = async (data, setErr) => {
        if (loginLoader) {
            return;
        }
        dispatch(requestLogin(true));
        try {
            const response = await postRequest({
                endpoint: "/registerReact",
                payload: data,
            });
            if (response?.data?.status === true) {
                dispatch(loginSuccess(response.data.user));
                dispatch(setUserProfile(response.data.user_profile));
                localStorage.setItem(
                    "accessToken",
                    response?.data?.user?.api_token
                );
                toast.success("Logged In", { duration: 3000 });
                setShow(false);
                navigate("/timeline");
            }
        } catch (error) {
            if (error?.response?.data?.errors?.email)
                setErr(error?.response?.data?.errors?.email?.[0]);
        } finally {
            dispatch(requestLogin(false));
        }
    };

    const forgetPassword = async (email, setErr) => {
        if (loginLoader) {
            return;
        }
        dispatch(requestLogin(true));
        try {
            const response = await postRequest({
                endpoint: "/forgetPassword",
                payload: { email },
            });
            if (response?.data?.status === true) {
                toast.success("Logged In", { duration: 3000 });
            }
            console.log("ERR>>>>", response.data);
            if (response?.data?.message) {
                setErr(response.data.message);
            }
        } catch (error) {
            console.log("ERR>>>>", error);
            if (error?.response?.data?.message) {
                setErr(error.response.data.message);
            }
        } finally {
            dispatch(requestLogin(false));
        }
    };

    return (
        <div
            className={
                darkMode ? "rightWrapMain darkMode" : "rightWrapMain lightMode"
            }
        >
            <div className="rightInnerWrap">
                <div className="sec2">
                    <div className="container">
                        {loading ? (
                            <Circles
                                height="50"
                                width="50"
                                color="transparent"
                                ariaLabel="circles-loading"
                                wrapperStyle={{
                                    alignItems: "center",
                                    justifyContent: "center",
                                    display: "flex",
                                }}
                                wrapperClass=""
                                visible={true}
                            />
                        ) : (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <SortableGrid
                                    widgetsOrder={data.homepageOrder}
                                />
                            </div>
                        )}
                        <LoginModal
                            show={show}
                            onHide={handleClose}
                            handleLogin={handleLogin}
                            onSignup={handleSignup}
                            forgetPassword={forgetPassword}
                        />
                    </div>
                    {!user?.api_token && !show && (
                        <FloatingLogin handleShow={handleShow} />
                    )}
                </div>
            </div>
        </div>
    );
}

export default Home;
