import React from "react";
import { ReactionBarSelector } from "@charkour/react-reactions";
import { Trash2 } from "lucide-react";
import Like from "../../../assets/svg/Like.svg";
import Heart from "../../../assets/svg/Heart2.svg";

const ChatItem = React.memo(
  ({ item, visibleReactionId, handleMouseEnter, handleMouseLeave }) => {
    const reactions = [
      {
        label: "Like",
        key: "1",
        node: (
          <div>
            {/* 👍 */}
            <img src={Like} />
          </div>
        ),
      },
      {
        label: "Love",
        key: "2",
        node: (
          <div>
            <img src={Heart} />
          </div>
        ),
      },
      {
        label: "Prayer",
        key: "3",
        node: <div>🙏</div>,
      },
      {
        label: "Praise",
        key: "4",
        node: <div>🙌</div>,
      },
      {
        label: "Fire",
        key: "5",
        node: <div>🔥</div>,
      },
      {
        label: "Water",
        key: "6",
        node: <div>💧</div>,
      },
    ];

    return (
      <div
        className="mb-2 pb-2"
        style={{
          paddingRight: 6,
          position: "relative",
          borderBottom: "1px solid #2c2c2c",
        }}
        onMouseLeave={handleMouseLeave}
      >
        <div
          className="d-flex justify-content-between mb-3"
          style={{ width: "100%" }}
        >
          <div className="d-flex gap-2">
            <p style={{ color: "#3478ff", margin: 0, fontSize: 14 }}>
              {item.name}
            </p>
            <Trash2 fill="black" color="red" size={18} />
          </div>
          <p style={{ color: "grey", margin: 0, fontSize: 12 }}>{item.time}</p>
        </div>
        <div
          className="mb-3"
          style={{
            backgroundColor: "#272727",
            padding: 6,
            borderRadius: 10,
            width: "fit-content",
            marginTop: 4,
            cursor: "pointer",
            marginBlock: 4,
          }}
        >
          <p style={{ color: "#ffffff", margin: 0, fontSize: 14 }}>
            {item.msg}
          </p>
        </div>
        <div
          style={{ cursor: "pointer", display: "flex", position: "relative" }}
        >
          <div
            onMouseEnter={() => handleMouseEnter(item.id)}
            style={{ alignSelf: "flex-start" }}
          >
            {/* 👍 */}
            <img src={Like} />
            {' '}
            <span style={{ fontSize: 12, color: "rgba(255,255,255,0.8)" }}>
              You reacted
            </span>
          </div>
          {visibleReactionId == item.id && (
            <div style={{ position: "absolute", top: -30, left: "-8px" }}>
              <ReactionBarSelector
                reactions={reactions}
                iconSize={25}
                style={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default ChatItem;
