import React, { useCallback, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import "../../pages/home/styles.css";
import ButtonComp from "../Button";
import ModalInput from "../InputField";
import { ChevronLeft } from "lucide-react";
import { Circles } from "react-loader-spinner";
import { useSelector } from "react-redux";
import PasswordInput from "../InputField/PasswordInput";

const LoginModal = ({
    show,
    onHide,
    handleLogin,
    onSignup,
    forgetPassword,
    initialState,
}) => {
    const isLoading = useSelector((state) => state.login.isLoading);
    const loginErr = useSelector((state) => state.login.loginError);

    const [section, setSection] = useState(initialState ? initialState : 1);
    // for login
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    // for signup
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = useState("");
    const [signUpEmail, setSignupEmail] = useState("");
    const [signupPass, setSignupPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState(false);
    const [forgotEmail, setForgotEmail] = useState("");

    useEffect(() => {
        if (loginErr) {
            setError(loginErr);
        }
    }, [loginErr]);

    const Section1 = useCallback(({ setError }) => {
        return (
            <div style={{ paddingBlock: 30 }} className="d-flex flex-column">
                <h2 class="welcomText align-self-center">
                    <span>Welcome to</span>
                </h2>
                <h1 className="redefineText align-self-center">
                    Redefine.Church
                </h1>
                <span className="footerText align-self-center">
                    Is This Your First Time?
                </span>
                <div className="row justify-content-center align-items-center mt-4 gap-3">
                    <div className="col-md-4">
                        <ButtonComp
                            title={"Yes!"}
                            onClick={() => {
                                setError("");
                                setSection(3);
                            }}
                        />
                    </div>
                    <div className="col-md-4">
                        <ButtonComp
                            title={"I'm Back!"}
                            onClick={() => setSection(2)}
                        />
                    </div>
                </div>
            </div>
        );
    }, []);

    const handleLoginSubmit = () => {
        if (
            username.trim().length === 0 ||
            password.trim().length === 0 ||
            email.trim().length === 0
        ) {
            setError("Please fill all fields");
            return;
        }
        const data = {
            username,
            password,
            email,
        };
        handleLogin(data);
    };

    const close = () => {
        setError("");
        setEmail("");
        setUsername("");
        setPassword("");
        setConfirmPassword("");
        setFirstName("");
        setLastName("");
        setPhone("");
        setSignupEmail("");
        setSignupPassword("");
        onHide();
    };

    const proceedSignup = (
        setError,
        firstName,
        lastName,
        signUpEmail,
        phone
    ) => {
        if (
            firstName.trim().length === 0 ||
            lastName.trim().length === 0 ||
            signUpEmail.trim().length === 0 ||
            phone.trim().length === 0
        ) {
            setError("Please fill all fields");
            return;
        }
        setError("");
        setSection(4);
    };

    const handleSignup = (
        setError,
        signupPass,
        confirmPassword,
        firstName,
        lastName,
        phone,
        signUpEmail
    ) => {
        if (
            signupPass.trim().length == 0 ||
            confirmPassword.trim().length == 0
        ) {
            setError("Please fill all fields");
            return;
        }
        if (signupPass !== confirmPassword) {
            setError("Please enter same password.");
            return;
        }
        const data = {
            first_name: firstName,
            last_name: lastName,
            phone_number: phone,
            email: signUpEmail,
            password: signupPass,
        };
        onSignup(data, setError);
    };

    // Login section
    const Section2 = useCallback(
        ({ username, email, password, handleLoginSubmit, error }) => {
            return (
                <div
                    style={{ paddingBlock: 20, paddingInline: 30 }}
                    className="d-flex flex-column"
                >
                    <h2 class="welcomText">
                        <span>Sorry we did not</span>
                    </h2>
                    <h1 className="redefineText" style={{ lineHeight: "34px" }}>
                        Recognize You!
                    </h1>
                    <span className="footerText" style={{ lineHeight: "34px" }}>
                        Please remind us your name.
                    </span>
                    <div className="row mt-4 col-md-12 row-gap-3">
                        {error ? (
                            <div className="col-md-12">
                                <span
                                    style={{ color: "#ff7a7a", fontSize: 12 }}
                                >
                                    {error}
                                </span>
                            </div>
                        ) : null}
                        <div className="col-md-6">
                            <ModalInput
                                value={username}
                                setText={setUsername}
                                placeholder="Name*"
                            />
                        </div>
                        <div className="col-md-6">
                            <ModalInput
                                type={"email"}
                                value={email}
                                setText={setEmail}
                                placeholder="Email*"
                            />
                        </div>
                        <div className="col-md-6">
                            <ModalInput
                                type={"password"}
                                value={password}
                                setText={setPassword}
                                placeholder="Password*"
                            />
                        </div>
                    </div>
                    <span
                        onClick={() => setSection(5)}
                        className="forgot-pass align-self-start"
                    >
                        Forgot Password?
                    </span>
                    <div className="row justify-contsent-between align-items-center mt-4 row-gap-2">
                        <div className="go-back col-md-5 d-flex align-items-center">
                            <div
                                onClick={() => setSection(1)}
                                className="d-flex align-items-center gap-2"
                            >
                                <div className="outer-border">
                                    <ChevronLeft color="#fff" size={40} />
                                </div>
                                <span>Go Back</span>
                            </div>
                        </div>
                        <div className="d-flex col-md-7 gap-2 sec2Btns justify-content-end">
                            <div className="col-md-5">
                                <ButtonComp
                                    title={"Submit"}
                                    onClick={handleLoginSubmit}
                                />
                            </div>
                            <div className="col-md-5">
                                <ButtonComp title={"Skip"} onClick={close} />
                            </div>
                        </div>
                    </div>
                </div>
            );
        },
        []
    );

    // Sign up section
    const Section3 = useCallback(
        ({
            section,
            firstName,
            lastName,
            phone,
            signUpEmail,
            confirmPassword,
            signupPass,
            setError,
            error,
            isLoading,
        }) => {
            return (
                <div
                    style={{ paddingBlock: 20, paddingInline: 30 }}
                    className="d-flex flex-column"
                >
                    <div className="d-flex flex=column justify-content-between">
                        {/* heading */}
                        <div>
                            <h1
                                className="redefineText"
                                style={{ lineHeight: "34px" }}
                            >
                                Tell Us About You!
                            </h1>
                            <span
                                className="footerText"
                                style={{ lineHeight: "34px" }}
                            >
                                Please fill the required information.
                            </span>
                        </div>
                        {/* circle steps */}
                        <div className="d-flex gap-2">
                            <div
                                className={`outerCircle ${
                                    section == 4 ? "outerCircleHide" : ""
                                }`}
                            >
                                <div className="circleDot" />
                            </div>
                            <div
                                className={`outerCircle ${
                                    section == 3 ? "outerCircleHide" : ""
                                }`}
                            >
                                <div className="circleDot" />
                            </div>
                        </div>
                    </div>
                    {(section == 3 || section == 4) && (
                        <>
                            {/* Inputs */}
                            {section == 3 ? (
                                <div className="row mt-4 col-md-12 row-gap-3">
                                    {error ? (
                                        <div className="col-md-12">
                                            <span
                                                style={{
                                                    color: "#ff7a7a",
                                                    fontSize: 12,
                                                }}
                                            >
                                                {error}
                                            </span>
                                        </div>
                                    ) : null}
                                    <div className="col-md-6">
                                        <ModalInput
                                            value={firstName}
                                            setText={setFirstName}
                                            placeholder="First Name*"
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <ModalInput
                                            value={lastName}
                                            setText={setLastName}
                                            placeholder="Last Name*"
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <ModalInput
                                            value={signUpEmail}
                                            type={"email"}
                                            setText={setSignupEmail}
                                            placeholder="Email*"
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <ModalInput
                                            value={phone}
                                            setText={setPhone}
                                            placeholder="Phone*"
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className="row mt-4 col-md-12 row-gap-3">
                                    {error ? (
                                        <div className="col-md-12">
                                            <span
                                                style={{
                                                    color: "#ff7a7a",
                                                    fontSize: 12,
                                                }}
                                            >
                                                {error}
                                            </span>
                                        </div>
                                    ) : null}
                                    <div className="col-md-6">
                                        <PasswordInput
                                            type={"password"}
                                            value={signupPass}
                                            setText={setSignupPassword}
                                            placeholder="Password"
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <PasswordInput
                                            type={"password"}
                                            value={confirmPassword}
                                            setText={setConfirmPassword}
                                            placeholder="Confirm Password*"
                                        />
                                    </div>
                                </div>
                            )}
                            {/*  */}
                            <div
                                className="row justify-content-between align-items-center row-gap-2"
                                style={{ marginTop: 50 }}
                            >
                                <div className="go-back col-md-5 d-flex align-items-center">
                                    <div
                                        onClick={() =>
                                            setSection((prev) =>
                                                prev == 4 ? 3 : 1
                                            )
                                        }
                                        className="d-flex align-items-center gap-2"
                                    >
                                        <div className="outer-border">
                                            <ChevronLeft
                                                color="#fff"
                                                size={40}
                                            />
                                        </div>
                                        <span>Go Back</span>
                                    </div>
                                </div>
                                <div className="d-flex col-md-7 gap-2 sec2Btns justify-content-end">
                                    <div className="col-md-5">
                                        <ButtonComp
                                            title={
                                                section == 4 ? "Submit" : "Next"
                                            }
                                            onClick={() => {
                                                section == 3
                                                    ? proceedSignup(
                                                          setError,
                                                          firstName,
                                                          lastName,
                                                          phone,
                                                          signUpEmail
                                                      )
                                                    : !isLoading &&
                                                      handleSignup(
                                                          setError,
                                                          signupPass,
                                                          confirmPassword,
                                                          firstName,
                                                          lastName,
                                                          phone,
                                                          signUpEmail,
                                                          password
                                                      );
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-5">
                                        <ButtonComp
                                            title={"Skip"}
                                            onClick={close}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            );
        },
        []
    );

    // Forgot Password
    const Section5 = useCallback(
        ({
            section,
            setError,
            error,
            isLoading,
            forgotPassword,
            forgotEmail,
        }) => {
            return (
                <div
                    style={{ paddingBlock: 20, paddingInline: 30 }}
                    className="d-flex flex-column"
                >
                    <div className="d-flex flex=column justify-content-between">
                        {/* heading */}
                        <div className="d-flex flex-column row-gap-2">
                            <h1
                                className="redefineText"
                                style={{ lineHeight: "34px" }}
                            >
                                Forget Password!
                            </h1>
                            <span
                                className="footerText"
                                style={{ lineHeight: "34px" }}
                            >
                                Please enter your email.
                            </span>
                        </div>
                    </div>
                    <>
                        {/* Inputs */}
                        <div className="row mt-3 col-md-12">
                            {error ? (
                                <div className="col-md-12 mb-2 ">
                                    <span
                                        style={{
                                            color: "#ff7a7a",
                                            fontSize: 12,
                                        }}
                                    >
                                        {error}
                                    </span>
                                </div>
                            ) : null}
                            <div className="col-md-12">
                                <ModalInput
                                    type={"email"}
                                    value={forgotEmail}
                                    setText={setForgotEmail}
                                    placeholder="Email*"
                                />
                            </div>
                        </div>
                        {/*  */}
                        <div
                            className="row justify-content-between align-items-center row-gap-2"
                            style={{ marginTop: 50 }}
                        >
                            <div className="go-back col-md-5 d-flex align-items-center">
                                <div
                                    onClick={() => {
                                        setError("");
                                        setSection(2);
                                    }}
                                    className="d-flex align-items-center gap-2"
                                >
                                    <div className="outer-border">
                                        <ChevronLeft color="#fff" size={40} />
                                    </div>
                                    <span>Go Back</span>
                                </div>
                            </div>
                            <div className="d-flex col-md-7 sec2Btns justify-content-end">
                                <div className="col-md-5">
                                    <ButtonComp
                                        title={"Submit"}
                                        onClick={() => {
                                            if (
                                                forgotEmail.trim().length > 0 &&
                                                !isLoading
                                            ) {
                                                forgetPassword(
                                                    forgotEmail,
                                                    setError
                                                );
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                </div>
            );
        },
        []
    );

    return (
        <Modal
            size={section == 1 ? "unset" : "lg"}
            centered
            show={show}
            onHide={onHide}
            backdrop="static"
        >
            <Modal.Body className="login-modal">
                {section == 1 ? (
                    <Section1 setError={setError} />
                ) : section == 2 ? (
                    <Section2
                        username={username}
                        email={email}
                        password={password}
                        handleLoginSubmit={handleLoginSubmit}
                        error={error}
                    />
                ) : section == 3 || section == 4 ? (
                    <Section3
                        section={section}
                        confirmPassword={confirmPassword}
                        firstName={firstName}
                        lastName={lastName}
                        phone={phone}
                        signUpEmail={signUpEmail}
                        signupPass={signupPass}
                        setError={setError}
                        error={error}
                        isLoading={isLoading}
                    />
                ) : section == 5 ? (
                    <Section5
                        setError={setError}
                        forgotPassword={forgetPassword}
                        forgotEmail={forgotEmail}
                        isLoading={isLoading}
                        error={error}
                    />
                ) : null}
                {isLoading && (
                    <Circles
                        height="40"
                        width="40"
                        color="white"
                        ariaLabel="circles-loading"
                        wrapperStyle={{
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                            position: "absolute",
                            top: 20,
                            right: 20,
                        }}
                        wrapperClass=""
                        visible={false}
                    />
                )}
            </Modal.Body>
        </Modal>
    );
};

export default LoginModal;
