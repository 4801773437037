import React from "react";

const ModalInput = ({ placeholder, value, setText, type }) => {
    return (
        <input
            value={value}
            type={type ? type : "text"}
            onChange={(e) => setText && setText(e.target.value)}
            placeholder={placeholder}
            className="modal-input"
        />
    );
};

export default ModalInput;
