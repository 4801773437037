import React, { useEffect, useRef, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
// Assets
import play_svg from "../../assets/svg/play.svg";
import heart_svg from "../../assets/svg/heart.svg";
import message_svg from "../../assets/svg/message.svg";
import people_svg from "../../assets/svg/people.svg";
import placeholder from "../../assets/placeholder.png";
import link_svg from "../../assets/svg/Link_color_white.svg";
import Hamburger from "hamburger-react";
import {
    Bell,
    BellOff,
    ChevronDown,
    ChevronDownSquare,
    ChevronUp,
    MapPin,
} from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import XHR from "../../utills/XHR";
import NotificationItem from "./NotificationItem";
import useOutsideClick from "../../utills/outerClick";
import Skeleton from "react-loading-skeleton";
import {
    setNotificationBadge,
    setSystemUserEnabled,
} from "../../store/slices/loginSlice";

export default function Header({ darkMode }) {
    const userProfile = useSelector((state) => state.login.userProfile);
    const user = useSelector((state) => state.login.userData);
    const notiBadge = useSelector((state) => state.login.notificationBadge);
    const messageBadge = useSelector((state) => state.login.messageBadge);
    // system user
    const isSystemUser = useSelector((state) => state.login.isSystemUser);
    const systemUserData = useSelector((state) => state.login.systemUserData);
    //

    const { pathname } = useLocation();
    const hostname = process.env.REACT_APP_BASE_URL;
    const dispatch = useDispatch();
    const notiData = useSelector(
        (state) => state.notification.notificationsdata
    );
    const [isOpen, setOpen] = useState(false);
    const [innerDrop, setInnerDrop] = useState(false);
    const [loader, setLoader] = useState({
        accept: false,
        decline: false,
        notificationId: null,
    });
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const navigate = useNavigate();

    const [notificationsData, setNotifications] = useState({
        notifications: [],
        notifications_last_page: 1,
        notifications_current_page: 1,
        all_read_check: 1,
    });
    const [showNotification, setShowNotification] = useState(false);

    const { permissions } = user || {};

    const redirectToLaravel = (route) => {
        const url = `${hostname}/login?apiToken=${user?.api_token}&callback_url=${route}`;
        // Redirect to the Laravel app
        window.location.href = url;
    };

    const headerRef = useRef(null);

    const handleToggleChange = () => {
        dispatch(setSystemUserEnabled(!isSystemUser));
        setInnerDrop(!innerDrop);
    };

    useEffect(() => {
        if (!notiData) return;
        const {
            notifications_current_page = 1,
            notifications_last_page = 1,
            notifications = [],
            all_read_check = 1,
        } = notiData;
        setNotifications({
            all_read_check,
            notifications,
            notifications_current_page,
            notifications_last_page,
        });
    }, [notiData]);

    // For chat screen
    useEffect(() => {
        const updateHeaderHeight = () => {
            if (headerRef.current) {
                const headerHeight = headerRef.current.offsetHeight;
                document.documentElement.style.setProperty(
                    "--header-height",
                    `${headerHeight}px`
                );
            }
        };

        // Use ResizeObserver to monitor changes
        const resizeObserver = new ResizeObserver(updateHeaderHeight);
        if (headerRef.current) {
            resizeObserver.observe(headerRef.current);
        }

        // Cleanup observer on unmount
        return () => {
            if (headerRef.current) {
                resizeObserver.unobserve(headerRef.current);
            }
        };
    }, []);

    const notiRef = useRef(null);

    useOutsideClick(notiRef, () => {
        setTimeout(() => {
            setShowNotification(false);
        }, 300);
    });

    // Notifications
    const getNotifications = (id, page) => {
        if (notificationsData.notifications.length !== 0) {
            return;
        }
        const options = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user.api_token}`,
            },
            method: "GET",
        };
        if (!notificationsData?.notifications?.length) {
            setSkeletonLoader(true);
        }

        XHR(
            `${process.env.REACT_APP_BASE_URL}/api/v1/getNotifications?user_id=${id}&&page=${page}`,
            options
        )
            .then((resp) => {
                if (resp?.data?.success) {
                    setNotifications(resp?.data);
                    setTimeout(() => {
                        if (resp.data?.all_read_check == 1) {
                            dispatch(setNotificationBadge(true));
                        } else {
                            dispatch(setNotificationBadge(false));
                        }
                    }, 500);
                }
            })
            .catch((e) => {
                console.log("ERR>", e);
            })
            .finally(() => {
                setSkeletonLoader(false);
            });
    };

    const handleRequest = (id, action, notificationId) => {
        const options = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user?.api_token}`,
            },
            method: "POST",
            data: {
                connection_id: id,
            },
        };
        setLoader({
            decline: action === "decline" ? true : false,
            accept: action === "accept" ? true : false,
            notificationId,
        });
        XHR(
            `${process.env.REACT_APP_BASE_URL}/api/v1/${
                action === "accept"
                    ? "acceptConnectionRequest"
                    : "rejectConnectionRequest"
            }`,
            options
        )
            .then((response) => {
                if (response.data.success) {
                    toast.success(
                        `Request ${
                            action === "accept" ? "Accepted" : "Declined"
                        }`,
                        { duration: 3000 }
                    );
                    setNotifications((prev) => {
                        const deepCopy = JSON.parse(
                            JSON.stringify(prev.notifications)
                        );
                        const notiIndex = deepCopy?.findIndex(
                            (item) => item.id == notificationId
                        );
                        if (notiIndex !== -1) {
                            deepCopy[notiIndex].status =
                                action === "accept" ? 1 : 2;
                            const temp = {
                                ...prev,
                                notifications: deepCopy,
                            };
                            return temp;
                        }
                        return prev;
                    });
                }
                console.log("RESP>", response);
            })
            .catch((error) => {
                console.log("ERRRR>", error);
            })
            .finally(() => {
                setLoader({
                    decline: false,
                    accept: false,
                    notificationId: null,
                });
            });
    };

    const handleEvent = (invitation_id, status, notificationId) => {
        const options = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user?.api_token}`,
            },
            method: "POST",
            data: { invitation_id, status },
        };
        setLoader({
            decline: status == 2 ? true : false,
            accept: status == 1 ? true : false,
            notificationId,
        });
        XHR(
            `${process.env.REACT_APP_BASE_URL}/user-change-event-status`,
            options
        )
            .then((resp) => {
                if (resp.data.success) {
                    toast.success(
                        status == 1
                            ? "Thanks for accepting invitation."
                            : "You have declined invitation request."
                    );
                    setNotifications((prev) => {
                        const deepCopy = JSON.parse(
                            JSON.stringify(prev.notifications)
                        );
                        const notiIndex = deepCopy?.findIndex(
                            (item) => item.id == notificationId
                        );
                        if (notiIndex !== -1) {
                            deepCopy[notiIndex] = {
                                ...deepCopy[notiIndex],
                                event_detail: {
                                    ...deepCopy[notiIndex]?.event_detail,
                                    invitation_status: status,
                                },
                            };

                            const temp = {
                                ...prev,
                                notifications: deepCopy,
                            };
                            return temp;
                        }
                        return prev;
                    });
                }
            })
            .catch((e) => {
                console.log("ERR>", e);
            })
            .finally(() => {
                setLoader({
                    decline: false,
                    accept: false,
                    notificationId: null,
                });
            });
    };

    const SkeletonComp = () => {
        return (
            <div
                className="d-flex gap-3 align-items-center"
                style={{
                    overflow: "hidden",
                    minHeight: 56,
                }}
            >
                <Skeleton baseColor="#716e6e" circle height={50} width={50} />
                <p
                    style={{
                        fontSize: 12,
                        fontWeight: "500",
                        marginBottom: 0,
                    }}
                >
                    <Skeleton baseColor="#716e6e" height={8} width={200} />
                    <Skeleton baseColor="#716e6e" height={8} width={150} />
                </p>
            </div>
        );
    };

    const changeNotificationStatus = (noti_Ids, check) => {
        const options = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user?.api_token}`,
            },
            method: "POST",
            data: {
                notification_ids: noti_Ids,
            },
        };
        XHR(
            `${process.env.REACT_APP_BASE_URL}/api/v1/changeNotificationStatus`,
            options
        )
            .then((response) => {
                if (check === "all") {
                    toast.success("Notifications marked as read", {
                        duration: 3000,
                    });
                }
                setNotifications((prev) => {
                    const updatedData = updateNotifications(
                        prev.notifications,
                        noti_Ids
                    );
                    const badgeToShow = updatedData.filter(
                        (item) => item.is_read == 0
                    );
                    setTimeout(() => {
                        if (badgeToShow.length > 0) {
                            dispatch(setNotificationBadge(true));
                        } else {
                            dispatch(setNotificationBadge(false));
                        }
                    }, 500);
                    const temp = {
                        ...prev,
                        notifications: updatedData,
                    };
                    return temp;
                });
            })
            .catch((error) => {
                console.log("ERRRR>", error);
            });
    };

    const updateNotifications = (notifications, idsToUpdate) => {
        return notifications.map((notification) => {
            if (idsToUpdate.includes(notification.id)) {
                return { ...notification, is_read: 1 };
            }
            return notification;
        });
    };

    const kidsHandOff = (status, id, notiId) => {
        const options = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user?.api_token}`,
            },
            method: "POST",
            data: { id, status, notiId },
        };
        XHR(`${process.env.REACT_APP_BASE_URL}/api/v1/hands_in_status`, options)
            .then((response) => {
                if (response?.data?.status) {
                    toast.success(
                        `Kid checked${status == 1 ? " In" : " Out"}`,
                        {
                            duration: 3000,
                        }
                    );
                    setNotifications((prev) => {
                        const deepCopy = JSON.parse(
                            JSON.stringify(prev.notifications)
                        );
                        const notiIndex = deepCopy?.findIndex(
                            (item) => item.id == notiId
                        );
                        if (notiIndex !== -1) {
                            deepCopy[notiIndex] = {
                                ...deepCopy[notiIndex],
                                kids_detail: {
                                    ...deepCopy[notiIndex]?.kids_detail,
                                    hands_in: status,
                                },
                                is_read: 1,
                            };

                            const temp = {
                                ...prev,
                                notifications: deepCopy,
                            };
                            return temp;
                        }
                        return prev;
                    });
                }
            })
            .catch((error) => {
                console.log("ERRRR>", error);
            });
    };

    return (
        <header
            ref={headerRef}
            className="newHeader blueHeader"
            style={{ backgroundColor: darkMode ? "#171717" : "#fff" }}
        >
            <Toaster position="top-center" />
            <div className="container-fluid">
                <div className="row d-flex align-items-center">
                    <div className="col-md-3 logo-main mainHeader">
                        <a
                            // href="/"
                            onClick={() => navigate("/")}
                            style={{ cursor: "pointer" }}
                        >
                            <img
                                style={{
                                    filter: !darkMode
                                        ? "brightness(0)"
                                        : "unset",
                                }}
                                src="https://images.ctfassets.net/1itkm9rji8jb/48jN6KXbjOANg70fZ2oHFE/66ead803c36b23e6752a0a2602e659bc/logo__1_.png"
                                alt="Logo"
                            />
                        </a>
                    </div>
                    {/* hamburger  */}
                    <div className="col-md-3 logo-main header-Logo">
                        <div style={{ width: 60 }} />
                        <a
                            // href="/"
                            onClick={() => navigate("/")}
                            style={{ cursor: "pointer" }}
                        >
                            <img
                                style={{
                                    filter: !darkMode
                                        ? "brightness(0)"
                                        : "unset",
                                }}
                                src="https://images.ctfassets.net/1itkm9rji8jb/48jN6KXbjOANg70fZ2oHFE/66ead803c36b23e6752a0a2602e659bc/logo__1_.png"
                                alt="Logo"
                            />
                        </a>
                        <div>
                            <Hamburger
                                color={darkMode ? "#fff" : "#000"}
                                toggled={isOpen}
                                toggle={setOpen}
                            />
                            <div
                                // className="mobileMenuDesign"
                                className={`mobileMenuDesign ${
                                    isOpen ? "open" : ""
                                }`}
                                // style={{ display: isOpen ? "block" : "none" }}
                                // style={{
                                //   maxHeight: isOpen && innerDrop ? 640 : isOpen ? 600 : 0,
                                // }}
                            >
                                <div
                                    className="admin-dashboard-left"
                                    style={{
                                        maxHeight: 550,
                                        overflow: "auto",
                                        boxShadow: isOpen
                                            ? "1px 1px 10px #d1d1d1"
                                            : "unset",
                                    }}
                                >
                                    <div className="dropdownnewpro">
                                        <div
                                            className="innerdropdownmain"
                                            style={{
                                                paddingBottom: innerDrop
                                                    ? 8
                                                    : 0,
                                                borderBottom: innerDrop
                                                    ? `1px solid ${
                                                          darkMode
                                                              ? "#2d2d2d"
                                                              : "#000"
                                                      }`
                                                    : "unset",
                                                transition:
                                                    "padding-bottom 0.5s ease-out",
                                            }}
                                        >
                                            <div className="innerdropdownmainLEft">
                                                <a
                                                    // href="/userProfile"
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                        navigate("/userProfile")
                                                    }
                                                >
                                                    <img
                                                        src={
                                                            isSystemUser &&
                                                            systemUserData?.profile_pic
                                                                ? `${process.env.REACT_APP_BASE_URL}/${systemUserData?.profile_pic}`
                                                                : userProfile?.profile_pic
                                                                ? `${process.env.REACT_APP_BASE_URL}/${userProfile?.profile_pic}`
                                                                : placeholder
                                                        }
                                                        alt=""
                                                    />
                                                    <h5>
                                                        {isSystemUser
                                                            ? systemUserData?.username
                                                            : user?.username}
                                                    </h5>
                                                </a>
                                            </div>
                                            <div
                                                onClick={() =>
                                                    setInnerDrop(!innerDrop)
                                                }
                                                className="innerdropdownmainRight"
                                            >
                                                <div className="angledownopenmenu">
                                                    {innerDrop ? (
                                                        <ChevronUp
                                                            color="#fff"
                                                            size={20}
                                                        />
                                                    ) : (
                                                        <ChevronDown
                                                            color="#fff"
                                                            size={20}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className={`menuDropinner ${
                                                innerDrop ? "open" : ""
                                            }`}
                                        >
                                            {systemUserData &&
                                                systemUserData?.user_id && (
                                                    <div
                                                        className="innerdropdownmain"
                                                        style={{
                                                            paddingBlock: 8,
                                                        }}
                                                        onClick={
                                                            handleToggleChange
                                                        }
                                                    >
                                                        <div className="innerdropdownmainLEft">
                                                            <a
                                                                style={{
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                <img
                                                                    style={{
                                                                        height: 30,
                                                                        width: 30,
                                                                    }}
                                                                    src={
                                                                        !isSystemUser &&
                                                                        systemUserData?.profile_pic
                                                                            ? `${process.env.REACT_APP_BASE_URL}/${systemUserData?.profile_pic}`
                                                                            : userProfile?.profile_pic
                                                                            ? `${process.env.REACT_APP_BASE_URL}/${userProfile?.profile_pic}`
                                                                            : placeholder
                                                                    }
                                                                    alt=""
                                                                />
                                                                <h5>
                                                                    {!isSystemUser
                                                                        ? systemUserData?.username
                                                                        : user?.username}
                                                                </h5>
                                                            </a>
                                                        </div>
                                                    </div>
                                                )}
                                            <ul style={{ marginTop: 0 }}>
                                                <li className="">
                                                    {user?.role == 1 ? (
                                                        <a
                                                            // href={`http://${hostname}/admin/users`}
                                                            onClick={(e) => {
                                                                redirectToLaravel(
                                                                    `${hostname}/admin/users`
                                                                );
                                                            }}
                                                            className="nav-link"
                                                        >
                                                            <span>
                                                                Dashboard
                                                            </span>
                                                        </a>
                                                    ) : (
                                                        <a
                                                            href={"#"}
                                                            className="nav-link"
                                                        >
                                                            <span>Profile</span>
                                                        </a>
                                                    )}
                                                </li>
                                                <li>
                                                    <a
                                                        onClick={() => {
                                                            navigate("/chat");
                                                            setOpen(false);
                                                        }}
                                                        // href="/"
                                                        className="nav-link"
                                                    >
                                                        <span>Chat</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        onClick={() => {
                                                            navigate(
                                                                "/notifications"
                                                            );
                                                            setOpen(false);
                                                        }}
                                                        // href="/"
                                                        className="nav-link"
                                                    >
                                                        <span>
                                                            Notifications
                                                        </span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        onClick={() => {
                                                            localStorage.clear();
                                                        }}
                                                        href="/"
                                                        className="nav-link"
                                                    >
                                                        <span>Logout</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* {user?.role == "1" && ( */}
                                    <>
                                        {/* <h3>Shortcuts</h3> */}
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h3
                                                style={{
                                                    marginBottom: 0,
                                                }}
                                            >
                                                Shortcuts
                                            </h3>
                                            {/* {systemUserData !== null && (
                                                <div className="d-flex align-items-center gap-2">
                                                    <h3
                                                        style={{
                                                            marginBottom: 0,
                                                        }}
                                                    >
                                                        System:
                                                    </h3>
                                                    <label
                                                        style={{
                                                            marginBottom: 0,
                                                        }}
                                                        className="toggle-switch"
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            checked={
                                                                isSystemUser
                                                            }
                                                            onChange={
                                                                handleToggleChange
                                                            }
                                                        />
                                                        <span className="system-toggle-slider"></span>
                                                    </label>
                                                </div>
                                            )} */}
                                        </div>
                                        <div className="header-menu-main-left">
                                            <div className="header-menu newmenuDesign">
                                                <ul>
                                                    {(permissions?.user_access ||
                                                        permissions?.people_team ||
                                                        user?.role == 1) && (
                                                        <li className="">
                                                            <a
                                                                // href={`http://${hostname}/admin/users`}
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    redirectToLaravel(
                                                                        `${hostname}/admin/users`
                                                                    );
                                                                }}
                                                            >
                                                                <img
                                                                    src="https://images.ctfassets.net/1itkm9rji8jb/5Ge0I3BVeTdtrZTCOpiosW/9f770ffed189d3a9c63237d55c5e7166/ico-people.svg"
                                                                    alt=""
                                                                />
                                                                <span>
                                                                    People
                                                                </span>
                                                            </a>
                                                        </li>
                                                    )}
                                                    {(permissions?.scheduling ||
                                                        permissions?.schedule_team ||
                                                        user?.role == 1) && (
                                                        <li className="">
                                                            <a
                                                                // href={`http://${hostname}/admin/schedule`}
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    redirectToLaravel(
                                                                        `${hostname}/admin/schedule`
                                                                    );
                                                                }}
                                                            >
                                                                <img
                                                                    src="https://images.ctfassets.net/1itkm9rji8jb/262tDgGk3FOuhQ8nlVUoiY/d0232b2f29e5193aaedae4a4b6bfe08f/ico-sch.svg"
                                                                    alt=""
                                                                />
                                                                <span>
                                                                    Schedules
                                                                </span>
                                                            </a>
                                                        </li>
                                                    )}
                                                    {(permissions?.groups ||
                                                        user?.role == 1) && (
                                                        <li className="">
                                                            <a
                                                                // href={`/group`}
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    redirectToLaravel(
                                                                        `${hostname}/admin/groups`
                                                                    );
                                                                }}
                                                            >
                                                                <img
                                                                    src="https://images.ctfassets.net/1itkm9rji8jb/4X5RS42wn101bgkUkdQqF0/2b5fe2c4b5a56b3fda01fd2b66a955de/ico-group.svg"
                                                                    alt=""
                                                                />
                                                                <span>
                                                                    Groups
                                                                </span>
                                                            </a>
                                                        </li>
                                                    )}
                                                    {(permissions?.kids_system ||
                                                        user?.role == 1) && (
                                                        <li className="">
                                                            <a
                                                                // href={`/kids`}
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    redirectToLaravel(
                                                                        `${hostname}/admin/all-kids`
                                                                    );
                                                                }}
                                                            >
                                                                <img
                                                                    src="https://images.ctfassets.net/1itkm9rji8jb/1Qy0TsteEmKbYWTPuaSZSG/faa5a80eadea3cec0e5cc5ed381f1c34/ico-kid.svg"
                                                                    alt=""
                                                                />
                                                                <span>
                                                                    Kids
                                                                </span>
                                                            </a>
                                                        </li>
                                                    )}
                                                    {(permissions?.finances ||
                                                        user?.role == 1) && (
                                                        <li className="">
                                                            <a
                                                                // href={`/giving`}
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    redirectToLaravel(
                                                                        `${hostname}/admin/giving`
                                                                    );
                                                                }}
                                                            >
                                                                <img
                                                                    src="https://images.ctfassets.net/1itkm9rji8jb/2Tm3pHUA0oiUCmU5nsT4l4/a7e43a934909b17de1d9af6837ddfb02/ico-giving.svg"
                                                                    alt=""
                                                                />
                                                                <span>
                                                                    Giving
                                                                </span>
                                                            </a>
                                                        </li>
                                                    )}
                                                    {(permissions?.live_streaming_set_up ||
                                                        user?.role == 1) && (
                                                        <li className="">
                                                            <a
                                                                // href={`http://${hostname}/admin/livestream`}
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    redirectToLaravel(
                                                                        `${hostname}/admin/livestream`
                                                                    );
                                                                }}
                                                            >
                                                                <img
                                                                    src="https://images.ctfassets.net/1itkm9rji8jb/6lyyozpxIzbB1ESrcbFC1v/27d14aac52adc9aaada713d7b48f7b22/ico-stream.svg"
                                                                    alt=""
                                                                />
                                                                <span>
                                                                    Live Stream
                                                                </span>
                                                            </a>
                                                        </li>
                                                    )}
                                                    {(permissions?.locations ||
                                                        user?.role == 1) && (
                                                        <li className="">
                                                            <a
                                                                // href={`http://${hostname}/admin/livestream`}
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    redirectToLaravel(
                                                                        `${hostname}/admin/locations`
                                                                    );
                                                                }}
                                                            >
                                                                <MapPin
                                                                    color="#fff"
                                                                    style={{
                                                                        marginBottom: 9,
                                                                    }}
                                                                    size={26}
                                                                />
                                                                <span>
                                                                    Locations
                                                                </span>
                                                            </a>
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                    </>
                                    {/* )} */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex col-md-5 align-items-center justify-content-between icons-container">
                        <a
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate("/timeline")}
                            // href={`/timeline`}
                        >
                            <img
                                style={{
                                    filter: !darkMode
                                        ? "brightness(0)"
                                        : "unset",
                                }}
                                className="header-Icon"
                                src={link_svg}
                                alt="Logo"
                            />
                        </a>
                        <a
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate("/watch")}
                            // href={`/watch`}
                        >
                            <img
                                style={{
                                    filter: !darkMode
                                        ? "brightness(0)"
                                        : "unset",
                                }}
                                className="header-Icon"
                                src={play_svg}
                                alt="Logo"
                            />
                        </a>
                        <a
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                                navigate(
                                    user.is_part_of_any_team
                                        ? "/team"
                                        : "/serve"
                                )
                            }
                            // href={user.is_part_of_any_team ? "/team" : "/serve"}
                        >
                            <img
                                style={{
                                    filter: !darkMode
                                        ? "brightness(0)"
                                        : "unset",
                                }}
                                className="header-Icon"
                                src={people_svg}
                                alt="Logo"
                            />
                        </a>
                        <a
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate("/group")}
                            // href={`/group`}
                        >
                            <img
                                style={{
                                    filter: !darkMode
                                        ? "brightness(0)"
                                        : "unset",
                                }}
                                className="header-Icon"
                                src={message_svg}
                                alt="Logo"
                            />
                        </a>
                        <a
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate("/give")}
                            // href={`/giving`}
                        >
                            <img
                                style={{
                                    filter: !darkMode
                                        ? "brightness(0)"
                                        : "unset",
                                }}
                                className="header-Icon"
                                src={heart_svg}
                                alt="Logo"
                            />
                        </a>
                    </div>

                    <div className="col-md-3 newheaderRight">
                        <div className="headerRightIcon">
                            <div className="newiconheader">
                                <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => navigate("/chat")}
                                    className="nottii"
                                >
                                    <img
                                        style={{
                                            filter: !darkMode
                                                ? "brightness(0)"
                                                : "unset",
                                        }}
                                        src="https://images.ctfassets.net/1itkm9rji8jb/5uZjMW3E2yBJxOl8sEq3tk/f9e2392657dc94c5d31489e0fa8b5f68/mesg.svg"
                                        alt=""
                                    />
                                    {messageBadge && (
                                        <div className="cirlenoti"></div>
                                    )}
                                </div>
                                <div
                                    className="mes"
                                    style={{
                                        position: "relative",
                                    }}
                                >
                                    <img
                                        style={{
                                            cursor: "pointer",
                                            filter: !darkMode
                                                ? "brightness(0)"
                                                : "unset",
                                        }}
                                        onClick={() => {
                                            if (
                                                !showNotification &&
                                                pathname !== "/notifications"
                                            ) {
                                                getNotifications(user?.id, 1);
                                                setShowNotification(true);
                                            }
                                        }}
                                        src="https://images.ctfassets.net/1itkm9rji8jb/4SRholtYMrPCO2YQDLNEyG/2efd1ecafa092541f1e4862d10378bc0/noti.svg"
                                        alt=""
                                    />
                                    {notiBadge && (
                                        <div className="cirlenoti"></div>
                                    )}

                                    {/* Notifications list */}
                                    {showNotification && (
                                        <div
                                            ref={notiRef}
                                            className="notification-menu"
                                        >
                                            <div className="d-flex align-items-center justify-content-between">
                                                <h3
                                                    style={{
                                                        color: darkMode
                                                            ? "#fff"
                                                            : "#000",
                                                        fontSize: 24,
                                                    }}
                                                >
                                                    Notifications
                                                </h3>
                                                {notificationsData.notifications_last_page >
                                                    1 && (
                                                    <span
                                                        onClick={() => {
                                                            setShowNotification(
                                                                false
                                                            );
                                                            navigate(
                                                                "/notifications"
                                                            );
                                                        }}
                                                        style={{
                                                            color: "#5aa7ff",
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        See all
                                                    </span>
                                                )}
                                            </div>
                                            {/* List */}
                                            <div className="mt-2 gap-2 notifications-cont">
                                                {skeletonLoader ? (
                                                    Array(10)
                                                        .fill(null)
                                                        .map((_, i) => {
                                                            return (
                                                                <SkeletonComp
                                                                    key={i}
                                                                />
                                                            );
                                                        })
                                                ) : notificationsData
                                                      .notifications?.length ==
                                                  0 ? (
                                                    <div className="d-flex  flex-column align-items-center justify-content-center gap-2 my-2">
                                                        <BellOff
                                                            size={80}
                                                            color="#b0b3b8"
                                                        />
                                                        <h5
                                                            style={{
                                                                color: "#b0b3b8",
                                                            }}
                                                        >
                                                            You have no
                                                            notifications.
                                                        </h5>
                                                    </div>
                                                ) : (
                                                    notificationsData.notifications
                                                        .slice(0, 15)
                                                        .map((item, index) => {
                                                            return item?.type ==
                                                                3 &&
                                                                item?.event_detail ==
                                                                    null ? null : (
                                                                <NotificationItem
                                                                    key={index}
                                                                    item={item}
                                                                    handleEvent={
                                                                        handleEvent
                                                                    }
                                                                    handleRequest={
                                                                        handleRequest
                                                                    }
                                                                    loader={
                                                                        loader
                                                                    }
                                                                    changeNotificationStatus={
                                                                        changeNotificationStatus
                                                                    }
                                                                    hideNotificationMenu={() =>
                                                                        setShowNotification(
                                                                            false
                                                                        )
                                                                    }
                                                                    darkMode={
                                                                        darkMode
                                                                    }
                                                                    kidsHandOff={
                                                                        kidsHandOff
                                                                    }
                                                                />
                                                            );
                                                        })
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div
                                    style={{ cursor: "pointer" }}
                                    className="profileIMg"
                                >
                                    <img
                                        style={{
                                            height: 35,
                                            width: 35,
                                            borderRadius: 50,
                                        }}
                                        src={
                                            isSystemUser &&
                                            systemUserData?.profile_pic
                                                ? `${process.env.REACT_APP_BASE_URL}/${systemUserData?.profile_pic}`
                                                : userProfile?.profile_pic
                                                ? `${process.env.REACT_APP_BASE_URL}/${userProfile?.profile_pic}`
                                                : placeholder
                                        }
                                        alt=""
                                    />
                                    <div className="mobileMenuDesign">
                                        <div className="admin-dashboard-left">
                                            <div className="dropdownnewpro">
                                                <div
                                                    className="innerdropdownmain"
                                                    style={{
                                                        paddingBottom: innerDrop
                                                            ? 8
                                                            : 0,
                                                        borderBottom: innerDrop
                                                            ? `1px solid ${
                                                                  darkMode
                                                                      ? "#2d2d2d"
                                                                      : "#000"
                                                              }`
                                                            : "unset",
                                                        transition:
                                                            "padding-bottom 0.5s ease-out",
                                                    }}
                                                >
                                                    <div className="innerdropdownmainLEft">
                                                        <a
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={() => {
                                                                if (
                                                                    isSystemUser
                                                                ) {
                                                                    return;
                                                                }
                                                                navigate(
                                                                    "/userProfile"
                                                                );
                                                            }}
                                                        >
                                                            <img
                                                                style={{
                                                                    height: 30,
                                                                    width: 30,
                                                                }}
                                                                src={
                                                                    isSystemUser &&
                                                                    systemUserData?.profile_pic
                                                                        ? `${process.env.REACT_APP_BASE_URL}/${systemUserData?.profile_pic}`
                                                                        : userProfile?.profile_pic
                                                                        ? `${process.env.REACT_APP_BASE_URL}/${userProfile?.profile_pic}`
                                                                        : placeholder
                                                                }
                                                                alt=""
                                                            />
                                                            <h5>
                                                                {isSystemUser
                                                                    ? systemUserData?.username
                                                                    : user?.username}
                                                            </h5>
                                                        </a>
                                                    </div>
                                                    <div
                                                        onClick={() =>
                                                            setInnerDrop(
                                                                !innerDrop
                                                            )
                                                        }
                                                        className="innerdropdownmainRight"
                                                    >
                                                        <div className="angledownopenmenu">
                                                            {innerDrop ? (
                                                                <ChevronUp
                                                                    color="#fff"
                                                                    size={20}
                                                                />
                                                            ) : (
                                                                <ChevronDown
                                                                    color="#fff"
                                                                    size={20}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div
                                                    className={`menuDropinner ${
                                                        innerDrop ? "open" : ""
                                                    }`}
                                                >
                                                    {systemUserData &&
                                                        systemUserData?.user_id && (
                                                            <div
                                                                className="innerdropdownmain"
                                                                style={{
                                                                    paddingBlock: 8,
                                                                }}
                                                                onClick={
                                                                    handleToggleChange
                                                                }
                                                            >
                                                                <div className="innerdropdownmainLEft">
                                                                    <a
                                                                        style={{
                                                                            cursor: "pointer",
                                                                        }}
                                                                    >
                                                                        <img
                                                                            style={{
                                                                                height: 30,
                                                                                width: 30,
                                                                            }}
                                                                            src={
                                                                                !isSystemUser &&
                                                                                systemUserData?.profile_pic
                                                                                    ? `${process.env.REACT_APP_BASE_URL}/${systemUserData?.profile_pic}`
                                                                                    : userProfile?.profile_pic
                                                                                    ? `${process.env.REACT_APP_BASE_URL}/${userProfile?.profile_pic}`
                                                                                    : placeholder
                                                                            }
                                                                            alt=""
                                                                        />
                                                                        <h5>
                                                                            {!isSystemUser
                                                                                ? systemUserData?.username
                                                                                : user?.username}
                                                                        </h5>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        )}
                                                    <ul
                                                        style={{ marginTop: 0 }}
                                                    >
                                                        <li className="">
                                                            {user?.role == 1 ? (
                                                                <a
                                                                    // href={`http://${hostname}/admin/users`}
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        redirectToLaravel(
                                                                            `${hostname}/admin/users`
                                                                        );
                                                                    }}
                                                                    className="nav-link"
                                                                >
                                                                    <span>
                                                                        Dashboard
                                                                    </span>
                                                                </a>
                                                            ) : (
                                                                <a
                                                                    // href={`#`}
                                                                    onClick={() =>
                                                                        navigate(
                                                                            "/userProfile"
                                                                        )
                                                                    }
                                                                    className="nav-link"
                                                                >
                                                                    <span>
                                                                        Profile
                                                                    </span>
                                                                </a>
                                                            )}
                                                        </li>
                                                        <li>
                                                            <a
                                                                onClick={() => {
                                                                    localStorage.clear();
                                                                }}
                                                                href="/"
                                                                className="nav-link"
                                                            >
                                                                <span>
                                                                    Logout
                                                                </span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            {/* {user?.role == 1 && ( */}
                                            <>
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <h3
                                                        style={{
                                                            marginBottom: 0,
                                                        }}
                                                    >
                                                        Shortcuts
                                                    </h3>
                                                    {/* {systemUserData !==
                                                        null && (
                                                        <div className="d-flex align-items-center gap-2">
                                                            <h3
                                                                style={{
                                                                    marginBottom: 0,
                                                                }}
                                                            >
                                                                System:
                                                            </h3>
                                                            <label
                                                                style={{
                                                                    marginBottom: 0,
                                                                }}
                                                                className="toggle-switch"
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    checked={
                                                                        isSystemUser
                                                                    }
                                                                    onChange={
                                                                        handleToggleChange
                                                                    }
                                                                />
                                                                <span className="system-toggle-slider"></span>
                                                            </label>
                                                        </div>
                                                    )} */}
                                                </div>

                                                <div className="header-menu-main-left">
                                                    <div className="header-menu newmenuDesign">
                                                        <ul>
                                                            {(permissions?.user_access ||
                                                                permissions?.people_team ||
                                                                user?.role ==
                                                                    1) && (
                                                                <li className="">
                                                                    <a
                                                                        // href={`http://${hostname}/admin/users`}
                                                                        onClick={(
                                                                            e
                                                                        ) => {
                                                                            redirectToLaravel(
                                                                                `${hostname}/admin/users`
                                                                            );
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src="https://images.ctfassets.net/1itkm9rji8jb/5Ge0I3BVeTdtrZTCOpiosW/9f770ffed189d3a9c63237d55c5e7166/ico-people.svg"
                                                                            alt=""
                                                                        />
                                                                        <span>
                                                                            People
                                                                        </span>
                                                                    </a>
                                                                </li>
                                                            )}
                                                            {(permissions?.scheduling ||
                                                                permissions?.schedule_team ||
                                                                user?.role ==
                                                                    1) && (
                                                                <li className="">
                                                                    <a
                                                                        // href={`http://${hostname}/admin/schedule`}
                                                                        onClick={(
                                                                            e
                                                                        ) => {
                                                                            redirectToLaravel(
                                                                                `${hostname}/admin/schedule`
                                                                            );
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src="https://images.ctfassets.net/1itkm9rji8jb/262tDgGk3FOuhQ8nlVUoiY/d0232b2f29e5193aaedae4a4b6bfe08f/ico-sch.svg"
                                                                            alt=""
                                                                        />
                                                                        <span>
                                                                            Schedules
                                                                        </span>
                                                                    </a>
                                                                </li>
                                                            )}
                                                            {(permissions?.groups ||
                                                                user?.role ==
                                                                    1) && (
                                                                <li className="">
                                                                    <a
                                                                        // href={`/group`}
                                                                        onClick={(
                                                                            e
                                                                        ) => {
                                                                            redirectToLaravel(
                                                                                `${hostname}/admin/groups`
                                                                            );
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src="https://images.ctfassets.net/1itkm9rji8jb/4X5RS42wn101bgkUkdQqF0/2b5fe2c4b5a56b3fda01fd2b66a955de/ico-group.svg"
                                                                            alt=""
                                                                        />
                                                                        <span>
                                                                            Groups
                                                                        </span>
                                                                    </a>
                                                                </li>
                                                            )}
                                                            {(permissions?.kids_system ||
                                                                user?.role ==
                                                                    1) && (
                                                                <li className="">
                                                                    <a
                                                                        // href={`kids`}
                                                                        onClick={(
                                                                            e
                                                                        ) => {
                                                                            redirectToLaravel(
                                                                                `${hostname}/admin/all-kids`
                                                                            );
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src="https://images.ctfassets.net/1itkm9rji8jb/1Qy0TsteEmKbYWTPuaSZSG/faa5a80eadea3cec0e5cc5ed381f1c34/ico-kid.svg"
                                                                            alt=""
                                                                        />
                                                                        <span>
                                                                            Kids
                                                                        </span>
                                                                    </a>
                                                                </li>
                                                            )}
                                                            {(permissions?.finances ||
                                                                user?.role ==
                                                                    1) && (
                                                                <li className="">
                                                                    <a
                                                                        // href={`/giving`}
                                                                        onClick={(
                                                                            e
                                                                        ) => {
                                                                            redirectToLaravel(
                                                                                `${hostname}/admin/giving`
                                                                            );
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src="https://images.ctfassets.net/1itkm9rji8jb/2Tm3pHUA0oiUCmU5nsT4l4/a7e43a934909b17de1d9af6837ddfb02/ico-giving.svg"
                                                                            alt=""
                                                                        />
                                                                        <span>
                                                                            Giving
                                                                        </span>
                                                                    </a>
                                                                </li>
                                                            )}
                                                            {(permissions?.live_streaming_set_up ||
                                                                user?.role ==
                                                                    1) && (
                                                                <li className="">
                                                                    <a
                                                                        // href={`http://${hostname}/admin/livestream`}
                                                                        onClick={(
                                                                            e
                                                                        ) => {
                                                                            redirectToLaravel(
                                                                                `${hostname}/admin/livestream`
                                                                            );
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src="https://images.ctfassets.net/1itkm9rji8jb/6lyyozpxIzbB1ESrcbFC1v/27d14aac52adc9aaada713d7b48f7b22/ico-stream.svg"
                                                                            alt=""
                                                                        />
                                                                        <span>
                                                                            Live
                                                                            Stream
                                                                        </span>
                                                                    </a>
                                                                </li>
                                                            )}
                                                            {(permissions?.locations ||
                                                                user?.role ==
                                                                    1) && (
                                                                <li className="">
                                                                    <a
                                                                        // href={`http://${hostname}/admin/livestream`}
                                                                        onClick={(
                                                                            e
                                                                        ) => {
                                                                            redirectToLaravel(
                                                                                `${hostname}/admin/locations`
                                                                            );
                                                                        }}
                                                                    >
                                                                        <MapPin
                                                                            color="#fff"
                                                                            style={{
                                                                                marginBottom: 9,
                                                                            }}
                                                                            size={
                                                                                26
                                                                            }
                                                                        />
                                                                        <span>
                                                                            Locations
                                                                        </span>
                                                                    </a>
                                                                </li>
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </>
                                            {/* )} */}
                                        </div>
                                    </div>
                                </div>
                                <div className="openmobilemenu">
                                    <i className="fa fa-bars"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}
