import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { removeStartingSlash as removeSlash } from "../../utills/RemoveSlash";
import { LucideLink, MoveLeft } from "lucide-react";

export default function UpcomingEvent() {
  const location = useLocation();
  const [eventData, setEvenetData] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state) {
      setEvenetData(location.state);
    }
  }, [location.state]);

  // const hostname='redefine.church'
  const hostname=process.env.REACT_APP_BASE_URL

  return (
    // <section className="profilemain1 contactcenterBox">
      <div className="container" style={{minHeight:'95vh'}} >
        <div className="row">
          <div className="col-md-12 contactcenterBoxwrap">
            <div className="d-flex col-md-12">
              <div
                onClick={() => {
                  navigate(-1);
                }}
                className="col-md-2"
                style={{ cursor: "pointer" }}
              >
                <MoveLeft
                  style={{ marginTop: 50 }}
                  size={30}
                  color="#ffffff"
                  fill="#ffffff"
                />
              </div>
              <div className="contactcenterBoxwrapInner col-md-8">
                <div className="contactcenterBoxImage">
                  <img
                    style={{ height: 230, width: "100%" }}
                    src={
                      eventData?.series_banner !== null
                        ? 
                          `${hostname}/${removeSlash(
                            eventData?.series_banner
                          )}`
                        : "https://images.ctfassets.net/1itkm9rji8jb/5ryfKZSVztxUEOGdVIOfr7/8841faa0824e7a1f3339ac7d954274c9/bannermain.png"
                    }
                    alt=""
                  />
                  <div className="evebtdate">
                    <span style={{ fontSize: 14,color:'#000' }}>
                      {eventData?.event_date}
                    </span>
                  </div>
                </div>
                <div className="contactimagecont">
                  <h3>
                    {eventData?.event_name}{" "}
                    {/* <span>527 people going</span> */}
                  </h3>
                  <h5>{eventData?.event_time}</h5>
                  <div className="conp">
                    <p>
                      {eventData?.event_desc}
                    </p>
                  </div>
                  <div className="btnconect4">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    // </section>
  );
}
