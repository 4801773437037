import React, { useState } from "react";
import { Ban, ChevronDown, CircleAlert, Clock4, Laugh } from "lucide-react";
import moment from "moment";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { ReactionBarSelector } from "@charkour/react-reactions";
import { PhotoView } from "react-photo-view";
import { useLocation } from "react-router-dom";

const ChatBubble = React.memo(
    ({
        item,
        user,
        chat,
        hostname,
        removeStartingSlash,
        placeholder,
        delMsg,
        setEditMsg,
        visibleReactions,
        setVisibleReactions,
        handleReactionApi,
    }) => {
        const [isHovered, setIsHovered] = useState(false);
        const [anchorEl, setAnchorEl] = useState(null);

        const isSender = item?.sender_user_id === user?.id;
        const isDeleted = item?.deleted_at !== null;
        const isGroup = chat?.type === "group";
        const isMenuOpen = Boolean(anchorEl);

        const { pathname } = useLocation();

        const [hoverReaction, setHoverReaction] = useState(false);

        const handleMenuClick = (event) => setAnchorEl(event.currentTarget);

        const handleMenuClose = () => setAnchorEl(null);

        const reactions = {
            1: "👍",
            2: "❤️",
            3: "🙏",
            4: "🙌",
            5: "🔥",
            6: "💧",
        };

        const uniqueReactionIds = [
            ...new Set(item?.reactions.map((r) => r.reaction_id)),
        ];

        return (
            <div
                className={`d-flex mb-2 ${
                    isSender ? "justify-content-end" : "justify-content-start"
                }`}
                onMouseEnter={() => setHoverReaction(true)}
                onMouseLeave={() => {
                    setIsHovered(false);
                    setHoverReaction(false);
                }}
                onClick={() => {
                    setVisibleReactions("");
                }}
            >
                <div
                    className={`d-flex gap-2 align-items-end  ${
                        pathname === "/chat"
                            ? "chat-bubble"
                            : "chatHead-chat-bubble"
                    }`}
                >
                    {isGroup && !isSender && (
                        <img
                            src={
                                item?.profile_pic
                                    ? `${hostname}/${removeStartingSlash(
                                          item?.profile_pic
                                      )}`
                                    : placeholder
                            }
                            style={{
                                height: 20,
                                width: 20,
                                borderRadius: 50,
                                objectFit: "cover",
                            }}
                        />
                    )}

                    <div
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        className="d-flex flex-column"
                        style={{ width: "100%" }}
                    >
                        {isGroup && !isSender && (
                            <small className="text-white-50 mb-1">
                                {item?.username}
                            </small>
                        )}

                        <div
                            className="d-flex flex-column"
                            // style={{ position: "relative" }}
                        >
                            {/* Material UI Menu */}
                            <Menu
                                sx={{
                                    "& .MuiPaper-root": {
                                        backgroundColor: "#393737",
                                        color: "#fff",
                                        borderRadius: "8px",
                                        boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
                                        border: "1 px solid #e0e0e0",
                                    },
                                }}
                                disableScrollLock={true}
                                anchorEl={anchorEl}
                                open={isMenuOpen}
                                onClose={handleMenuClose}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                PaperProps={{
                                    elevation: 3,
                                    sx: {
                                        borderRadius: "8px",
                                        minWidth: "160px",
                                        marginTop: "4px",
                                    },
                                }}
                            >
                                <MenuItem
                                    onClick={() => {
                                        setEditMsg({
                                            msg: item?.content
                                                ? item?.content
                                                : "",
                                            id: item?.id,
                                        });
                                        handleMenuClose();
                                    }}
                                >
                                    <ListItemText>Edit</ListItemText>
                                </MenuItem>
                                {isSender && !isDeleted && (
                                    <MenuItem
                                        onClick={() => {
                                            delMsg(item?.id);
                                            handleMenuClose();
                                        }}
                                        sx={{ color: "error.main" }}
                                    >
                                        <ListItemText>Delete</ListItemText>
                                    </MenuItem>
                                )}
                            </Menu>

                            {/* Message Content */}
                            <div
                                className="d-flex align-items-center gap-2"
                                style={{
                                    flexDirection: isSender
                                        ? "row-reverse"
                                        : "row",
                                    position: "relative",
                                }}
                            >
                                <div
                                    className={`${
                                        isSender ? "bg-primary" : "bg-secondary"
                                    } text-white `}
                                    style={{
                                        padding: "6px 12px",
                                        borderRadius: 14,
                                        borderBottomLeftRadius: !isSender
                                            ? 0
                                            : 14,
                                        borderBottomRightRadius: isSender
                                            ? 0
                                            : 14,
                                        position: "relative",
                                        wordWrap: "break-word",
                                        overflow: "hidden",
                                    }}
                                >
                                    {(item?.attachment_file ||
                                        item?.offlineImg) &&
                                        item?.deleted_at == null && (
                                            <PhotoView
                                                src={
                                                    item?.attachment_file
                                                        ? `${hostname}/${removeStartingSlash(
                                                              item?.attachment_file
                                                          )}`
                                                        : item?.offlineImg
                                                }
                                            >
                                                <img
                                                    src={
                                                        item?.attachment_file
                                                            ? `${hostname}/${removeStartingSlash(
                                                                  item?.attachment_file
                                                              )}`
                                                            : item?.offlineImg
                                                    }
                                                    style={{
                                                        width: "100%",
                                                        maxHeight: "40vh",
                                                        objectFit: "contain",
                                                        borderRadius: 10,
                                                    }}
                                                />
                                            </PhotoView>
                                        )}
                                    <p
                                        className={`mb-1 ${
                                            isDeleted
                                                ? "underline"
                                                : "text-white"
                                        }`}
                                        style={{
                                            marginRight: 18,
                                            color: isDeleted
                                                ? "#121212"
                                                : "#fff",
                                        }}
                                    >
                                        {isDeleted && (
                                            <Ban
                                                size={16}
                                                className="inline mr-1"
                                            />
                                        )}
                                        {isDeleted
                                            ? " message deleted"
                                            : item.content}
                                        {item?.status === "pending" &&
                                            !isDeleted && (
                                                <Clock4
                                                    size={16}
                                                    className="inline ml-1"
                                                />
                                            )}
                                        {item?.status === "failed" &&
                                            !isDeleted && (
                                                <CircleAlert
                                                    size={16}
                                                    className="inline ml-1 text-red-500"
                                                />
                                            )}
                                    </p>
                                    <small className="text-white-50">
                                        {/* {moment(item?.created_at).format(
                                            "hh:mm A"
                                        )} */}
                                        {moment
                                            .utc(
                                                item?.created_at,
                                                "YYYY-MM-DD HH:mm:ss"
                                            )
                                            .local()
                                            .format("hh:mm A")}
                                    </small>
                                    {isSender && isHovered && !isDeleted && (
                                        <ChevronDown
                                            onClick={handleMenuClick}
                                            style={{
                                                position: "absolute",
                                                right: 6,
                                                top: 4,
                                            }}
                                            color="#3c3c3c"
                                        />
                                    )}
                                    {/* {visibleReactions == item.id && (
                                        <ReactionBarSelector
                                            iconSize={30}
                                            onSelect={(key) => {
                                                handleReactionApi(item.id, key);
                                                setTimeout(() => {
                                                    setVisibleReactions("");
                                                }, 200);
                                            }}
                                            reactions={[
                                                {
                                                    label: "like",
                                                    node: <div>👍</div>,
                                                    key: "1",
                                                },
                                                {
                                                    label: "love",
                                                    node: <div>❤️</div>,
                                                    key: "2",
                                                },
                                                {
                                                    label: "prayer",
                                                    node: <div>🙏</div>,
                                                    key: "3",
                                                },
                                                {
                                                    label: "praise",
                                                    node: <div>🙌</div>,
                                                    key: "4",
                                                },
                                                {
                                                    label: "fire",
                                                    node: <div>🔥</div>,
                                                    key: "5",
                                                },
                                                {
                                                    label: "water",
                                                    node: <div>💧</div>,
                                                    key: "6",
                                                },
                                            ]}
                                            style={{
                                                position: "absolute",
                                                top: "-55px",
                                                right: isSender ? 10 : "unset",
                                                left: isSender ? "unset" : 10,
                                                backgroundColor: "#292626",
                                                zIndex: 2,
                                            }}
                                        />
                                    )} */}
                                </div>
                                {visibleReactions == item.id && (
                                    <ReactionBarSelector
                                        iconSize={30}
                                        onSelect={(key) => {
                                            handleReactionApi(item.id, key);
                                            setTimeout(() => {
                                                setVisibleReactions("");
                                            }, 200);
                                        }}
                                        reactions={[
                                            {
                                                label: "like",
                                                node: <div>👍</div>,
                                                key: "1",
                                            },
                                            {
                                                label: "love",
                                                node: <div>❤️</div>,
                                                key: "2",
                                            },
                                            {
                                                label: "prayer",
                                                node: <div>🙏</div>,
                                                key: "3",
                                            },
                                            {
                                                label: "praise",
                                                node: <div>🙌</div>,
                                                key: "4",
                                            },
                                            {
                                                label: "fire",
                                                node: <div>🔥</div>,
                                                key: "5",
                                            },
                                            {
                                                label: "water",
                                                node: <div>💧</div>,
                                                key: "6",
                                            },
                                        ]}
                                        style={{
                                            position: "absolute",
                                            top: "-55px",
                                            right: isSender ? 10 : "unset",
                                            left: isSender ? "unset" : 10,
                                            backgroundColor: "#292626",
                                            zIndex: 2,
                                        }}
                                    />
                                )}

                                {!isDeleted && (
                                    <div className="d-flex flex-column align-items-center justify-content-center">
                                        <Laugh
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setVisibleReactions(
                                                    visibleReactions == item.id
                                                        ? ""
                                                        : item.id
                                                );
                                            }}
                                            size={16}
                                            color={
                                                hoverReaction
                                                    ? "#fff"
                                                    : "transparent"
                                            }
                                            style={{
                                                pointerEvents: !hoverReaction
                                                    ? "none"
                                                    : "all",
                                                cursor: "pointer",
                                            }}
                                        />
                                    </div>
                                )}
                            </div>

                            {item?.reactions && item.reactions?.length > 0 && (
                                <div
                                    className="d-flex"
                                    style={{
                                        // position: "absolute",
                                        // backgroundColor: "#606060",
                                        backgroundColor: "#000",
                                        padding: 4,
                                        borderRadius: 20,
                                        alignSelf: isSender
                                            ? "flex-end"
                                            : "flex-start",
                                        marginTop: -4,
                                        marginRight: 10,
                                        zIndex: 1,
                                    }}
                                >
                                    {uniqueReactionIds?.map((reactionId) => {
                                        return (
                                            <span style={{ fontSize: 14 }}>
                                                {reactions[reactionId]}
                                            </span>
                                        );
                                    })}
                                    <span
                                        style={{ fontSize: 14, marginLeft: 2 }}
                                    >
                                        {item?.reactions?.length}
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
);

export default ChatBubble;
