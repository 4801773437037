import React, { useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { X } from "lucide-react";
import { postRequest } from "../../utills/requests";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { submitRequest } from "../../store/slices/bodyStyling";
import { Circles } from "react-loader-spinner";

const CardForm = ({ showCardModal, requestGiving }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [name, setName] = useState("");
  const user = useSelector((state) => state.login.userData);
  const givingData = useSelector((state) => state.giving.givingData);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    if (loading) return;
    setLoading(true);

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);
    const { error, token } = await stripe.createToken(cardElement);

    if (error) {
      console.log("[error]", error);
      setLoading(false);
    } else {
      console.log("[PaymentMethod]", token);
      checkCardExists(token.id);
    }
  };

  const checkCardExists = async (token) => {
    const data = {
      customer_name: name,
      customer_email: user?.email,
      customer_stripe_id: givingData?.giving?.userCustomer?.stripe_customer_id || '',
      stripeToken: token,
    };
    try {
      const response = await postRequest({
        endpoint: "/checkCardExist",
        payload: data,
      });
      console.log("EXIST>", response.data);
      if (response?.data?.response) {
        toast.error("Card already exist.", { duration: 3000 });
        setLoading(false);
        return;
      }
      addCard(data);
    } catch (error) {
      setLoading(false);
      console.log("ERR>", error);
    }
  };

  const addCard = async (data) => {
    try {
      const response = await postRequest({
        endpoint: "/addNewCard",
        payload: data,
      });
      console.log("RESP>", response.data);
      toast.success("Payment method added successfully", { duration: 3000 });
      showCardModal(false)
      dispatch(submitRequest(true))
      requestGiving();
    } catch (error) {
      console.log("ERR>", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="confirmation-dialog"
      style={{
        backgroundColor: "#1F2020",
        borderRadius: 10,
        padding: 20,
      }}
    >
      <div className="d-flex justify-content-between">
        <h4 style={{ fontSize: 20, color: "#fff" }}>Add New Card</h4>
        <X
          onClick={() => showCardModal(false)}
          size={20}
          fill="#8F9090"
          color="#8F9090"
          strokeWidth={5}
          style={{ cursor: "pointer" }}
        />
      </div>
      <div className="d-flex flex-column mt-4 gap-4">
        <div className="d-flex col-md-12 inputCont align-items-center">
          <input
            className="givingInput2"
            value={name}
            type="text"
            min={0}
            onChange={(e) => setName(e.target.value)}
            placeholder="Name on Card"
            style={{ padding: 10 }}
          />
        </div>
        <CardElement
          // options={{ style: { base: { fontSize: "16px" } } }}
          id="card-element"
          options={{
            style: {
              base: {
                color: "#fff", // Set the color of the input text to white
                "::placeholder": {
                  color: "#fff", // Set the color of the input placeholder text to white
                },
              },
            },
          }}
        />
      </div>

      <div
        className="d-flex align-items-center justify-content-end mt-3"
        style={{ columnGap: 4 }}
      >
        <button
          onClick={() => handleSubmit()}
          style={{
            backgroundColor: "#28a745",
            color: "#fff",
            padding: 6,
            paddingInline: 10,
            borderRadius: 5,
          }}
        >
          {loading ? (
            <Circles
              height="20"
              width="20"
              color="#fff"
              ariaLabel="circles-loading"
              wrapperClass=""
              visible={true}
            />
          ) : (
            "Add"
          )}
        </button>

        <button
          onClick={() => showCardModal(false)}
          style={{
            backgroundColor: "#dc3545",
            color: "#fff",
            padding: 6,
            borderRadius: 5,
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default CardForm;
