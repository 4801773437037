import React, { useCallback, useEffect, useRef, useState } from "react";
import moment from "moment";
import { Send, MoveLeft } from "lucide-react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import placeholder from "../../assets/images/placeholder.png";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { requestingPost } from "../../store/slices/connectSlice.js";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { PhotoView } from "react-photo-view";
import { removeStartingSlash as removeSlash } from "../../utills/RemoveSlash.js";
import { database } from "../../firebase/firebaseConfig.js";
import { ref, onChildAdded, off, onValue } from "firebase/database";
import LikeBar from "../../components/Reactions/LikeBar.js";
import ReactionBar from "../../components/Reactions/ReactionBar.js";
// XHR
import XHR from "../../utills/XHR.js";

export default function Post() {
  const location = useLocation();
  const navigate = useNavigate();
  const [comment, setComment] = useState("");
  const [reply, setReply] = useState("");
  const [replyingTo, setReplyingTo] = useState(null);
  const [data, setData] = useState({});
  const postLoader = useSelector((state) => state.connect.postLoader);
  const userDetails = useSelector((state) => state.login.userProfile);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.login.userData);

  const [repliesVisibility, setRepliesVisibility] = useState({});
  // Function to toggle visibility of replies for a specific comment ID
  const toggleReplies = (itemId) => {
    setRepliesVisibility((prevState) => ({
      ...prevState,
      [itemId]: !prevState[itemId], // Toggle visibility for the specific comment ID
    }));
  };

  // const hostname = "redefine.church";
  const hostname=process.env.REACT_APP_BASE_URL

  const bottomRef = useRef(null);
  const replyBottomRef = useRef(null);

  useEffect(() => {
    if (location.state) {
      getPostData(location.state);
    }
  }, [location.state]);

  let newItems = false;

  useEffect(() => {
    // posts reaction listener
    const PostReactionRef = ref(database, "PostReactionChanged");
    onChildAdded(PostReactionRef, handlePostReaction);
    // posts comment listener
    const PostCommentRef = ref(database, "PostCommentAdded");
    onChildAdded(PostCommentRef, handlePostCommment);
    // posts comments reaction listener
    const CommemtReactionRef = ref(database, "PostCommentReactionChanged");
    onChildAdded(CommemtReactionRef, handleCommentReaction);

    // handle initial items
    // onValue(addPostRef, handleInitialItems)
    onValue(CommemtReactionRef, handleInitialItems);

    // return () => {
    //     // off(addPostRef, 'child_added')
    //     off(PostReactionRef, 'child_added')
    //     // off(postUpdateRef, 'child_added')
    // }
  }, []);

  const handleInitialItems = () => {
    newItems = true;
  };

  const handleCommentReaction = (snapshot) => {
    if (!newItems) return;
    if (snapshot.exists()) {
      const e = snapshot.val();
      console.log("REAL TIME COMMENT REACTION>>", e);
      const loggedInUser = user.id;
      if (e) {
        const { commentId, parent_comment_ID, userId, reactionId, action } = e;
        if (parent_comment_ID == null) {
          setData((prev) => {
            const newObj = { ...prev };
            // @ts-ignore
            const commentIndex = newObj.comments.findIndex(
              (comment) => comment.id == commentId
            );
            // check for self user
            if (userId == loggedInUser) {
              // @ts-ignore
              newObj.comments[commentIndex].user_reaction =
                action === "added" || action === "updated" ? reactionId : "";
              return newObj;
            } else {
              // @ts-ignore
              const userReactionIndex = newObj.comments[
                commentIndex
              ].reactions.findIndex((reaction) => reaction.user_id == userId);
              if (userReactionIndex !== -1) {
                //reaction present
                if (action === "deleted") {
                  // for deleting other users reaction obj if unliked
                  // @ts-ignore
                  newObj.comments[commentIndex].reactions.splice(
                    userReactionIndex,
                    1
                  );
                  return newObj;
                } else {
                  // Forupdating other users reaction id
                  // @ts-ignore
                  newObj.comments[commentIndex].reactions[userReactionIndex] = {
                    ...newObj.comments[commentIndex].reactions[
                      userReactionIndex
                    ],
                    ...e,
                    reaction_id: reactionId,
                  };
                  // FOR SELF USER
                  return newObj;
                }
              } else {
                // @ts-ignore
                newObj.comments[commentIndex].reactions = [
                  ...newObj.comments[commentIndex].reactions,
                  {
                    ...e,
                    reaction_id: reactionId,
                    user_id: userId,
                    comment_id: commentId,
                  },
                ];
                return newObj;
              }
            }
          });
        } else {
          // FOR updating reacltion on REPLIES
          setData((prev) => {
            const newObj = { ...prev };
            // @ts-ignore
            const commentIndex = newObj.comments.findIndex(
              (comment) => comment.id == parent_comment_ID
            );
            // check for self user
            if (userId == loggedInUser) {
              // @ts-ignore
              const replyCommentIndex = newObj.comments[
                commentIndex
              ].replies.findIndex((reply) => reply.id == commentId);
              // @ts-ignore
              newObj.comments[commentIndex].replies[
                replyCommentIndex
              ].user_reaction =
                action === "added" || action === "updated" ? reactionId : "";
              return newObj;
              // done
            } else {
              // @ts-ignore
              const replyCommentIndex = newObj.comments[
                commentIndex
              ].replies.findIndex((reply) => reply.id == commentId);
              // @ts-ignore
              const userReactionIndex = newObj.comments[commentIndex].replies[
                replyCommentIndex
              ].reactions.findIndex((reaction) => reaction.user_id == userId);

              if (userReactionIndex !== -1) {
                //reaction present
                if (action === "deleted") {
                  // for deleting other users reaction obj if unliked
                  // @ts-ignore
                  newObj.comments[commentIndex].replies[
                    replyCommentIndex
                  ].reactions.splice(userReactionIndex, 1);
                  return newObj;
                } else {
                  // For updating other users reaction id
                  // @ts-ignore
                  newObj.comments[commentIndex].replies[
                    replyCommentIndex
                  ].reactions[userReactionIndex] = {
                    ...newObj.comments[commentIndex].replies[replyCommentIndex]
                      .reactions[userReactionIndex],
                    ...e,
                    reaction_id: reactionId,
                  };
                  // FOR SELF USER
                  return newObj;
                }
              } else {
                // @ts-ignore
                newObj.comments[commentIndex].replies[
                  replyCommentIndex
                ].reactions = [
                  ...newObj.comments[commentIndex].replies[replyCommentIndex]
                    .reactions,
                  {
                    ...e,
                    reaction_id: reactionId,
                    user_id: userId,
                    comment_id: commentId,
                  },
                ];
                return newObj;
              }
            }
          });
        }
      }
    }
  };

  const handlePostCommment = (snapshot) => {
    if (!newItems) return;
    if (snapshot.exists()) {
      const e = snapshot.val();
      console.log("REAL TIME COMMENT UPDATE", e);
      if (e.is_reply == 0) {
        //for comment
        if (e?.user_id == user?.id) {
          setData((prev) => {
            const comments = prev.comments;
            const tempComments = comments.map((item) =>
              item?.id == e.temp_id
                ? {
                    ...item,
                    status: "posted",
                    CommentId: e.CommentId,
                    id: Number(e.CommentId),
                    created_at: e.created_at,
                  }
                : item
            );
            return { ...prev, comments: tempComments };
          });
        } else {
          setData((prev) => {
            const comments = [
              ...prev.comments,
              {
                ...e,
                replies: [],
                reactions: [],
                id: Number(e.CommentId),
                user_reaction: "",
              },
            ];
            const newObj = { ...prev, comments };
            return newObj;
          });
        }
      } else {
        //for replies
        if (e?.user_id == user?.id) {
          setData((prev) => {
            /* @ts-ignore*/
            const commentIndex = prev.comments.findIndex(
              (comment) => comment.id == e.parent_comment_ID
            );
            let newObj = { ...prev };
            if (commentIndex !== -1) {
              /* @ts-ignore*/
              const commentReplies = prev.comments[commentIndex].replies;
              const tempReplies = commentReplies.map((item) =>
                item.id == e.temp_id
                  ? {
                      ...item,
                      status: "posted",
                      CommentId: e.CommentId,
                      id: Number(e.CommentId),
                      created_at: e.created_at,
                    }
                  : item
              );
              /* @ts-ignore*/
              newObj.comments[commentIndex].replies = tempReplies;
              return newObj;
            }
            return prev;
          });
        } else {
          setData((prev) => {
            /* @ts-ignore*/
            const commentIndex = prev.comments.findIndex(
              (comment) => comment.id == e.parent_comment_ID
            );
            const reply = {
              ...e,
              user_reaction: "",
              reactions: [],
              id: Number(e.CommentId),
            };
            let newObj = { ...prev };
            if (commentIndex !== -1) {
              /* @ts-ignore*/
              const replies = [...prev.comments[commentIndex].replies, reply];
              /* @ts-ignore*/
              newObj.comments[commentIndex].replies = replies;
              return newObj;
            }
            return prev;
          });
        }
      }
    }
  };

  const handlePostReaction = (snapshot) => {
    if (!newItems) return;
    if (snapshot.exists()) {
      const e = snapshot.val();
      console.log("REAL TIME LIKE>>", e);
      if (e) {
        const { postId, userId } = e;
        setData((prevPost) =>
          handleReactionChange(prevPost, e, postId, userId)
        );
      }
    }
  };

  /*@ts-ignore */
  const handleReactionChange = (prevPost, e, postId, userId) => {
    const loggedInUser = localStorage.getItem("userId");
    if (prevPost.id == postId) {
      const updatedReactions = [...prevPost.reactions];
      const existingReactionIndex = updatedReactions.findIndex(
        (reaction) => reaction.user_id == userId
      );

      if (existingReactionIndex !== -1) {
        updatedReactions.splice(existingReactionIndex, 1);
        return {
          ...prevPost,
          reactions: updatedReactions,
          is_reacted: userId == loggedInUser ? false : prevPost.is_reacted,
        };
      } else {
        const newReaction = {
          reaction_id: e.reactionId,
          post_id: postId,
          user_id: userId,
        };
        return {
          ...prevPost,
          reactions: [...updatedReactions, newReaction],
          is_reacted: userId == loggedInUser ? true : prevPost.is_reacted,
        };
      }
    } else {
      return prevPost;
    }
  };

  const getPostData = (postId) => {
    dispatch(requestingPost(true));
    try {
      const options = {
        headers: {
          "Content-Type": "multipart-formdata",
          Authorization: `Bearer ${user.api_token}`,
        },
        method: "GET",
      };
      XHR(
        `${hostname}/api/v1/getPostDetails?post_id=${postId}`,
        options
      )
        .then((resp) => {
          if (resp.data.success) {
            setData(resp.data.post);
          }
        })
        .catch((e) => {
          console.log("ERR>", e);
        })
        .finally(() => {
          dispatch(requestingPost(false));
        });
    } catch (error) {}
  };

  const sendComment = (check, commentId) => {
    // UNCOMMENT THIS
    const tempId = new Date().getTime();
    const createdAt = moment(tempId).format("YYYY-MM-DD HH:mm:ss");

    const formdata = new FormData();
    formdata.append("comment_image", "null");
    formdata.append("post_id", data.id);
    formdata.append("user_id", user.id);
    formdata.append("comment_content", check === "comment" ? comment : reply);
    formdata.append("is_reply", check === "comment" ? 0 : 1);
    formdata.append("parent_comment_ID", commentId);
    formdata.append("temp_id", tempId);

    if (check === "comment") {
      setData((prev) => {
        const newComment = {
          CommentId: tempId,
          id: Number(tempId),
          address: userDetails.address ? userDetails.address : "",
          comment_content: comment,
          comment_image: "",
          created_at: createdAt,
          parent_comment_ID: check === "comment" ? "null" : commentId,
          post_id: data.id,
          is_reply: check === "comment" ? 0 : 1,
          profile_pic: userDetails.profile_pic,
          user_id: user.id,
          username: userDetails.username,
          replies: [],
          reactions: [],
          user_reaction: "",
          status: "posting",
        };
        const newComments = [...prev.comments, newComment];
        return { ...prev, comments: newComments };
      });
      setComment("");
      if (bottomRef.current) {
        setTimeout(() => {
          bottomRef.current.scrollIntoView({ behavior: "smooth" });
        }, 100);
      }
    } else {
      // For reply
      setData((prev) => {
        const newReply = {
          CommentId: tempId,
          id: Number(tempId),
          address: userDetails.address ? userDetails.address : "",
          comment_content: reply,
          comment_image: "",
          created_at: createdAt,
          parent_comment_ID: commentId,
          post_id: data.id,
          is_reply: 1,
          profile_pic: userDetails.profile_pic,
          user_id: user.id,
          username: user.username,
          replies: [],
          reactions: [],
          user_reaction: "",
          status: "posting",
        };
        let newObj = { ...prev };
        const commentIndex = prev.comments.findIndex(
          (comment) => comment.id == commentId
        );
        if (commentIndex !== -1) {
          const replies = [...prev.comments[commentIndex].replies, newReply];
          newObj.comments[commentIndex].replies = replies;
          return newObj;
        }
        return prev;
      });
      setReply("");
      if (replyBottomRef.current) {
        setTimeout(() => {
          replyBottomRef.current.scrollIntoView({
            behavior: "smooth",
            block: "end",
          });
        }, 100);
      }
    }

    const options = {
      headers: {
        "Content-Type": "multipart-formdata",
        Authorization: `Bearer ${user.api_token}`,
      },
      method: "POST",
      data: formdata,
    };
    XHR(`${hostname}/api/v1/addPostComment`, options)
      .then((resp) => {
        if (resp.data.success) {
          setComment("");
          setReply("");
          toast.success(resp.data.message);
        }
      })
      .catch((e) => {
        console.log("ERR>", e);
        if (check === "comment") {
          setData((prev) => {
            const comments = prev.comments;
            const tempComments =
              comments &&
              comments.map((item) =>
                item?.id == tempId ? { ...item, status: "failed" } : item
              );
            return { ...prev, comments: tempComments };
          });
        } else {
        }
      })
      .finally(() => {});
  };

  return (
    // <section className="profilemain1 contactcenterBox">
    <div className="container" style={{ minHeight: "95vh" }}>
      <div className="row">
        <div className="col-md-12 contactcenterBoxwrap">
          <div className="d-flex col-md-12">
            <div
              onClick={() => {
                navigate(-1);
              }}
              className="col-md-2"
              style={{ cursor: "pointer" }}
            >
              <MoveLeft
                style={{ marginTop: 50 }}
                size={30}
                color="#ffffff"
                fill="#ffffff"
              />
            </div>
            <div className="mindUploadPost hasboxbg col-md-8">
              <div className="uoloadtextmain">
                <div className="uoloadtext">
                  <div className="profileimg">
                    {postLoader ? (
                      <Skeleton
                        baseColor="#716e6e"
                        circle
                        height={50}
                        width={50}
                      />
                    ) : (
                      <img
                        src={
                          data?.profile_pic !== null
                            ? `${hostname}/${removeSlash(
                                data?.profile_pic
                              )}`
                            : placeholder
                        }
                        alt=""
                      />
                    )}
                  </div>
                  <div className="profileText">
                    {postLoader ? (
                      <Skeleton
                        baseColor="#716e6e"
                        count={2}
                        height={8}
                        width={100}
                      />
                    ) : (
                      <>
                        <h4> {data?.username}</h4>
                      </>
                    )}
                  </div>
                  {postLoader ? (
                    <Skeleton className="tag" baseColor="#716e6e" />
                  ) : (
                    <span className="tag">{data?.post_type}</span>
                  )}
                </div>
                <div className="comenttag">
                  {postLoader ? (
                    <Skeleton className="time" baseColor="#716e6e" width={80} />
                  ) : (
                    <span className="time">
                      {data?.updated_at !== null && "edited "}
                      {moment(data?.created_at).format("hh:mm A")}
                    </span>
                  )}
                </div>
              </div>
              <div className="postContent">
                {postLoader ? (
                  <>
                    <Skeleton
                      baseColor="#716e6e"
                      height={10}
                      width={200}
                      style={{ marginTop: 10 }}
                    />
                    <Skeleton baseColor="#716e6e" height={10} width={300} />
                    <Skeleton
                      baseColor="#716e6e"
                      height={10}
                      width={400}
                      style={{ marginBottom: 10 }}
                    />
                  </>
                ) : (
                  <p style={{ marginBlock: 10 }}>{data?.post_content}</p>
                )}
                {postLoader ? (
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ overflow: "hidden" }}
                  >
                    <Skeleton baseColor="#716e6e" height={350} width={550} />
                  </div>
                ) : (
                  data?.post_img && (
                    <div className="d-flex align-items-center justify-content-center">
                      <PhotoView
                        src={`${hostname}/${removeSlash(
                          data?.post_img
                        )}`}
                      >
                        <img
                          style={{ maxHeight: 300, maxWidth: "100%" }}
                          src={`${hostname}/${removeSlash(
                            data?.post_img
                          )}`}
                        />
                      </PhotoView>
                    </div>
                  )
                )}
              </div>
              <LikeBar postData={data} edit={true} getPostData={getPostData} />
              <div className="cahtListcomments">
                <ul>
                  {data?.comments?.map((item) => {
                    const isReplying = replyingTo === item.id;
                    return (
                      <li key={item.id}>
                        <div className="userProfileicon col-md-1 ">
                          <img
                            src={
                              item?.profile_pic
                                ? `${hostname}/${removeSlash(
                                    item?.profile_pic
                                  )}`
                                : placeholder
                            }
                            alt=""
                          />
                        </div>
                        <div style={{ width: "100%" }} className="usercomment">
                          <div className="comentbox">
                            <h5>{item.username}</h5>

                            <p className="comment-content">
                              {item.comment_content}
                            </p>
                          </div>
                          {item?.status && item.status == "posting" ? (
                            <div className="userActins">
                              <div className="timecoments">
                                <span>Posting...</span>
                              </div>
                            </div>
                          ) : item.status == "failed" ? (
                            <div className="userActins">
                              <div className="timecoments">
                                <span>Failed...</span>
                              </div>
                            </div>
                          ) : (
                            <div className="userActins">
                              <div className="timecoments">
                                <span>{moment(item.created_at).fromNow()}</span>
                              </div>
                              <ReactionBar
                                commentData={item}
                                reply={() =>
                                  setReplyingTo(isReplying ? null : item.id)
                                }
                              />
                            </div>
                          )}
                          {isReplying && (
                            <div
                              style={{
                                margin: "2%",
                                marginBottom: 0,
                                columnGap: 10,
                              }}
                              className="d-flex align-items-center"
                            >
                              <input
                                placeholder="reply here"
                                style={{
                                  backgroundColor: "transparent",
                                  color: "#ffffff",
                                  width: "30%",
                                  padding: 5,
                                  border: "unset",
                                  // outline:
                                  //     'none',
                                }}
                                value={reply}
                                onChange={(e) => {
                                  setReply(e.target.value);
                                }}
                              />
                              <Send
                                onClick={() => {
                                  if (reply.trim().length > 0) {
                                    sendComment("reply", item.id);
                                  }
                                }}
                                size={30}
                                color="#3478FF"
                              />
                            </div>
                          )}
                          {/* REPLIESS */}
                          {item.replies.length > 0 && (
                            <div className="d-flex">
                              <p
                                onClick={() => toggleReplies(item.id)}
                                style={{
                                  margin: 0,
                                  color: "#6c757d",
                                  cursor: "pointer",
                                  fontSize: 14,
                                }}
                              >
                                {repliesVisibility[item.id]
                                  ? `Hide replies`
                                  : `View ${
                                      item.replies.length == 1
                                        ? "1 reply"
                                        : `all ${item.replies.length} replies`
                                    }`}
                              </p>
                            </div>
                          )}
                          {repliesVisibility[item.id] && (
                            <div className="replies-cont">
                              <ul className="mt-2">
                                {item.replies.length > 0 &&
                                  item.replies.map((item, index) => {
                                    return (
                                      <li key={item.id}>
                                        <div className="userProfileicon col-md-1 ">
                                          <img
                                            style={{
                                              height: 35,
                                              width: 35,
                                            }}
                                            src={
                                              item?.profile_pic
                                                ? `${hostname}/${removeSlash(
                                                    item?.profile_pic
                                                  )}`
                                                : placeholder
                                            }
                                            alt=""
                                          />
                                        </div>
                                        <div
                                          style={{
                                            width: "100%",
                                          }}
                                          className="usercomment"
                                        >
                                          <div
                                            style={{
                                              padding: 8,
                                            }}
                                            className="comentbox"
                                          >
                                            <h5
                                              style={{
                                                fontSize: 12,
                                                marginBottom: 0,
                                              }}
                                            >
                                              {item.username}
                                            </h5>

                                            <p
                                              style={{
                                                fontSize: 12,
                                              }}
                                              className="comment-content"
                                            >
                                              {item.comment_content}
                                            </p>
                                          </div>
                                          {item?.status &&
                                          item.status == "posting" ? (
                                            <div className="userActins">
                                              <div className="timecoments">
                                                <span>Posting...</span>
                                              </div>
                                            </div>
                                          ) : item.status == "failed" ? (
                                            <div className="userActins">
                                              <div className="timecoments">
                                                <span>Failed...</span>
                                              </div>
                                            </div>
                                          ) : (
                                            <div className="userActins">
                                              <div className="timecoments">
                                                <span
                                                  style={{
                                                    fontSize: 10,
                                                  }}
                                                >
                                                  {moment(
                                                    item.created_at
                                                  ).fromNow()}
                                                </span>
                                              </div>
                                              <ReactionBar
                                                hideReply={true}
                                                commentData={item}
                                                reply={() =>
                                                  setReplyingTo(
                                                    isReplying ? null : item.id
                                                  )
                                                }
                                              />
                                            </div>
                                          )}
                                        </div>
                                      </li>
                                    );
                                  })}
                                <div ref={replyBottomRef} />
                              </ul>
                            </div>
                          )}
                        </div>
                      </li>
                    );
                  })}
                  <div ref={bottomRef} />
                </ul>
              </div>
              <div className="d-flex mt-4 align-items-center justify-content-between">
                <input
                  placeholder="Type Your Comment"
                  style={{
                    backgroundColor: "transparent",
                    color: "#ffffff",
                    width: "90%",
                    padding: 5,
                    outline: "none",
                    border: "unset",
                  }}
                  value={comment}
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}
                />
                <Send
                  onClick={() => {
                    if (comment.trim().length > 0) {
                      sendComment("comment", null);
                    }
                  }}
                  size={30}
                  color="#3478FF"
                />
              </div>
            </div>
          </div>
          {/* } */}
        </div>
      </div>
    </div>
    // </section>
  );
}
