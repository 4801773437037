import React from "react";

const OurCode = () => {
  const data = [
    {
      id: 1,
      title: "1. We do what we do because God is who He is",
      descriptions: "It’s about honoring God",
    },
    {
      id: 2,
      title: "2. We love our neighbor because God first loved us",
      descriptions: "It’s about honoring People",
    },
    {
      id: 3,
      title: "3. We Believe In Maximizing Resources",
      descriptions: "It’s about being grateful for provision",
    },
    {
      id: 4,
      title: "4. We Believe In Focused Excellence",
      descriptions: "It’s about it being worth our best",
    },
    {
      id: 5,
      title: "5. We Are Contributors, not Consumers",
      descriptions: "It’s about being the church",
    },
    {
      id: 6,
      title: "6. We Believe In Defined Authority",
      descriptions: "It’s about trusting God and empowering people",
    },
    {
      id: 7,
      title: "7. We Embrace limitations",
      descriptions: "It’s about wisdom",
    },
    {
      id: 8,
      title: "8. We Value Teachable Attitudes",
      descriptions: "It’s about humility",
    },
    {
      id: 9,
      title: "9. We don’t maintain, we multiply",
      descriptions: "It’s about growth",
    },
    {
      id: 10,
      title: "10. We are who God has called us to be",
      descriptions: "It’s about authenticity",
    },
  ];

  return (
    <div className="our-code ">
      <div className="d-flex flex-column mb-4">
        <h4>Our Vision Statement</h4>
        <h2>See that God is making all things new.</h2>
      </div>
      <div className="d-flex flex-column mb-4">
        <h4>Our Mission Statement</h4>
        <h2>
          Our church exists so that the broken would be redefined to the
          restored through Jesus Christ.
        </h2>
      </div>

      <h2 className="mb-3">Our Code</h2>

      {data.map((item) => {
        return (
          <div
            key={item.id}
            style={{
                backgroundColor:'rgb(23 23 23 / 30%)',
                borderRadius:15,padding:10,marginBottom:10
            }}
            className="row justify-content-between"
          >
            <div className="col-md-6" >
              <h4 style={{color:'#fff',opacity:1}} >{item.title}</h4>
            </div>
            <div className="col-md-6">
              <p style={{opacity:0.6,color:'#fff',fontSize:24}} >
                <i>{item.descriptions}</i>
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default OurCode;
