import React, { useEffect, useRef } from "react";
import Hls from "hls.js";

const BGVideo = ({ border, src }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (src.endsWith(".m3u8") && Hls.isSupported()) {
      const hls = new Hls();
      hls.loadSource(src);
      hls.attachMedia(videoRef.current);
    } else {
      videoRef.current.src = src;
    }
  }, [src]);

  return (
    <video
      ref={videoRef}
      autoPlay
      loop
      muted
      style={{
        position: "absolute",
        width: "101%",
        height: "101%",
        objectFit: "cover",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        borderRadius: border,
      }}
    />
  );
};

export default BGVideo;
