import React, { useRef } from "react";

const SpanComp = ({
    item,
    widgetId,
    default_widget_colors,
    mainDimensions1,
    isVisible,
    universalStyles,
    type,
    isColumn = true,
}) => {
    const spanRef = useRef(null);

    return (
        <span
            ref={spanRef}
            id={`${widgetId.toString()}_headingSpan`}
            role="textbox"
            data-placeholder={`${type}...`}
            style={{
                fontSize: Number(item.fontSize),
                color:
                    item.isDefaultColor && type === "text"
                        ? default_widget_colors.text.color
                        : item.isDefaultColor && type === "heading"
                        ? default_widget_colors.heading.color
                        : item.isDefaultColor && type === "paragraph"
                        ? default_widget_colors.paragraph.color
                        : item.color,
                textDecorationLine: item.isUnderlined ? "underline" : "none",
                fontWeight: item.isBold ? "bold" : "normal",
                fontStyle: item.isItalic ? "italic" : "normal",
                fontFamily:
                    item.family === "default"
                        ? universalStyles.font
                        : item.family || "sans-serif",
                borderColor:
                    widgetId == localStorage.getItem("customId")
                        ? "#BE9494"
                        : "transparent",
                resize:
                    widgetId == localStorage.getItem("customId") &&
                    !isVisible.heading
                        ? "both"
                        : "none",
                backgroundColor: "transparent",
                overflow: "hidden",
                maxHeight: mainDimensions1.height,
                maxWidth: isColumn
                    ? mainDimensions1.width / 3 - 30
                    : mainDimensions1.width,
                height: item.height,
                width: item.width,
                pointerEvents:
                    widgetId == localStorage.getItem("customId") ? "" : "none",
                textAlign: item.direction || "left",
                overflowWrap: "break-word",
            }}
        >
            {item.text}
        </span>
    );
};

export default SpanComp;
