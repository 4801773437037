import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  watchData: {},
  currentSelectedEventData: {},
  selectedStreamId: null,
  noteText: "",
  liveSreamChat: [],
  sermonsData: {},
  liveEvent: {},
  // new
  selectedEventDetails: {},
  eventId: "",
  seriesId: "",
};

const slice = createSlice({
  name: "watch",
  initialState,
  reducers: {
    setWatchData(state, action) {
      state.watchData = action.payload;
    },
    setCurrentSelectedEvent(state, action) {
      state.currentSelectedEventData = action.payload;
    },
    setSelectedStreamID(state, action) {
      state.selectedStreamId = action.payload;
    },
    setEventNoteText(state, action) {
      state.noteText = action.payload;
    },
    setLiveStreamChat(state, action) {
      state.liveSreamChat = action.payload;
    },
    setSermonsData(state, action) {
      state.sermonsData = action.payload;
    },
    setLiveEvent(state, action) {
      state.liveEvent = action.payload;
    },

    // new
    setSelectedEventDeatails(state, action) {
      state.selectedEventDetails = action.payload;
    },
    setSeriesId(state, action) {
      state.seriesId = action.payload;
    },
    setEventId(state, action) {
      state.eventId = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
const actions = slice.actions;

export const {
  setWatchData,
  setCurrentSelectedEvent,
  setSelectedStreamID,
  setEventNoteText,
  setLiveStreamChat,
  setSermonsData,
  setLiveEvent,
  // new
  setSelectedEventDeatails,
  setEventId,
  setSeriesId,
} = actions;
