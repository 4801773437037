import React, { useState, useEffect } from "react";
import "../../home/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { getGradient } from "../../../utills/getGradient";

const GroupWidget2 = ({ itemStyles, height, width, widgetId }) => {
  const dispatch = useDispatch();
  const editingMode = useSelector((state) => state.bodyStyling.isEdit);
  const [image, setImage] = useState(false);
  const [color, setColor] = useState("");
  const [link, setLink] = useState("");
  const [border, setBorder] = useState("");
  const [defaultCorners, setDefaultCorners] = useState("true");

  const [title, setTitle] = useState("");
  const [font, setFont] = useState({
    color: "#ffffff",
    isBold: false,
    isItalic: false,
    isUnderlined: false,
    family: "gilroymedium",
    size: 34,
    isDefaultFont: 1,
  });
  const [descFont, setDescFont] = useState({
    color: "#ffffff",
    isBold: false,
    isItalic: false,
    isUnderlined: false,
    family: "gilroymedium",
    size: 18,
    isDefaultFont: 1,
  });
  const [isEditText, setEditText] = useState(false);
  const [isEditText2, setEditText2] = useState(false);
  const [description, setDescription] = useState("");
  const [isDefault, setDefault] = useState(0);

  const universalStyles = useSelector(
    (state) => state.bodyStyling.universalStyles
  );

  const { default_widget_colors, mainColor } = universalStyles;

  useEffect(() => {
    if (universalStyles.corners && defaultCorners == "true") {
      setBorder(universalStyles.corners);
      if (universalStyles.font) {
        font.isDefaultFont == 1 &&
          setFont((prevFont) => ({
            ...prevFont,
            family: universalStyles.font,
          }));
      }
    }
  }, [universalStyles.corners, universalStyles.font]);

  useEffect(() => {
    if (itemStyles) {
      // setColor(itemStyles.background_color);
      if (
        itemStyles.background_color !== "0" &&
        itemStyles.background_color !== null
      ) {
        try {
          const bgstyle = JSON.parse(itemStyles.background_color);
          setColor(bgstyle);
        } catch (error) {
          const bgstyle = itemStyles.background_color;
          setColor(bgstyle);
        }
      } else {
        if (itemStyles.background_image) {
          // console.log("IMG HERE>", itemStyles.background_image);
          setImage(itemStyles.background_image);
        }
      }
      setTitle(itemStyles.title);
      if (itemStyles.title_font_style) {
        try {
          const fontstyle = JSON.parse(itemStyles.title_font_style);
          setFont({
            ...fontstyle,
            color: itemStyles.title_color,
            family: itemStyles.title_font,
            size: itemStyles.title_font_size,
          });
        } catch (error) {
          const fontstyle = itemStyles.title_font_style;
          setFont({
            ...fontstyle,
            color: itemStyles.title_color,
            family: itemStyles.title_font,
            size: itemStyles.title_font_size,
          });
        }
      }
      setDescription(itemStyles.description);
      if (itemStyles.description_font_style) {
        try {
          const fontstyle = JSON.parse(itemStyles.description_font_style);
          setDescFont({
            ...fontstyle,
            color: itemStyles.description_color,
            family: itemStyles.description_font,
            size: itemStyles.description_font_size,
          });
        } catch (error) {
          const fontstyle = itemStyles.description_font_style;
          setDescFont({
            ...fontstyle,
            color: itemStyles.description_color,
            family: itemStyles.description_font,
            size: itemStyles.description_font_size,
          });
        }
      }

      // setImage(itemStyles.background_image);
      setLink(itemStyles.widget_link);
      setBorder(itemStyles.border);
      setDefaultCorners(itemStyles.defaultCorners);
      setDefault(itemStyles.is_default);
      setImagePosition(itemStyles.image_position);
      setImgRepeat(itemStyles.image_repeat);
    }
  }, [itemStyles]);

  const [imagePosition, setImagePosition] = useState("cover");
  const [imageRepeat, setImgRepeat] = useState("no-repeat");

  return (
    <a
      style={{ textDecoration: "none" }}
      href={itemStyles?.widget_link}
      target="_blank"
    >
      <div
        className={`d-flex orangegradientmain  ${
          editingMode.widgtId == widgetId ? "edit-mode" : ""
        }`}
        style={{
          ...((image == "0" || image == null || image == false) && {
            background: `${
              isDefault && default_widget_colors.serve
                ? default_widget_colors.serve.is_default === "0"
                  ? default_widget_colors.serve.color
                  : default_widget_colors.serve.is_default === "1"
                  ? mainColor
                  : getGradient(color)
                : getGradient(color)
            }`,
          }),
          ...(image !== "0" &&
            image !== null &&
            image !== false && {
              backgroundImage: `url(${process.env.REACT_APP_BASE_URL}${image})`,
              backgroundPosition: "center",
              backgroundRepeat: imageRepeat,
              backgroundSize: imagePosition,
            }),
          borderRadius: border === "round" ? 20 : border === "sharp" ? 0 : null,
        }}
      >
        <div className="groupmainwrap col-md-12 d-flex flex-column justify-content-around ">
          <div className="d-flex align-items-end">
            <span
              suppressContentEditableWarning
              data-placeholder="text..."
              role="textbox"
              className={`connectTextarea ${isEditText ? "editText" : ""}  `}
              placeholder="type here"
              style={{
                fontSize: Number(font.size),
                color: font.color ? font.color : "white",
                textDecorationLine: font.isUnderlined ? "underline" : "none",
                fontWeight: font.isBold ? "bold" : "normal",
                fontStyle: font.isItalic ? "italic" : "normal",
                fontFamily:
                  font.family === "default"
                    ? universalStyles.font
                    : font.family
                    ? font.family
                    : "sans-serif",
                padding: 0,
              }}
            >
              {title}
            </span>
          </div>
          <div className="d-flex align-items-end">
            <textarea
              rows="2"
              value={description}
              className={`connectTextarea ${isEditText2 ? "editText" : ""}  `}
              placeholder="enter description "
              disabled
              style={{
                fontSize: Number(descFont.size),
                color: descFont.color ? descFont.color : "white",
                textDecorationLine: descFont.isUnderlined
                  ? "underline"
                  : "none",
                fontWeight: descFont.isBold ? "bold" : "normal",
                fontStyle: descFont.isItalic ? "italic" : "normal",
                fontFamily:
                  descFont.family === "default"
                    ? universalStyles.font
                    : descFont.family
                    ? descFont.family
                    : "sans-serif",
              }}
            />
          </div>
        </div>
      </div>
    </a>
  );
};

export default GroupWidget2;
