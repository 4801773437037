import React, { useState, useEffect } from "react";
import "../../home/styles.css";
import { useDispatch, useSelector } from "react-redux";
import Assets from "../../../assets/images";
import { getGradient } from "../../../utills/getGradient";

const GivingStatement = ({ itemStyles, width, height, widgetId }) => {
    const [border, setBorder] = useState("");
    const universalStyles = useSelector(
        (state) => state.bodyStyling.universalStyles
    );
    const { default_widget_colors, mainColor } = universalStyles;
    const [defaultCorners, setDefaultCorners] = useState("true");

    const [isDefaultButton, setDefaultButton] = useState(0);
    const [buttonFont, setButtonFont] = useState({
        color: "#ffffff",
        isBold: false,
        isItalic: false,
        isUnderlined: false,
        family: "gilroymedium",
        size: 18,
        isDefaultFont: 1,
    });

    const [isDefault, setDefault] = useState(0);
    const [color, setColor] = useState("#f8acac");
    const [font, setFont] = useState({
        color: "#ffffff",
        isBold: false,
        isItalic: false,
        isUnderlined: false,
        family: "frontage",
        size: 22,
        isDefaultFont: 1,
    });
    const [btnBg, setBtnBg] = useState("");
    const [btnTxt, setBtnTxt] = useState("");
    const [txtColor, setTxtColor] = useState("");
    const [link, setLink] = useState("");
    const [btnlink, setBtnLink] = useState("");

    useEffect(() => {
        if (universalStyles.corners && defaultCorners == "true") {
            setBorder(universalStyles.corners);
        }
        if (universalStyles.font) {
            font.isDefaultFont == 1 &&
                setFont((prevFont) => ({
                    ...prevFont,
                    family: universalStyles.font,
                }));
            buttonFont.isDefaultFont == 1 &&
                setButtonFont((prevFont) => ({
                    ...prevFont,
                    family: universalStyles.font,
                }));
        }
    }, [universalStyles.corners, universalStyles.font]);

    useEffect(() => {
        if (itemStyles) {
            // setColor(itemStyles.background_color);
            if (itemStyles.background_color) {
                try {
                    const bgstyle = JSON.parse(itemStyles.background_color);
                    setColor(bgstyle);
                } catch (error) {
                    const bgstyle = itemStyles.background_color;
                    setColor(bgstyle);
                }
            }
            setBtnTxt(itemStyles.button_text);
            // setBtnBg(itemStyles.button_background_color);
            if (itemStyles.button_background_color) {
                try {
                    const bgstyle = JSON.parse(
                        itemStyles.button_background_color
                    );
                    setBtnBg(bgstyle);
                } catch (error) {
                    const bgstyle = itemStyles.button_background_color;
                    setBtnBg(bgstyle);
                }
            }
            setTxtColor(itemStyles.button_text_color);
            if (itemStyles.title_font_style) {
                try {
                    const fontstyle = JSON.parse(itemStyles.title_font_style);
                    setFont({
                        ...fontstyle,
                        color: itemStyles.title_color,
                        family: itemStyles.title_font,
                        size: itemStyles.title_font_size,
                    });
                } catch (error) {
                    const fontstyle = itemStyles.title_font_style;
                    setFont({
                        ...fontstyle,
                        color: itemStyles.title_color,
                        family: itemStyles.title_font,
                        size: itemStyles.title_font_size,
                    });
                }
            }
            if (itemStyles.button_font_style) {
                try {
                    const buttonStyle = JSON.parse(
                        itemStyles.button_font_style
                    );
                    setButtonFont({
                        ...buttonStyle,
                        color: itemStyles.button_text_color,
                        family: itemStyles.button_font,
                        size: itemStyles.button_font_size,
                    });
                    setDefaultButton(buttonStyle.isDefaultButton);
                } catch (error) {
                    const buttonStyle = itemStyles.button_font_style;
                    setButtonFont({
                        ...buttonStyle,
                        color: itemStyles.button_text_color,
                        family: itemStyles.button_font,
                        size: itemStyles.button_font_size,
                    });
                    setDefaultButton(buttonStyle.isDefaultButton);
                    console.error(
                        "Error parsing heading title font style:",
                        error
                    );
                }
            }

            setLink(itemStyles.widget_link);
            setBtnLink(itemStyles.button_link);
            setBorder(itemStyles.border);
            setDefaultCorners(itemStyles.defaultCorners);
            setDefault(itemStyles.is_default);
        }
    }, [itemStyles]);

    return (
        <a
            style={{ textDecoration: "none" }}
            // href={link ? link : "#"}
            href="/view-statement"
            // target="_blank"
        >
            <div
                className={` givingGoal `}
                style={{
                    background: `${
                        isDefault && default_widget_colors.giving
                            ? default_widget_colors.giving.is_default === "0"
                                ? default_widget_colors.giving.color
                                : default_widget_colors.giving.is_default ===
                                  "1"
                                ? mainColor
                                : getGradient(color)
                            : getGradient(color)
                    }`,
                    // justifyContent: height == 8 ? "space-around" : "space-between",
                    borderRadius:
                        border === "round" ? 20 : border === "sharp" ? 0 : null,
                }}
            >
                <div
                    className="d-flex align-items-center"
                    style={{ width: "100%" }}
                >
                    <img
                        src={Assets.DollarIcon}
                        style={{ height: 100, width: 100 }}
                    />
                    <h6
                        style={{
                            margin: 0,
                            fontSize: 24,
                            fontWeight: "bold",
                            color: "#000",
                        }}
                    >
                        VIEW GIVING STATEMENT
                    </h6>
                </div>
            </div>
        </a>
    );
};

export default GivingStatement;
